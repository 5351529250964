import React from 'react';

const PaymentSuccess = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="50" viewBox="0 0 80 50" fill="none">
            <g clipPath="url(#clip0_701_541)">
                <path d="M73.1779 17.4944C73.094 17.5581 73.01 17.6191 72.924 17.6791H51.7132C51.5552 17.6191 51.3979 17.5575 51.2413 17.4944C49.0021 16.5944 46.9179 15.3959 45.0552 13.9373C43.8184 12.9695 42.6806 11.9703 41.8557 11.0339C40.7049 9.72723 39.6451 8.30326 39.1521 6.68076C38.6602 5.05825 38.8072 3.19841 39.906 1.85294C40.2463 1.43905 40.6646 1.08525 41.1408 0.808512C41.2198 0.762339 41.2998 0.718014 41.3818 0.676459C42.6996 -0.00689766 44.3623 -0.17312 45.8461 0.180563C47.9708 0.687539 49.7365 2.10319 50.9963 3.76357C52.2561 5.42301 53.074 7.32902 53.8799 9.21379C54.6978 5.84965 57.1744 2.83642 60.5199 1.30441C63.8644 -0.227604 68.0198 -0.209135 71.2932 1.45031C72.3232 1.97561 73.2536 2.65245 74.0478 3.45421C74.1268 3.5327 74.2038 3.61304 74.2798 3.69431C75.8765 5.40177 76.8754 7.58851 76.9574 9.84359C77.0624 12.7488 75.6046 15.6669 73.1779 17.4944Z" fill="#F2F2F2" />
                <path d="M52.5611 17.6791H52.2612C52.2572 17.6172 52.2522 17.5562 52.2462 17.4944C52.1414 16.2864 51.9219 15.0894 51.5903 13.9169C50.8296 11.2402 49.5301 8.71958 47.7599 6.48678C45.9947 4.24684 43.7694 2.34972 41.2109 0.903579C41.1922 0.893675 41.1761 0.879962 41.164 0.863481C41.1519 0.846999 41.144 0.828184 41.1409 0.808463C41.136 0.782676 41.1392 0.756134 41.15 0.731932C41.1608 0.707729 41.1788 0.686864 41.202 0.671765C41.2251 0.656667 41.2525 0.647959 41.2808 0.646656C41.3091 0.645352 41.3372 0.651508 41.3619 0.664404C41.3688 0.668078 41.3754 0.672085 41.3818 0.676408C42.8314 1.49242 44.177 2.45641 45.3933 3.5502C47.5689 5.51034 49.3231 7.83307 50.5635 10.3958C51.6577 12.6402 52.328 15.0411 52.5452 17.4944C52.5512 17.5562 52.5562 17.6181 52.5611 17.6791Z" fill="white" />
                <path d="M74.2798 3.69429C74.2777 3.69533 74.2753 3.69595 74.2728 3.69613C74.0539 3.73307 73.8339 3.77186 73.6159 3.81341C71.8267 4.15279 70.0752 4.644 68.3837 5.28078C66.6967 5.91667 65.0739 6.68923 63.5344 7.58942C61.993 8.48914 60.5394 9.51128 59.1901 10.6442C57.8409 11.7755 56.6032 13.0154 55.4917 14.3491C54.6609 15.3484 53.9056 16.3993 53.231 17.4944C53.1931 17.5553 53.156 17.6172 53.119 17.6791H52.7761C52.8121 17.6172 52.8491 17.5553 52.8871 17.4944C53.7032 16.1526 54.6389 14.876 55.6847 13.6778C56.8411 12.3517 58.1229 11.1232 59.5151 10.007C60.8984 8.89709 62.383 7.89974 63.9524 7.02611C65.5283 6.14896 67.1852 5.40235 68.9036 4.79505C70.5713 4.20623 72.293 3.75746 74.0479 3.45419C74.0968 3.44588 74.1448 3.43757 74.1939 3.42926C74.3808 3.39786 74.4608 3.65827 74.2798 3.69429Z" fill="white" />
                <path d="M19.7649 18.2281C20.2983 17.2981 20.3863 16.0868 19.7231 15.2311C19.3283 14.7217 18.7119 14.3873 18.065 14.1987C17.4182 14.0101 16.7359 13.9546 16.0594 13.9088C15.3898 13.8634 14.7082 13.8271 14.0541 13.967C13.4 14.1069 12.7664 14.4486 12.4477 14.9944C11.9094 15.9165 12.3998 17.0989 12.0044 18.0808C11.6415 18.9819 10.6089 19.5087 9.59373 19.7213C8.57858 19.9339 7.51903 19.9113 6.50337 20.1218C5.48772 20.3323 4.45333 20.8543 4.08405 21.7531C3.81702 22.4031 3.9497 23.1518 4.30156 23.7664C4.65343 24.381 5.20451 24.8785 5.78946 25.3161C6.6621 25.9688 7.68304 26.5278 8.8043 26.5844C9.98575 26.644 11.141 26.1246 11.9913 25.3647C12.8416 24.6048 13.4206 23.6228 13.8831 22.617C14.1765 21.9789 14.4537 21.2921 15.0417 20.8612C15.4738 20.5446 16.0234 20.4059 16.5563 20.2747L17.1598 20.1261C17.7067 19.994 18.2184 19.7599 18.6637 19.4383C19.1089 19.1166 19.4782 18.7142 19.7488 18.2559C19.7542 18.2466 19.7596 18.2374 19.7649 18.2281Z" fill="#2F2E41" />
                <path d="M15.0057 48.8198H13.78L13.1968 44.453H15.006L15.0057 48.8198Z" fill="#FFB8B8" />
                <path d="M12.9042 48.4963H15.2683V49.8711H11.4158C11.4158 49.6905 11.4543 49.5118 11.5291 49.345C11.6039 49.1782 11.7135 49.0266 11.8517 48.899C11.9899 48.7713 12.154 48.6701 12.3346 48.601C12.5152 48.5319 12.7088 48.4963 12.9042 48.4963Z" fill="#2F2E41" />
                <path d="M25.2039 48.8198H23.9782L23.395 44.453H25.2042L25.2039 48.8198Z" fill="#FFB8B8" />
                <path d="M23.1027 48.4963H25.4667V49.8711H21.6143C21.6143 49.6905 21.6528 49.5118 21.7276 49.345C21.8024 49.1782 21.912 49.0266 22.0502 48.899C22.1884 48.7713 22.3525 48.6701 22.5331 48.601C22.7137 48.5319 22.9072 48.4963 23.1027 48.4963Z" fill="#2F2E41" />
                <path d="M17.362 45.349C17.3449 45.349 17.3278 45.3482 17.3108 45.3465L12.3238 44.853C12.2576 44.8464 12.1936 44.8269 12.1362 44.7957C12.0788 44.7646 12.0292 44.7225 11.9907 44.6722C11.9523 44.622 11.9259 44.5647 11.9133 44.5043C11.9007 44.4439 11.9021 44.3817 11.9176 44.3219L16.3331 28.8106C16.3538 28.7317 16.398 28.6597 16.4606 28.6027C16.5232 28.5457 16.6019 28.506 16.6876 28.4881L20.7838 27.4335C20.8659 27.4168 20.9514 27.4207 21.0313 27.4448C21.1113 27.4689 21.1828 27.5122 21.2386 27.5704C23.8722 30.314 25.8569 36.3959 27.3603 44.0619C27.3717 44.1197 27.3702 44.1791 27.3559 44.2364C27.3416 44.2937 27.3148 44.3477 27.2772 44.3951C27.2395 44.4425 27.1919 44.4824 27.137 44.5122C27.0822 44.5421 27.0214 44.5614 26.9582 44.5688L22.8721 45.0507C22.7562 45.0646 22.6388 45.0379 22.5432 44.9759C22.4475 44.9139 22.3804 44.8211 22.3551 44.7157L20.367 36.5295C20.3487 36.4541 20.303 36.3869 20.2374 36.339C20.1718 36.291 20.0905 36.2654 20.0069 36.2663C19.9233 36.2672 19.8425 36.2946 19.7782 36.3439C19.7138 36.3932 19.6698 36.4614 19.6534 36.5371L17.8206 45.0006C17.7999 45.0989 17.7427 45.1874 17.6591 45.2509C17.5754 45.3145 17.4703 45.3492 17.362 45.349Z" fill="#2F2E41" />
                <path d="M16.6034 20.358C17.9597 20.358 19.0592 19.3425 19.0592 18.0899C19.0592 16.8372 17.9597 15.8218 16.6034 15.8218C15.2472 15.8218 14.1477 16.8372 14.1477 18.0899C14.1477 19.3425 15.2472 20.358 16.6034 20.358Z" fill="#FFB8B8" />
                <path d="M16.5347 28.8542C16.4315 28.8541 16.3311 28.8225 16.2494 28.7643C16.1676 28.7061 16.109 28.6246 16.0826 28.5324L14.6053 23.4063C14.4986 23.038 14.5428 22.6463 14.7293 22.3061C14.9158 21.966 15.2315 21.7013 15.6161 21.5627C16.8053 21.1339 17.9383 20.9749 18.9836 21.0898C19.9127 21.192 21.0588 26.272 20.983 26.3804L21.3496 27.3962C21.3704 27.4538 21.3778 27.5148 21.3714 27.5752C21.3651 27.6357 21.345 27.6942 21.3125 27.747C21.2801 27.7998 21.236 27.8457 21.1831 27.8818C21.1302 27.9178 21.0696 27.9432 21.0054 27.9562L16.6366 28.8439C16.6031 28.8507 16.569 28.8542 16.5347 28.8542Z" fill="#CCCCCC" />
                <path d="M17.8589 34.0963C17.88 33.9954 17.8768 33.8915 17.8497 33.7919C17.8225 33.6922 17.772 33.5993 17.7018 33.5196C17.6316 33.44 17.5433 33.3755 17.4431 33.3307C17.343 33.286 17.2335 33.2621 17.1222 33.2606L16.2711 30.8901L15.4011 31.9093L16.3428 34.0041C16.3526 34.1769 16.4307 34.3403 16.5622 34.4634C16.6938 34.5866 16.8696 34.6608 17.0565 34.6722C17.2434 34.6836 17.4283 34.6312 17.5762 34.5251C17.7241 34.4189 17.8247 34.2664 17.8589 34.0963Z" fill="#FFB8B8" />
                <path d="M28.8019 28.3993C28.7509 28.308 28.6789 28.2281 28.5909 28.1652C28.5029 28.1024 28.401 28.0581 28.2925 28.0356C28.184 28.013 28.0714 28.0127 27.9627 28.0347C27.8541 28.0567 27.7519 28.1004 27.6635 28.1628L25.4083 26.7848L25.4062 28.0826L27.5482 29.1922C27.6715 29.3224 27.8422 29.4063 28.028 29.428C28.2138 29.4498 28.4018 29.4078 28.5563 29.31C28.7108 29.2123 28.8212 29.0656 28.8664 28.8978C28.9117 28.7299 28.8887 28.5526 28.8019 28.3993Z" fill="#FFB8B8" />
                <path d="M16.0744 33.0011C15.9758 33.0012 15.8797 32.9725 15.7997 32.9191C15.7197 32.8658 15.66 32.7905 15.6291 32.704L13.6337 28.8055C13.5519 28.577 13.5102 28.3379 13.5103 28.0973L14.1102 23.0302C14.1101 22.7465 14.2274 22.4735 14.4379 22.2669C14.6484 22.0603 14.9364 21.9357 15.243 21.9185C15.5496 21.9014 15.8517 21.993 16.0877 22.1746C16.3237 22.3562 16.4757 22.6141 16.5128 22.8958L15.595 28.6795L17.0596 32.2292C17.0828 32.3237 17.071 32.4225 17.026 32.51C16.9811 32.5975 16.9057 32.6685 16.8118 32.7117L16.2841 32.9555C16.219 32.9855 16.1472 33.0012 16.0744 33.0011Z" fill="#CCCCCC" />
                <path d="M26.731 28.9111C26.6576 28.9112 26.5851 28.8952 26.5196 28.8646L22.5514 27.0134C22.3188 26.9049 22.1065 26.7628 21.9226 26.5922L18.0495 23.0035C17.8327 22.8025 17.707 22.5328 17.6982 22.2492C17.6895 21.9656 17.7982 21.6896 18.0023 21.4776C18.2063 21.2655 18.4903 21.1334 18.7962 21.1082C19.1022 21.0829 19.407 21.1665 19.6485 21.3418L23.8441 25.6454L27.1699 27.5957C27.2585 27.6475 27.3257 27.7252 27.3608 27.8165C27.3958 27.9077 27.3967 28.0072 27.3632 28.099L27.1757 28.6157C27.1444 28.7018 27.0847 28.7766 27.0049 28.8296C26.9251 28.8826 26.8293 28.9111 26.731 28.9111Z" fill="#CCCCCC" />
                <path d="M13.8101 16.9173C14.2272 17.4213 14.8268 17.7698 15.4993 17.899C16.1809 18.0291 16.8904 17.9352 17.505 17.6336C17.9996 17.3868 18.3954 17.0164 18.783 16.6517C18.9448 16.4995 19.1171 16.3239 19.109 16.1163C19.0964 15.7946 18.6776 15.6187 18.3182 15.5486C17.1385 15.3213 15.9111 15.4488 14.8155 15.9124C14.3276 16.1217 13.8192 16.5004 13.8925 16.9712" fill="#2F2E41" />
                <path d="M78.3003 17.4944H30.1077C29.6571 17.495 29.2251 17.6606 28.9065 17.9548C28.5879 18.2491 28.4086 18.6481 28.408 19.0643V36.2405C28.4086 36.6567 28.5879 37.0556 28.9065 37.3499C29.2251 37.6442 29.6571 37.8098 30.1077 37.8104H78.3003C78.7509 37.8098 79.1829 37.6442 79.5015 37.3499C79.8202 37.0556 79.9994 36.6567 80.0001 36.2405V19.0643C79.9994 18.6481 79.8202 18.2491 79.5015 17.9548C79.1829 17.6606 78.7509 17.495 78.3003 17.4944ZM79.8001 36.2405C79.7996 36.6077 79.6415 36.9598 79.3603 37.2195C79.0791 37.4792 78.698 37.6252 78.3003 37.6257H30.1077C29.7101 37.6252 29.3289 37.4792 29.0477 37.2195C28.7666 36.9598 28.6084 36.6077 28.6079 36.2405V19.0643C28.6084 18.697 28.7666 18.345 29.0477 18.0853C29.3289 17.8256 29.7101 17.6795 30.1077 17.6791H78.3003C78.698 17.6795 79.0791 17.8256 79.3603 18.0853C79.6415 18.345 79.7996 18.697 79.8001 19.0643V36.2405Z" fill="#3F3D56" />
                <path d="M38.8577 33.0085C37.7108 33.0085 36.5896 32.6943 35.6359 32.1058C34.6822 31.5173 33.9389 30.6808 33.5 29.7021C33.0611 28.7234 32.9463 27.6465 33.17 26.6075C33.3938 25.5685 33.9461 24.6142 34.7571 23.8651C35.5681 23.1161 36.6014 22.606 37.7264 22.3993C38.8513 22.1926 40.0173 22.2987 41.0769 22.7041C42.1366 23.1095 43.0423 23.796 43.6795 24.6768C44.3167 25.5576 44.6568 26.5931 44.6568 27.6524C44.6551 29.0724 44.0436 30.4338 42.9564 31.4379C41.8692 32.442 40.3952 33.0069 38.8577 33.0085ZM38.8577 22.4811C37.7503 22.4811 36.6678 22.7844 35.747 23.3526C34.8262 23.9208 34.1086 24.7285 33.6848 25.6734C33.261 26.6184 33.1501 27.6582 33.3661 28.6613C33.5822 29.6644 34.1155 30.5859 34.8985 31.3091C35.6816 32.0323 36.6792 32.5249 37.7654 32.7244C38.8515 32.9239 39.9773 32.8215 41.0004 32.4301C42.0235 32.0387 42.898 31.3759 43.5132 30.5255C44.1285 29.675 44.4569 28.6752 44.4569 27.6524C44.4552 26.2814 43.8647 24.9669 42.815 23.9974C41.7654 23.028 40.3422 22.4826 38.8577 22.4811Z" fill="#3F3D56" />
                <path d="M73.9994 24.2356H50.903C50.5801 24.2358 50.2699 24.3513 50.0373 24.5581C49.8047 24.7648 49.6677 25.0467 49.6552 25.3447C49.6535 25.3597 49.6528 25.3748 49.6532 25.3899C49.6536 25.6959 49.7855 25.9893 50.0197 26.2057C50.254 26.422 50.5716 26.5438 50.903 26.5442H73.9994C74.3309 26.5442 74.6488 26.4226 74.8832 26.2061C75.1176 25.9897 75.2493 25.6961 75.2493 25.3899C75.2493 25.0838 75.1176 24.7902 74.8832 24.5737C74.6488 24.3572 74.3309 24.2356 73.9994 24.2356Z" fill="#6C63FF" />
                <path d="M73.9994 28.7605H50.903C50.5801 28.7607 50.2699 28.8762 50.0373 29.083C49.8047 29.2897 49.6677 29.5716 49.6552 29.8696C49.6535 29.8846 49.6528 29.8997 49.6532 29.9148C49.6536 30.2208 49.7855 30.5142 50.0197 30.7306C50.254 30.9469 50.5716 31.0687 50.903 31.0691H73.9994C74.3309 31.0691 74.6488 30.9475 74.8832 30.731C75.1176 30.5146 75.2493 30.221 75.2493 29.9148C75.2493 29.6087 75.1176 29.3151 74.8832 29.0986C74.6488 28.8821 74.3309 28.7605 73.9994 28.7605Z" fill="#6C63FF" />
                <path d="M38.2107 29.9926C38.0489 29.9928 37.8914 29.9445 37.7619 29.8549L37.7539 29.8493L36.0637 28.6542C35.9855 28.5988 35.9198 28.5297 35.8704 28.4508C35.821 28.372 35.789 28.2849 35.776 28.1947C35.7631 28.1044 35.7695 28.0126 35.795 27.9246C35.8204 27.8366 35.8644 27.7541 35.9244 27.6819C35.9844 27.6096 36.0592 27.5489 36.1445 27.5033C36.2299 27.4577 36.3241 27.4281 36.4219 27.4161C36.5196 27.4042 36.619 27.4101 36.7143 27.4336C36.8095 27.4571 36.8988 27.4978 36.9771 27.5531L38.0719 28.3284L40.6589 25.2123C40.7189 25.14 40.7937 25.0794 40.8791 25.0338C40.9645 24.9883 41.0588 24.9587 41.1565 24.9468C41.2543 24.9348 41.3536 24.9408 41.4489 24.9644C41.5441 24.9879 41.6334 25.0286 41.7117 25.084L41.6956 25.1041L41.7121 25.0843C41.87 25.1963 41.9732 25.3616 41.9993 25.5439C42.0253 25.7262 41.972 25.9106 41.8511 26.0566L38.8081 29.7219C38.7377 29.8064 38.6472 29.8747 38.5436 29.9217C38.44 29.9686 38.3261 29.9929 38.2107 29.9926Z" fill="#6C63FF" />
                <path d="M38.1942 50H0.0999847C0.0734671 50 0.0480356 49.9903 0.0292848 49.9729C0.010534 49.9556 0 49.9321 0 49.9077C0 49.8832 0.010534 49.8597 0.0292848 49.8424C0.0480356 49.825 0.0734671 49.8153 0.0999847 49.8153H38.1942C38.2207 49.8153 38.2461 49.825 38.2649 49.8424C38.2836 49.8597 38.2942 49.8832 38.2942 49.9077C38.2942 49.9321 38.2836 49.9556 38.2649 49.9729C38.2461 49.9903 38.2207 50 38.1942 50Z" fill="#3F3D56" />
                <path d="M68.3153 13.3342C66.2265 13.0688 64.1054 13.113 62.0317 13.465C59.9641 13.8141 57.968 14.4587 56.1153 15.3755C55.0741 15.8922 54.0859 16.4953 53.1629 17.1771C53.0121 17.2884 53.2256 17.4833 53.375 17.373C54.9947 16.183 56.8103 15.2404 58.7529 14.5809C60.6801 13.9226 62.7058 13.543 64.7584 13.4556C65.9198 13.4064 67.0837 13.4551 68.2355 13.6013C68.2738 13.6098 68.3142 13.6045 68.3484 13.5866C68.3827 13.5686 68.4083 13.5393 68.42 13.5046C68.4303 13.4692 68.4251 13.4314 68.4055 13.3995C68.3859 13.3676 68.3535 13.3441 68.3153 13.3342Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_701_541">
                    <rect width="80" height="50" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
export default PaymentSuccess;