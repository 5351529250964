import React from 'react';

const Packaged = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="50" viewBox="0 0 80 50" fill="none">
            <g clipPath="url(#clip0_701_1473)">
                <path d="M61.5739 37.7665C64.8646 29.5993 63.8691 20.4797 59.6251 13.4757L15.199 19.5824L57.7734 10.774C39.8301 -11.4391 4.90608 3.29457 8.27258 31.3402L61.5739 37.7665Z" fill="#E6E6E6" />
                <path d="M63.5333 28.7384H6.93384V46.2352H63.5333V28.7384Z" fill="#6C63FF" />
                <path d="M22.7187 26.6047C23.2265 26.9962 23.4122 27.1226 23.5203 27.1066C28.7599 28.6213 24.1666 30.3965 21.6208 27.3832L22.7187 26.6047Z" fill="#FFB8B8" />
                <path d="M5.4292 42.6078V46.1641L8.00836 46.0219L7.29198 42.3234L5.4292 42.6078Z" fill="#FFB8B8" />
                <path d="M19.7582 42.6078V46.1641L17.179 46.0219L17.8954 42.3234L19.7582 42.6078Z" fill="#FFB8B8" />
                <path d="M11.8773 24.2576L15.7461 24.1154C24.671 33.4039 21.2958 31.2854 21.0479 44.0304L16.3193 42.6079L17.3223 33.7884C17.3228 33.788 17.5869 33.6133 17.3674 33.6092C15.9946 34.0776 14.9929 28.2788 12.0206 28.9519C8.63826 28.7912 8.08762 33.7724 6.71888 33.7884L7.72192 42.6079L2.99336 44.0304C2.46393 30.0249 -0.130822 33.1888 8.29507 24.1154L11.8773 24.2576Z" fill="#2F2E41" />
                <path d="M6.14568 45.0261C6.14568 45.0261 5.57252 43.888 4.85607 44.457C4.13962 45.026 2.42015 46.7331 2.42015 46.7331C2.42015 46.7331 -1.73525 48.5823 0.84396 49.7203C3.42317 50.8583 5.57252 48.1556 5.57252 48.1556C8.02919 47.647 9.4462 47.3214 8.14339 44.6611C7.40373 44.9789 6.35701 45.3468 6.14568 45.0261Z" fill="#2F2E41" />
                <path d="M19.0417 45.0261C19.0417 45.0261 19.6148 43.888 20.3313 44.457C21.0477 45.026 22.7672 46.7331 22.7672 46.7331C22.7672 46.7331 26.9226 48.5823 24.3434 49.7203C21.7642 50.8583 19.6148 48.1556 19.6148 48.1556C17.1582 47.647 15.7411 47.3214 17.0439 44.6611C17.7836 44.9789 18.8303 45.3468 19.0417 45.0261Z" fill="#2F2E41" />
                <path d="M15.7461 8.18327C15.5329 11.7244 10.6647 11.4127 10.5876 8.18324C10.8007 4.64209 15.669 4.95385 15.7461 8.18327Z" fill="#FFB8B8" />
                <path d="M12.0205 10.0326C13.274 13.7937 10.3145 11.746 14.4564 14.7268L16.0326 11.5973C16.0326 11.5973 14.8863 11.1706 15.0296 9.60583L12.0205 10.0326Z" fill="#FFB8B8" />
                <path d="M19.7581 12.1664L15.4867 11.1985C15.6069 13.7553 12.8583 13.2407 12.3787 11.9546L8.29496 14.3001C10.009 20.6378 9.57462 20.8993 7.29193 24.9689C6.42076 25.7586 7.72361 26.2113 8.85782 25.7791C9.7229 25.2491 10.1622 24.778 11.0892 24.8979C15.4693 25.8033 19.1156 26.017 19.6927 25.6632C19.8992 25.3698 19.6867 12.2173 19.7581 12.1664Z" fill="#575A89" />
                <path d="M18.6118 12.4508C18.7551 12.4508 19.4932 12.1063 19.4932 12.1063C21.6407 12.0703 23.0817 26.4104 23.1971 26.8181L21.7642 27.8138L19.4716 21.9816L18.6118 12.4508Z" fill="#575A89" />
                <path d="M8.58154 29.0941C8.65481 29.6309 8.77948 35.6598 10.955 32.8436C11.2404 30.7889 10.27 30.1756 10.0144 28.8096L8.58154 29.0941Z" fill="#FFB8B8" />
                <path d="M9.15467 14.3001L8.71563 14.0585C7.53858 14.0201 7.90954 17.1132 7.41155 19.3784C7.00472 22.0173 7.12056 24.927 7.59786 28.8173C7.6396 30.2448 10.2152 29.3455 10.5876 29.2364C10.5876 29.2364 9.72782 23.4041 10.1577 22.4083C10.5876 21.4126 9.15467 14.3001 9.15467 14.3001Z" fill="#575A89" />
                <path d="M15.6334 5.70115C15.2355 5.05967 14.6126 4.58697 13.8847 4.37402L13.3501 4.79863L13.5579 4.30337C13.3831 4.2769 13.2062 4.26675 13.0295 4.27305L12.5485 4.88705L12.7476 4.29408C10.6834 4.51411 9.46215 6.86648 10.5338 8.51285C10.6991 8.00854 10.8997 7.53533 11.065 7.03102C11.1949 7.0486 11.3266 7.04924 11.4567 7.03291L11.6578 6.56705L11.714 7.01321C12.3373 6.95929 13.2619 6.84093 13.8529 6.73247L13.7954 6.39021L14.1392 6.67463C14.3203 6.63325 14.4278 6.59573 14.4189 6.56705C14.8584 7.27045 15.2939 7.71973 15.7334 8.42313C15.9002 7.43096 16.1947 6.60624 15.6334 5.70115Z" fill="#2F2E41" />
                <path d="M60.1456 30.8491H47.6458V36.7355H60.1456V30.8491Z" fill="white" />
                <path d="M57.7816 32.082H50.0093V32.7878H57.7816V32.082Z" fill="#E6E6E6" />
                <path d="M57.7816 33.4395H50.0093V34.1453H57.7816V33.4395Z" fill="#E6E6E6" />
                <path d="M57.7816 34.7968H50.0093V35.5026H57.7816V34.7968Z" fill="#E6E6E6" />
                <path d="M36.7602 32.2474L36.3535 31.5483L35.947 30.8491L35.5404 31.5483L35.1338 32.2474H35.5707V34.2719H36.3233V32.2474H36.7602Z" fill="white" />
                <path d="M64.3108 46.6526C66.1599 46.6526 67.6588 45.1645 67.6588 43.3289C67.6588 41.4933 66.1599 40.0052 64.3108 40.0052C62.4618 40.0052 60.9629 41.4933 60.9629 43.3289C60.9629 45.1645 62.4618 46.6526 64.3108 46.6526Z" fill="#3F3D56" />
                <path d="M64.9195 44.8018C65.739 44.8018 66.4033 44.1423 66.4033 43.3288C66.4033 42.5153 65.739 41.8558 64.9195 41.8558C64.1001 41.8558 63.4358 42.5153 63.4358 43.3288C63.4358 44.1423 64.1001 44.8018 64.9195 44.8018Z" fill="#E6E6E6" />
                <path d="M62.1995 45.6516L69.5042 48.6731L69.2759 49.3908L65.0719 48.9186L57.9575 45.7838L61.9142 46.2371L62.1995 45.6516Z" fill="#3F3D56" />
                <path d="M68.6564 48.5371L77.7413 18.9518L79.9537 15.0514L79.9615 15.0137C79.9686 14.9793 80.1311 14.1641 79.7315 13.5737C79.6308 13.4269 79.5007 13.3023 79.3493 13.2077C79.198 13.113 79.0286 13.0503 78.8518 13.0235C77.6307 12.7962 74.8991 12.6843 74.7834 12.6796L74.7616 12.6787L74.74 12.6816C74.6654 12.6917 72.9196 12.9451 72.525 14.4585L70.6257 17.8901L70.6182 17.9145L62.02 45.9768L62.4932 46.1197L71.0839 18.0818L72.9896 14.6386L72.9973 14.607C73.2763 13.4681 74.6105 13.2017 74.7879 13.1712C75.0669 13.183 77.6343 13.2965 78.7606 13.5061C78.8724 13.5212 78.9799 13.5593 79.0761 13.6177C79.1724 13.6762 79.2554 13.7539 79.3199 13.8459C79.5661 14.2079 79.5045 14.7408 79.4828 14.8809L77.284 18.7574L77.2761 18.783L68.1833 48.3939L68.6564 48.5371Z" fill="#3F3D56" />
                <path d="M69.9846 20.7273L69.8967 21.2104L76.458 22.3869L76.5459 21.9037L69.9846 20.7273Z" fill="#3F3D56" />
                <path d="M68.7593 24.7456L68.6604 25.2267L75.1749 26.5466L75.2738 26.0655L68.7593 24.7456Z" fill="#3F3D56" />
                <path d="M66.5772 31.9321L66.4412 32.4042L72.8014 34.2109L72.9374 33.7388L66.5772 31.9321Z" fill="#3F3D56" />
                <path d="M70.8787 45.8614L71.059 45.4041L64.8856 43.0043L64.5825 39.7458L70.3444 41.8823L72.2111 44.5636L72.6181 44.2846L70.663 41.4762L64.0176 39.0121L64.4212 43.3511L70.8787 45.8614Z" fill="#3F3D56" />
                <path d="M73.0232 49.8251C74.8723 49.8251 76.3712 48.337 76.3712 46.5014C76.3712 44.6658 74.8723 43.1777 73.0232 43.1777C71.1742 43.1777 69.6753 44.6658 69.6753 46.5014C69.6753 48.337 71.1742 49.8251 73.0232 49.8251Z" fill="#3F3D56" />
                <path d="M73.8983 48.1255C74.7178 48.1255 75.3821 47.4661 75.3821 46.6526C75.3821 45.839 74.7178 45.1796 73.8983 45.1796C73.0788 45.1796 72.4146 45.839 72.4146 46.6526C72.4146 47.4661 73.0788 48.1255 73.8983 48.1255Z" fill="#E6E6E6" />
            </g>
            <defs>
                <clipPath id="clip0_701_1473">
                    <rect width="80" height="50" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
export default Packaged;