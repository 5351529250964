import React, { Component } from 'react';
import { Result, Icon, Row, Spin } from 'antd';
import { api } from '../../config/Services';
import { DetailRequest } from '../../utilities/RequestService';
import { Alert } from '../../components/Base/BaseComponent';
import { EmailChecklist } from '../../components/IconSVG/index';

class EmailVerified extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false
		}
	};

	componentDidMount() {
		this.setState({ isLoading: true });
		document.title = 'Email Verified | Loyalty Management System';
		const memberid = this.props.match.params.ID;

		DetailRequest(api.url.member.verifyemail, { memberid }).then((response) => {
			let { status } = response;
			if (status.responsecode === '0000') {
				Alert.success(status.responsemessage);
				this.setState({ isLoading: false });
			} else {
				Alert.console.error(status.responsemessage);
				this.setState({ isLoading: false });
			}
		})
	};

	render() {
		const { isLoading } = this.state;

		return (
			<Row>
				<Spin spinning={isLoading}>
					<Result
						icon={<Icon component={EmailChecklist} {...this.props} />}
						title='Congratulations!'
						subTitle='Email verified successfully'
					/>
				</Spin>
			</Row>
		)
	};
}

export default EmailVerified;