import React, { Component } from 'react';
import { Row, Col, Modal, Alert } from 'antd';
import DashboardItem from '../../components/DashboardItem';
import EnrollmentMemberInformation from './enrollment_member_information/Index';
import Flight from './flight/Index';
import PromoCode from './promo_code/Index';
import NonAirActivity from './non_air_activity/Index'
import ActivityCorporate from './activity_corporate/Index'
import MemberTier from './member_tier/Index';
import MemberActivity from './member_activity/Index';
import Redemption from './redemption/Index';
import { getGeneralConfig } from '../../utilities/Helpers';
import { general_config } from '../../utilities/Constant';
import ReactHtmlParser from 'react-html-parser';

class Layout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			announcement: null,
			visible: false,
			visibleAlert: false
		}
	}

	componentDidMount = async () => {
		document.title = "Dashboard | Loyalty Management System";
		/* Default announcement show */
		const callbackShow = async (show) => {
			if (show === 'true' || show === true) {
				/* Default announcement */
				const callback = (announcement) => {
					this.setState({ announcement, visible: true, visibleAlert: true });
				}
				await getGeneralConfig(general_config.default_announcement, callback);
			}
		}
		await getGeneralConfig(general_config.default_announcement_show, callbackShow);

	};

	render() {
		const { announcement, visible, visibleAlert } = this.state;

		return (
			<React.Fragment>
				<Modal visible={visible} footer={null} destroyOnClose={true} width={1000} onCancel={() => { this.setState({ visible: false, visibleAlert: true }) }} >
					<Row gutter={24} style={{ padding: 40, marginTop: '-100px' }}>{ReactHtmlParser(announcement)}</Row>
				</Modal>

				{(visibleAlert) ?
					<Alert message="You Have One Announcement" type="info" closeText={<u>Open Announcement</u>} closable={false} onClose={() => { this.setState({ visible: true }) }}
						style={{ marginBottom: 30 }} afterClose={() => { this.setState({ visibleAlert: false }) }} /> : null
				}

				<Row gutter={24}>
					<Col xs={{ span: 24 }} xl={{ span: 8 }} style={{ marginBottom: 16 }}>
						<DashboardItem title="Enrollment Member">
							<EnrollmentMemberInformation />
						</DashboardItem>
					</Col>
					<Col xs={{ span: 24 }} xl={{ span: 8 }} style={{ marginBottom: 16 }}>
						<DashboardItem title="Flight" >
							<Flight />
						</DashboardItem>
					</Col>
					<Col xs={{ span: 24 }} xl={{ span: 8 }} style={{ marginBottom: 16 }}>
						<DashboardItem title="Promo Code">
							<PromoCode />
						</DashboardItem>
					</Col>
				</Row>
				<Row gutter={24}>
					<Col xs={{ span: 24 }} xl={{ span: 12 }} style={{ marginBottom: 16 }}>
						<DashboardItem title="Non - Air Activity">
							<NonAirActivity />
						</DashboardItem>
					</Col>
					<Col xs={{ span: 24 }} xl={{ span: 12 }} style={{ marginBottom: 16 }}>
						<DashboardItem title="Corporate Activity">
							<ActivityCorporate />
						</DashboardItem>
					</Col>
				</Row>
				<Row gutter={24}>
					<Col xs={{ span: 24 }} xl={{ span: 8 }} style={{ marginBottom: 16 }}>
						<DashboardItem title="Member Tier">
							<MemberTier />
						</DashboardItem>
					</Col>
					<Col xs={{ span: 24 }} xl={{ span: 8 }} style={{ marginBottom: 16 }}>
						<DashboardItem title="Member Activity">
							<MemberActivity />
						</DashboardItem>
					</Col>
					<Col xs={{ span: 24 }} xl={{ span: 8 }} style={{ marginBottom: 16 }}>
						<DashboardItem title="Redemption">
							<Redemption />
						</DashboardItem>
					</Col>
				</Row>
			</React.Fragment>
		)
	}
}

export default Layout;