import React from 'react';

const OnDelivery = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="50" viewBox="0 0 80 50" fill="none">
            <path d="M55.9022 41.1684C56.1806 44.2288 57.2046 47.0287 58.297 49.7259C58.3331 49.8177 58.3703 49.9081 58.4084 50H69.7621C69.9917 49.9164 70.2184 49.8245 70.4441 49.7259C71.7264 49.1782 72.9371 48.3428 74.029 47.2521C78.206 43.0309 79.8133 35.0546 79.0873 27.9074C78.6359 23.467 77.2465 18.9334 74.541 16.5419C73.1125 15.2769 71.2775 14.8657 69.6331 15.3358C69.5901 15.3468 69.5471 15.3605 69.5032 15.3728C68.0883 15.8196 66.8279 16.9297 66.1107 18.7182C64.821 21.932 65.589 25.9134 66.2231 29.4904C66.8553 33.0674 67.1982 37.3488 65.3632 39.9871C65.9691 37.4859 65.3711 34.5023 63.924 32.8111C62.8307 31.5352 61.3084 31.0569 59.9199 31.4529C59.7871 31.4927 59.6561 31.5379 59.5271 31.5927C59.2154 31.7208 58.9149 31.8973 58.6312 32.119C56.467 33.8143 55.5905 37.7189 55.9022 41.1684Z" fill="#F2F2F2" />
            <path d="M62.6343 49.7259C62.3197 49.8287 62.0041 49.9192 61.6865 50H63.4032C63.628 49.9151 63.8517 49.8246 64.0735 49.7259C64.5611 49.5121 65.0412 49.2668 65.5137 48.99C67.4093 47.8744 69.1553 46.1996 70.4763 43.9712C71.1224 42.8915 71.65 41.6827 72.0416 40.3846C72.4663 38.9174 72.7362 37.3712 72.8428 35.7948C73.0822 32.5604 72.8379 29.2369 72.3816 26.0601C71.9107 22.7675 71.1506 19.5694 70.1187 16.5392C69.9946 16.176 69.8663 15.8151 69.7337 15.4565C69.7246 15.427 69.7108 15.4007 69.6934 15.3798C69.6759 15.3589 69.6553 15.3439 69.6331 15.3359C69.588 15.3236 69.5413 15.3369 69.5032 15.3729C69.4574 15.4097 69.4235 15.4698 69.4084 15.5407C69.3933 15.6116 69.3982 15.6879 69.4221 15.7539C70.486 18.6643 71.2926 21.746 71.8237 24.9308C72.3435 28.0377 72.6386 31.2885 72.538 34.4832C72.4422 37.4681 71.8862 40.4038 70.6248 42.8446C69.4729 45.0731 67.8822 46.8301 66.1224 48.0265C65.0086 48.774 63.8386 49.344 62.6343 49.7259V49.7259Z" fill="white" />
            <path d="M58.8667 50H59.3729C59.4296 49.9096 59.4843 49.8178 59.538 49.7259C60.6909 47.7612 61.4719 45.4204 61.8088 42.92C62.2179 39.7997 61.8996 36.5775 60.9011 33.7308C60.6225 32.9325 60.2942 32.1703 59.9201 31.453C59.7872 31.4927 59.6562 31.538 59.5273 31.5928C60.5113 33.4216 61.1732 35.5508 61.459 37.8067C61.7139 39.9205 61.6291 42.0875 61.2109 44.1496C60.7927 46.2117 60.0516 48.1168 59.0416 49.7259C58.985 49.8178 58.9264 49.9096 58.8667 50Z" fill="white" />
            <path d="M13.2722 47.6692C13.1987 48.4769 12.9285 49.2158 12.6402 49.9277C12.6306 49.9519 12.6208 49.9758 12.6108 50H9.61434C9.55374 49.9779 9.49392 49.9537 9.43435 49.9277C9.09591 49.7831 8.77641 49.5627 8.48824 49.2748C7.38585 48.1608 6.96165 46.0557 7.15326 44.1694C7.2724 42.9975 7.63907 41.801 8.35311 41.1698C8.74085 40.8385 9.20346 40.7249 9.64838 40.8515C9.65974 40.8544 9.67108 40.858 9.68268 40.8613C9.86788 40.9115 10.0434 41.0161 10.1977 41.1683C10.352 41.3205 10.4816 41.5167 10.578 41.7442C10.9184 42.5924 10.7157 43.6431 10.5484 44.5871C10.3815 45.5312 10.291 46.6611 10.7753 47.3574C10.7043 47.0662 10.6899 46.7538 10.7335 46.4522C10.7771 46.1506 10.8772 45.8708 11.0235 45.6414C11.1698 45.4121 11.357 45.2415 11.5658 45.1472C11.7747 45.053 11.9977 45.0384 12.2118 45.1051C12.2469 45.1156 12.2815 45.1275 12.3155 45.142C12.3978 45.1758 12.4771 45.2224 12.552 45.2809C13.1232 45.7283 13.3545 46.7588 13.2722 47.6692Z" fill="#F2F2F2" />
            <path d="M11.4955 49.9277C11.5785 49.9548 11.6618 49.9787 11.7456 50H11.2925C11.2332 49.9776 11.1742 49.9537 11.1156 49.9277C10.987 49.8713 10.8603 49.8065 10.7356 49.7335C10.2297 49.4454 9.78053 48.9911 9.42585 48.4089C9.25535 48.124 9.11611 47.8049 9.01275 47.4623C8.90067 47.0751 8.82944 46.667 8.80131 46.251C8.7499 45.3909 8.79093 44.5248 8.92302 43.6818C9.0473 42.8128 9.2479 41.9688 9.52024 41.1691C9.55299 41.0732 9.58686 40.978 9.62184 40.8833C9.62425 40.8756 9.62789 40.8686 9.63249 40.8631C9.63709 40.8576 9.64253 40.8536 9.64839 40.8515C9.66029 40.8483 9.67263 40.8518 9.68269 40.8613C9.69476 40.871 9.70372 40.8869 9.7077 40.9056C9.71169 40.9243 9.71039 40.9444 9.70409 40.9618C9.42329 41.7299 9.21044 42.5433 9.07026 43.3838C8.92588 44.2082 8.86246 45.0564 8.88175 45.9049C8.89207 46.694 9.06766 47.4614 9.38666 48.1116C9.69688 48.6971 10.1049 49.1668 10.5749 49.4792C10.8689 49.6765 11.1776 49.8269 11.4955 49.9277Z" fill="white" />
            <path d="M12.49 50H12.3564C12.3415 49.9761 12.327 49.9519 12.3128 49.9277C12.0086 49.4091 11.8024 48.7913 11.7135 48.1315C11.6055 47.3079 11.6896 46.4576 11.9531 45.7062C12.0266 45.4956 12.1133 45.2944 12.212 45.1051C12.2471 45.1156 12.2816 45.1275 12.3157 45.142C12.056 45.6246 11.8813 46.1866 11.8059 46.7819C11.7386 47.3398 11.761 47.9117 11.8713 48.456C11.9817 49.0002 12.1773 49.503 12.4438 49.9277C12.4588 49.9519 12.4743 49.9761 12.49 50Z" fill="white" />
            <path d="M37 29H48.8735L48.9994 25.1848C49.0048 25.0194 48.9759 24.8547 48.9146 24.7021C48.8533 24.5496 48.7611 24.4127 48.6444 24.3009C48.5276 24.1891 48.3891 24.105 48.2383 24.0546C48.0875 24.0041 47.928 23.9884 47.7708 24.0085L40.8613 24.8927H40.8604C39.8361 24.8956 38.8546 25.3227 38.131 26.0805C37.4074 26.8382 37.0007 27.8648 37 28.9354V29Z" fill="#6C63FF" />
            <path d="M41.8421 15H50.1579C50.2685 15 50.378 15.0239 50.4802 15.0704C50.5824 15.117 50.6752 15.1852 50.7534 15.2712C50.8316 15.3573 50.8936 15.4594 50.936 15.5718C50.9783 15.6842 51 15.8047 51 15.9263V25.0737C51 25.1953 50.9783 25.3158 50.936 25.4282C50.8936 25.5406 50.8316 25.6427 50.7534 25.7288C50.6752 25.8148 50.5824 25.883 50.4802 25.9296C50.378 25.9761 50.2685 26 50.1579 26H41.8421C41.7315 26 41.622 25.9761 41.5198 25.9296C41.4176 25.883 41.3248 25.8148 41.2466 25.7288C41.1684 25.6427 41.1064 25.5406 41.064 25.4282C41.0217 25.3158 41 25.1953 41 25.0737V15.9263C41 15.8047 41.0217 15.6842 41.064 15.5718C41.1064 15.4594 41.1684 15.3573 41.2466 15.2712C41.3248 15.1852 41.4176 15.117 41.5198 15.0704C41.622 15.0239 41.7315 15 41.8421 15V15Z" fill="#CCCCCC" />
            <path d="M43.7215 21.2388L47.6365 21.9841C47.8687 22.028 48.1093 21.9801 48.3055 21.8509C48.5016 21.7217 48.6373 21.5216 48.6828 21.2947L48.9837 19.784C49.0287 19.557 48.9797 19.3218 48.8475 19.1301C48.7153 18.9383 48.5107 18.8057 48.2785 18.7612L44.3635 18.0159C44.1313 17.972 43.8907 18.0199 43.6945 18.1491C43.4984 18.2783 43.3627 18.4784 43.3172 18.7053L43.0163 20.216C42.9713 20.443 43.0203 20.6782 43.1525 20.8699C43.2847 21.0617 43.4893 21.1943 43.7215 21.2388Z" fill="#3F3D56" />
            <path d="M45.1495 19.4153L47.7799 19.9961C47.828 20.0068 47.8778 19.9953 47.9185 19.9642C47.9591 19.9331 47.9872 19.885 47.9966 19.8305C48.006 19.7759 47.9958 19.7194 47.9684 19.6733C47.941 19.6272 47.8986 19.5953 47.8505 19.5847L45.2201 19.0039C45.172 18.9932 45.1222 19.0047 45.0815 19.0358C45.0409 19.0669 45.0128 19.115 45.0034 19.1695C44.994 19.2241 45.0042 19.2806 45.0316 19.3267C45.059 19.3728 45.1014 19.4047 45.1495 19.4153V19.4153Z" fill="white" />
            <path d="M45.1728 20.5771L46.7455 20.9946C46.8011 21.0094 46.8588 20.9934 46.9058 20.9503C46.9528 20.9071 46.9852 20.8402 46.9961 20.7644C47.0069 20.6886 46.9952 20.61 46.9635 20.546C46.9318 20.4819 46.8828 20.4377 46.8272 20.4229L45.2545 20.0054C45.2269 19.9981 45.1986 19.9982 45.1711 20.0058C45.1436 20.0134 45.1175 20.0284 45.0942 20.0497C45.071 20.0711 45.051 20.0985 45.0355 20.1304C45.02 20.1623 45.0093 20.198 45.0039 20.2356C44.9986 20.2731 44.9987 20.3117 45.0043 20.3492C45.0099 20.3867 45.0208 20.4223 45.0365 20.454C45.0522 20.4857 45.0723 20.5129 45.0957 20.534C45.1191 20.5552 45.1453 20.5698 45.1728 20.5771Z" fill="white" />
            <path d="M41 15.8649V17H51V15.8649C51 15.7513 50.9783 15.6388 50.936 15.5339C50.8936 15.4289 50.8316 15.3336 50.7534 15.2532C50.6752 15.1729 50.5824 15.1092 50.4802 15.0658C50.378 15.0223 50.2685 15 50.1579 15H41.8421C41.7315 15 41.622 15.0223 41.5198 15.0658C41.4176 15.1092 41.3248 15.1729 41.2466 15.2532C41.1684 15.3336 41.1063 15.4289 41.064 15.5339C41.0217 15.6388 41 15.7513 41 15.8649Z" fill="#B3B3B3" />
            <path d="M23.0587 17.1589C23.1127 16.9625 23.2043 16.7818 23.327 16.6296C23.4497 16.4774 23.6005 16.3574 23.7689 16.2779C23.9372 16.1985 24.119 16.1616 24.3014 16.1698C24.4838 16.1781 24.6623 16.2313 24.8245 16.3257L35.5285 12L36 15.0119L25.33 18.3168C25.1712 18.6132 24.9214 18.8336 24.6279 18.9363C24.3344 19.039 24.0177 19.0169 23.7377 18.8742C23.4577 18.7315 23.234 18.478 23.1088 18.1619C22.9837 17.8457 22.9658 17.4889 23.0587 17.1589V17.1589Z" fill="#FFB8B8" />
            <path opacity="0.2" d="M23.0587 17.1589C23.1127 16.9625 23.2043 16.7818 23.327 16.6296C23.4497 16.4774 23.6005 16.3574 23.7689 16.2779C23.9372 16.1985 24.119 16.1616 24.3014 16.1698C24.4838 16.1781 24.6623 16.2313 24.8245 16.3257L35.5285 12L36 15.0119L25.33 18.3168C25.1712 18.6132 24.9214 18.8336 24.6279 18.9363C24.3344 19.039 24.0177 19.0169 23.7377 18.8742C23.4577 18.7315 23.234 18.478 23.1088 18.1619C22.9837 17.8457 22.9658 17.4889 23.0587 17.1589V17.1589Z" fill="black" />
            <path d="M33.0419 12.635L34.1879 15.5966C34.2205 15.6809 34.2711 15.7572 34.3362 15.8204C34.4013 15.8835 34.4794 15.9321 34.5652 15.9627C34.651 15.9933 34.7424 16.0053 34.8333 15.9978C34.9242 15.9903 35.0124 15.9636 35.0919 15.9193L37.8769 14.3686C38.3118 14.2011 38.6617 13.8699 38.85 13.4476C39.0384 13.0252 39.0498 12.5462 38.8817 12.1156C38.7136 11.685 38.3798 11.3379 37.9534 11.1504C37.527 10.9628 37.0428 10.9502 36.607 11.1153L33.4971 11.7976C33.408 11.8172 33.3243 11.8555 33.2516 11.91C33.1789 11.9644 33.1189 12.0337 33.0757 12.1132C33.0325 12.1927 33.0071 12.2804 33.0013 12.3705C32.9955 12.4605 33.0093 12.5507 33.0419 12.635V12.635Z" fill="#6C63FF" />
            <path d="M21.8 20L26 22.0301L25.4486 24.2376C24.3837 28.5004 24.1011 32.9415 24.6162 37.318V37.318L21.5455 38L19 31.0977L19.8909 23.2481L21.8 20Z" fill="#6C63FF" />
            <path d="M14.5 31C16.9266 31 18.904 32.776 19 35H10C10.096 32.776 12.0734 31 14.5 31Z" fill="#3F3D56" />
            <path d="M16.0195 31.1448C16.1528 18.9008 22.1447 20.0272 22.1447 20.0272C22.1447 20.0272 22.0326 23.8383 21.9788 23.9569C18.1605 32.3642 24.4621 42.2206 32.4835 40.4074C32.6348 40.3732 32.7801 40.3385 32.9194 40.3033C33.9358 40.0564 34.8332 39.3682 35.429 38.3786C36.0249 37.3891 36.2744 36.1726 36.127 34.9765C35.3368 27.5921 39.2669 27.7228 39.2669 27.7228H45.0136L47.998 24.597C48.1033 24.4867 48.2303 24.4081 48.3678 24.368C48.5053 24.328 48.6491 24.3278 48.7867 24.3674C48.9242 24.407 49.0514 24.4852 49.157 24.5952C49.2626 24.7052 49.3434 24.8437 49.3924 24.9984L49.7763 26.2112C49.7763 26.2112 55.4444 26.8983 54.972 33.357H53.2197C53.2239 33.5406 53.2176 33.7242 53.2008 33.9067L40.1229 33.783C39.3563 33.7757 38.6796 34.4986 38.6765 35.3908C38.6757 35.608 38.7126 35.8231 38.7849 36.0234C38.8572 36.2236 38.9635 36.4048 39.0973 36.5561C39.2312 36.7075 39.3899 36.8258 39.564 36.9041C39.7381 36.9824 39.924 37.019 40.1105 37.0118L42.219 36.93C42.8684 40.2968 41.2743 42.5643 39.5178 43.99C37.864 45.3129 35.9017 46.0143 33.8949 45.9998H28.167C20.7277 45.9998 19.4288 38.7165 19.4288 38.7165C15.532 36.7926 16.0195 31.1448 16.0195 31.1448Z" fill="#3F3D56" />
            <path d="M14.5 50C10.9101 50 8 46.4183 8 42C8 37.5817 10.9101 34 14.5 34C18.0899 34 21 37.5817 21 42C21 46.4183 18.0899 50 14.5 50Z" fill="#3F3D56" />
            <path d="M14.5 44C13.6716 44 13 43.1046 13 42C13 40.8954 13.6716 40 14.5 40C15.3284 40 16 40.8954 16 42C16 43.1046 15.3284 44 14.5 44Z" fill="white" />
            <path d="M47.5 50C43.3579 50 40 45.9706 40 41C40 36.0294 43.3579 32 47.5 32C51.6421 32 55 36.0294 55 41C55 45.9706 51.6421 50 47.5 50Z" fill="#3F3D56" />
            <path d="M48 43C46.8954 43 46 42.1046 46 41C46 39.8954 46.8954 39 48 39C49.1046 39 50 39.8954 50 41C50 42.1046 49.1046 43 48 43Z" fill="white" />
            <path d="M25.4797 23L26.8996 18.4485C26.9736 18.2113 27.0073 17.9592 26.9987 17.7067C26.9901 17.4541 26.9395 17.206 26.8497 16.9765C26.7599 16.747 26.6327 16.5406 26.4753 16.3692C26.3179 16.1977 26.1335 16.0645 25.9325 15.9772L21.8618 14.2086C21.5081 14.0549 21.1323 13.985 20.7557 14.0027C20.3792 14.0204 20.0092 14.1255 19.6671 14.312C19.3249 14.4984 19.0172 14.7626 18.7616 15.0894C18.5059 15.4162 18.3073 15.7992 18.1771 16.2165C18.0597 16.5912 17.9997 16.9875 18 17.3869C18.0015 17.894 18.0988 18.3944 18.2847 18.8517C18.4706 19.309 18.7405 19.7116 19.0748 20.0303C19.3163 20.2589 19.5877 20.4395 19.8785 20.5651L25.4797 23Z" fill="#3F3D56" />
            <path d="M18 17.3698C18.0004 17.8747 18.1021 18.373 18.2977 18.8282C18.4933 19.2834 18.7779 19.6838 19.1304 20C19.6713 19.8407 20.1746 19.5518 20.6053 19.1535C21.0361 18.7551 21.3839 18.2567 21.6247 17.6931C21.8656 17.1294 21.9935 16.5139 21.9998 15.8895C22.006 15.265 21.8904 14.6465 21.6609 14.077C21.2203 13.97 20.7638 13.9746 20.325 14.0903C19.8862 14.2061 19.4762 14.4301 19.1251 14.7459C18.774 15.0617 18.4907 15.4613 18.296 15.9154C18.1013 16.3694 18.0002 16.8664 18 17.3698V17.3698Z" fill="#6C63FF" />
            <path d="M53.5 34C52.6716 34 52 32.8807 52 31.5C52 30.1193 52.6716 29 53.5 29C54.3284 29 55 30.1193 55 31.5C55 32.8807 54.3284 34 53.5 34Z" fill="#6C63FF" />
            <path d="M31.9998 40H30.6447L30 36L32 36.0001L31.9998 40Z" fill="#FFB8B8" />
            <path d="M33 39.0002L29.9462 39H29.9461C29.6905 39 29.4375 39.0751 29.2014 39.2211C28.9653 39.3671 28.7507 39.5811 28.57 39.8509C28.3893 40.1207 28.2459 40.441 28.1481 40.7935C28.0503 41.146 28 41.5238 28 41.9053V41.9997L32.9999 42L33 39.0002Z" fill="#2F2E41" />
            <path d="M30.4783 38C30.4948 38 30.5112 37.9991 30.5278 37.9972L32.4781 37.8264C32.5569 37.8176 32.6331 37.7872 32.7006 37.7374C32.7681 37.6876 32.8253 37.6199 32.8676 37.5394C32.9099 37.4589 32.9362 37.3677 32.9446 37.273C32.953 37.1783 32.9432 37.0826 32.9159 36.9932L30.3058 29.9428C30.2872 29.88 30.2796 29.8132 30.2835 29.7466C30.2874 29.68 30.3027 29.6152 30.3284 29.556C30.3541 29.4962 30.39 29.4436 30.4338 29.4017C30.4777 29.3598 30.5283 29.3295 30.5826 29.3128C31.8099 28.9271 34.9589 27.9 37.6848 26.6983C38.8527 26.1834 39.5882 25.4394 39.871 24.4868C40.2438 23.231 39.6923 22.0857 39.6687 22.0377L39.6501 22L37.0851 22.2803L34.3044 22.5647L28.2106 26.4935C27.7 26.8255 27.3121 27.3765 27.1242 28.0369C26.9364 28.6973 26.9623 29.4185 27.1969 30.0569L30.0063 37.6314C30.047 37.7411 30.1132 37.8343 30.1969 37.8997C30.2807 37.9651 30.3785 38 30.4783 38Z" fill="#2F2E41" />
            <path d="M37 9C35.3431 9 34 7.433 34 5.5C34 3.567 35.3431 2 37 2C38.6569 2 40 3.567 40 5.5C40 7.433 38.6569 9 37 9Z" fill="#FFB8B8" />
            <path d="M32.9998 40H31.6447L31 36L33 36.0001L32.9998 40Z" fill="#FFB8B8" />
            <path d="M33 40.0001L30.557 40H30.5569C30.3524 40 30.15 40.0501 29.9611 40.1474C29.7722 40.2448 29.6006 40.3874 29.456 40.5673C29.3114 40.7471 29.1967 40.9607 29.1185 41.1957C29.0403 41.4307 29 41.6825 29 41.9369V41.9998L32.9999 42L33 40.0001Z" fill="#2F2E41" />
            <path d="M31.4783 39C31.4948 39 31.5112 38.9991 31.5278 38.9972L33.4781 38.8264C33.5569 38.8176 33.6331 38.7872 33.7006 38.7374C33.7681 38.6876 33.8253 38.6199 33.8676 38.5394C33.9099 38.4589 33.9362 38.3677 33.9446 38.273C33.953 38.1783 33.9432 38.0826 33.9159 37.9932L31.3058 30.9428C31.2872 30.88 31.2796 30.8132 31.2835 30.7466C31.2874 30.68 31.3027 30.6152 31.3284 30.556C31.3541 30.4962 31.39 30.4436 31.4338 30.4017C31.4777 30.3598 31.5283 30.3295 31.5826 30.3128C32.8099 29.9271 35.9589 28.9 38.6848 27.6983C39.8527 27.1834 40.5882 26.4394 40.871 25.4868C41.2438 24.231 40.6923 23.0857 40.6687 23.0377L40.6501 23L38.0851 23.2803L35.3044 23.5647L29.2106 27.4935C28.7 27.8255 28.3121 28.3765 28.1242 29.0369C27.9364 29.6973 27.9623 30.4185 28.1969 31.0569L31.0063 38.6314C31.047 38.7411 31.1132 38.8343 31.1969 38.8997C31.2807 38.9651 31.3785 39 31.4783 39Z" fill="#2F2E41" />
            <path d="M35.9375 10.2402L39.1875 9C39.1875 9 41.2278 10.728 40.2476 15.0148C39.7359 17.227 39.885 19.5621 40.6726 21.6735C40.7675 21.9347 40.8768 22.1892 41 22.4357C41 22.4357 37.375 27.2587 34 23.6759L35.3125 16.7169C35.3125 16.7169 33.25 12.1005 35.9375 10.2402Z" fill="#6C63FF" />
            <path d="M36.2091 8.96579C36.6354 8.24537 36.9719 7.2464 36.5025 6.56094C36.0391 5.88432 35.1251 5.984 34.4154 5.6846C33.427 5.26759 32.8537 3.95671 33.0325 2.78273C33.2113 1.60875 34.0461 0.635557 35.0407 0.237358C36.0352 -0.160841 37.1567 -0.0313374 38.1307 0.427213C39.3296 0.991694 40.358 2.08365 40.7893 3.46686C41.2206 4.85007 40.99 6.51088 40.0954 7.57405C39.1362 8.71388 37.6308 9.01765 36.2382 8.99922" fill="#2F2E41" />
            <path d="M39.3654 2.81194C39.8553 3.29739 40.6028 3.08177 41.1287 2.65573C41.6545 2.22968 42.0813 1.62302 42.6502 1.28899C43.6534 0.700097 44.8662 1.09157 45.8424 1.74388C46.8187 2.39618 47.6841 3.29735 48.7154 3.80924C49.7466 4.32113 51.0625 4.34643 51.8434 3.37987C52.0356 4.05755 52.0513 4.7909 51.8884 5.47985C51.7255 6.1688 51.3919 6.7798 50.9332 7.22949C50.4745 7.67917 49.913 7.94564 49.3253 7.99254C48.7376 8.03943 48.1523 7.86447 47.6493 7.49152C46.5194 6.65374 45.8953 4.89716 44.6279 4.43476C43.9266 4.17888 43.1736 4.38497 42.4648 4.60818C41.756 4.8314 41.017 5.07259 40.3017 4.88256C39.5864 4.69254 38.9173 3.91012 39.0083 3.02588L39.3654 2.81194Z" fill="#2F2E41" />
            <path d="M23.1129 17.1411C23.1869 16.9673 23.296 16.8128 23.4324 16.6885C23.5689 16.5642 23.7293 16.4732 23.9025 16.4218C24.0758 16.3703 24.2575 16.3598 24.435 16.3909C24.6124 16.422 24.7813 16.494 24.9297 16.6018L35.8641 14L36 16.8331L25.2091 18.4997C25.0218 18.752 24.7537 18.9226 24.4556 18.9794C24.1576 19.0361 23.8502 18.9749 23.5918 18.8075C23.3334 18.64 23.142 18.3779 23.0537 18.0707C22.9654 17.7636 22.9865 17.4328 23.1129 17.1411V17.1411Z" fill="#FFB8B8" />
            <path d="M34.0156 14.2008L34.6627 17.4899C34.6811 17.5835 34.7154 17.671 34.7632 17.7465C34.8111 17.8219 34.8713 17.8836 34.9398 17.9273C35.0084 17.9709 35.0836 17.9955 35.1604 17.9994C35.2372 18.0034 35.3138 17.9865 35.3849 17.9499L37.8774 16.6691C38.259 16.5486 38.5868 16.2431 38.7889 15.8198C38.9911 15.3965 39.0511 14.89 38.9557 14.4113C38.8603 13.9327 38.6174 13.521 38.2803 13.2667C37.9431 13.0123 37.5392 12.936 37.1573 13.0546L34.4843 13.3724C34.4078 13.3816 34.3337 13.4113 34.2671 13.4595C34.2006 13.5078 34.1431 13.5735 34.0986 13.6521C34.0542 13.7307 34.0237 13.8204 34.0094 13.9151C33.995 14.0098 33.9972 14.1072 34.0156 14.2008V14.2008Z" fill="#6C63FF" />
            <path d="M24 20C23.4477 20 23 19.5523 23 19C23 18.4477 23.4477 18 24 18C24.5523 18 25 18.4477 25 19C25 19.5523 24.5523 20 24 20Z" fill="#6C63FF" />
            <path d="M-5.96046e-07 49.8629C7.77972e-05 49.8992 0.0102662 49.934 0.0283468 49.9597C0.0464275 49.9853 0.070933 49.9998 0.0965028 49.9999H79.9035C79.9291 49.9999 79.9536 49.9855 79.9717 49.9598C79.9898 49.9341 80 49.8992 80 49.8629C80 49.8265 79.9898 49.7917 79.9717 49.766C79.9536 49.7403 79.9291 49.7258 79.9035 49.7258H0.0965028C0.070933 49.7259 0.0464275 49.7404 0.0283468 49.7661C0.0102662 49.7918 7.77972e-05 49.8266 -5.96046e-07 49.8629V49.8629Z" fill="#3F3D56" />
        </svg>
    );
}
export default OnDelivery;