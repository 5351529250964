import React, { Component } from 'react';
import { Result, Button } from 'antd';

class Error404 extends Component {
	componentDidMount() {
		document.title = "Page Not Found | Loyalty Management System";
	}

	render() {
		return (
			<Result
				status="404"
				title="404"
				subTitle="Sorry, the page you visited does not exist."
				extra={<Button type="primary" onClick={e => {e.preventDefault(); this.props.history.goBack();}}>Back</Button>}
			/>
		)
	}
}

export default Error404;