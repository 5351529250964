import React from 'react';

const Packaging = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="50" viewBox="0 0 80 50" fill="none">
            <g clipPath="url(#clip0_701_1660)">
                <path d="M50.8108 27.7683C51.0908 27.7134 51.3366 27.5573 51.4952 27.3339C51.6538 27.1104 51.7124 26.8374 51.6585 26.5737C51.6464 26.5154 51.6289 26.4583 51.6061 26.4029L54.5145 24.0498L52.613 23.5449L50.1427 25.8569C49.8832 25.9731 49.6829 26.1804 49.5849 26.4341C49.487 26.6878 49.4994 26.9675 49.6195 27.2127C49.7214 27.4184 49.8949 27.5855 50.1114 27.6865C50.3279 27.7874 50.5745 27.8163 50.8108 27.7683Z" fill="#FFB6B6" />
                <path d="M69.6967 44.4561L69.8024 42.2252C70.9043 41.6724 72.1334 41.3803 73.3829 41.3745C71.6633 42.6941 71.8782 45.2379 70.7123 47.0125C70.3441 47.5634 69.8486 48.0294 69.2625 48.3761C68.6763 48.7228 68.0146 48.9412 67.3263 49.0152L65.8872 49.8422C65.6897 48.809 65.7332 47.7478 66.0148 46.7319C66.2963 45.716 66.8092 44.7694 67.518 43.9574C67.9205 43.5056 68.3819 43.1031 68.8916 42.7596C69.2362 43.6126 69.6967 44.4561 69.6967 44.4561Z" fill="#F2F2F2" />
                <path d="M65.989 48.6941L64.6858 48.694L64.0659 43.9766L65.9891 43.9766L65.989 48.6941Z" fill="#FFB6B6" />
                <path d="M66.321 49.8798L62.1194 49.8796V49.8297C62.1194 49.4227 62.2917 49.0322 62.5984 48.7444C62.9051 48.4565 63.3211 48.2948 63.7548 48.2948H63.7549L64.5224 47.7483L65.9543 48.2949L66.3211 48.2949L66.321 49.8798Z" fill="#2F2E41" />
                <path d="M59.9349 48.6941L58.6319 48.694L58.012 43.9766L59.9352 43.9766L59.9349 48.6941Z" fill="#FFB6B6" />
                <path d="M60.2673 49.8798L56.0657 49.8796V49.8297C56.0657 49.4227 56.238 49.0322 56.5447 48.7444C56.8514 48.4565 57.2674 48.2948 57.7011 48.2948H57.7012L58.4687 47.7483L59.9006 48.2949L60.2674 48.2949L60.2673 49.8798Z" fill="#2F2E41" />
                <path d="M59.3709 22.9031L67.8466 23.102C69.4564 23.9226 69.4808 25.6987 68.9534 27.8075C68.9534 27.8075 69.1314 29.0609 68.7753 29.3952C68.4192 29.7294 68.2412 29.7294 68.4192 30.3143C68.5973 30.8993 67.6565 31.8546 67.7708 31.9618C67.8851 32.0691 68.1521 32.654 68.1521 32.654L67.2619 37.4169C67.2619 37.4169 66.6387 45.6894 66.4606 45.8565C66.2826 46.0236 66.1045 45.8565 66.2826 46.2743C66.4606 46.6921 66.6387 46.525 66.4606 46.6921C66.3029 46.8517 66.1543 47.0191 66.0155 47.1935H63.863C63.863 47.1935 63.7008 46.3579 63.7008 46.2743C63.7008 46.1908 63.5227 45.6894 63.5227 45.6058C63.5227 45.5223 63.6801 45.3746 63.6801 45.3746C63.7345 45.2047 63.7712 45.0303 63.7898 44.8538C63.7898 44.6867 64.324 38.2525 64.324 38.2525L63.2556 29.8965L60.6738 38.0854C60.6738 38.0854 60.6738 45.2716 60.4958 45.4387C60.3177 45.6058 60.3177 45.5223 60.4068 45.8565C60.4958 46.1908 60.8519 46.1072 60.5848 46.3579C60.3177 46.6085 60.2287 46.1908 60.3177 46.6086L60.4068 47.0264L57.7359 47.0622C57.7359 47.0622 57.3798 46.3579 57.5579 46.1072C57.7359 45.8565 57.7253 45.9249 57.508 45.5147C57.2908 45.1045 57.2018 45.0209 57.2908 44.9373C57.3798 44.8538 57.3798 44.4093 57.3798 44.4093L57.825 36.4142C57.825 36.4142 57.914 27.9747 57.914 27.724C57.9207 27.595 57.8954 27.4663 57.84 27.3481V27.0078L58.1811 25.8021L59.3709 22.9031Z" fill="#2F2E41" />
                <path d="M50.7126 25.054L52.1451 26.1382L59.954 20.4568L59.492 10.5686C59.0528 10.8984 58.6876 11.3066 58.4176 11.7697C58.1476 12.2327 57.9781 12.7415 57.9188 13.2664L56.5809 18.8522L50.7126 25.054Z" fill="#E6E6E6" />
                <path d="M58.106 25.401C60.8701 25.7325 63.4169 24.728 65.8225 23.9698C66.9086 23.6275 68.0026 23.9848 69.0108 23.406C68.5748 19.3755 68.9981 14.8355 69.9242 9.99737C69.977 9.72348 69.9219 9.4409 69.7691 9.20235C69.6163 8.9638 69.3763 8.78558 69.0937 8.70094L66.1096 7.89735L65.1908 6.94604L62.8179 7.51142L61.9873 8.92061L59.1329 10.7309C58.9663 10.8365 58.8357 10.985 58.7569 11.1586C58.6781 11.3321 58.6544 11.5234 58.6886 11.7093C59.5462 16.2594 59.3474 20.9296 58.106 25.401Z" fill="#E6E6E6" />
                <path d="M66.9277 26.6485C67.1811 26.5239 67.3722 26.3109 67.4598 26.0553C67.5474 25.7996 67.5244 25.522 67.3959 25.2821C67.3673 25.2291 67.3339 25.1787 67.2958 25.1313L69.4097 22.1226L67.4337 22.1238L65.7292 24.9807C65.5134 25.1589 65.3809 25.4097 65.3604 25.6789C65.34 25.948 65.4332 26.2139 65.62 26.4191C65.7779 26.5909 65.9934 26.7072 66.231 26.7489C66.4686 26.7906 66.7143 26.7552 66.9277 26.6485Z" fill="#FFB6B6" />
                <path d="M66.3308 23.8397L67.6246 25.401L72.1529 19.0691L71.7612 12.6362C71.7114 11.8169 71.4415 11.0227 70.9768 10.3276C70.512 9.63246 69.8675 9.05894 69.1032 8.6604L69.1956 18.5486L66.3308 23.8397Z" fill="#E6E6E6" />
                <path d="M63.0794 6.57809C64.6788 6.57809 65.9753 5.3612 65.9753 3.86009C65.9753 2.35898 64.6788 1.14209 63.0794 1.14209C61.4801 1.14209 60.1836 2.35898 60.1836 3.86009C60.1836 5.3612 61.4801 6.57809 63.0794 6.57809Z" fill="#FFB6B6" />
                <path d="M61.2344 2.2649C61.3465 2.26572 61.458 2.27946 61.5665 2.3058C61.7803 2.35405 61.9975 2.38824 62.2164 2.4081C62.8868 2.4673 63.5167 2.73564 64.0056 3.17027C64.4944 3.60491 64.814 4.18076 64.9132 4.80585C65.0815 4.66815 65.4644 4.41486 65.7782 4.65026C65.783 4.654 65.7858 4.65592 65.7968 4.65101C66.0053 4.55822 66.3753 3.41594 66.3865 2.41018C66.3924 1.87636 66.3028 1.1947 65.8429 0.978834L65.8186 0.967461L65.8123 0.94274C65.7815 0.82266 65.4382 0.617686 64.8414 0.458149C63.757 0.168228 62.1404 -0.396546 61.2301 0.434616C61.1612 0.71482 60.9371 1.31446 60.7107 1.38243C60.46 1.45766 60.2435 1.52258 60.2094 1.81122C60.1809 2.22198 60.183 2.6341 60.2156 3.04459C60.3005 2.79341 60.4673 2.57356 60.6923 2.416C60.8524 2.31488 61.0415 2.26215 61.2344 2.2649Z" fill="#2F2E41" />
                <path d="M80 49.8967C80 49.9102 79.9972 49.9237 79.9917 49.9362C79.9862 49.9488 79.9781 49.9602 79.9679 49.9698C79.9577 49.9793 79.9455 49.9869 79.9322 49.9921C79.9188 49.9973 79.9045 49.9999 79.89 49.9999H0.109973C0.0808063 49.9999 0.0528354 49.989 0.0322115 49.9697C0.0115876 49.9503 0 49.9241 0 49.8967C0 49.8693 0.0115876 49.843 0.0322115 49.8237C0.0528354 49.8043 0.0808063 49.7935 0.109973 49.7935H79.89C79.9045 49.7934 79.9188 49.7961 79.9322 49.8012C79.9455 49.8064 79.9577 49.814 79.9679 49.8236C79.9781 49.8332 79.9862 49.8446 79.9917 49.8571C79.9972 49.8697 80 49.8831 80 49.8967Z" fill="#CCCCCC" />
                <path d="M33.0173 8.77115L22.1471 11.0665C21.8559 11.1284 21.6027 11.2961 21.443 11.5329C21.2834 11.7698 21.2303 12.0564 21.2955 12.3299L24.2763 24.7652L37.0481 22.0682C37.1531 22.0464 37.2491 21.9966 37.3247 21.9249L34.3633 9.57045C34.2975 9.29709 34.1188 9.05942 33.8664 8.90958C33.6141 8.75973 33.3087 8.70995 33.0173 8.77115Z" fill="#E6E6E6" />
                <path d="M29.0689 10.4835L23.1118 11.7415C22.8465 11.7977 22.6159 11.9504 22.4705 12.166C22.3251 12.3817 22.2767 12.6426 22.336 12.8917L25.1384 24.5832L36.4817 22.188L34.4408 13.6735C34.1779 12.5826 33.4647 11.634 32.4576 11.036C31.4505 10.438 30.2319 10.2393 29.0689 10.4835Z" fill="white" />
                <path d="M34.3002 21.5576L26.8219 23.1368C26.7899 23.1435 26.7595 23.156 26.7327 23.1738C26.7058 23.1915 26.6829 23.214 26.6653 23.2401C26.6477 23.2661 26.6358 23.2951 26.6301 23.3255C26.6245 23.3559 26.6253 23.387 26.6326 23.4171C26.6398 23.4472 26.6532 23.4756 26.6722 23.5008C26.6911 23.526 26.7151 23.5474 26.7429 23.5639C26.7707 23.5804 26.8017 23.5915 26.834 23.5967C26.8664 23.6019 26.8996 23.6011 26.9316 23.5943L34.4099 22.0152C34.442 22.0085 34.4723 21.9959 34.4992 21.9782C34.5261 21.9605 34.549 21.9379 34.5666 21.9119C34.5843 21.8858 34.5962 21.8568 34.6018 21.8264C34.6075 21.796 34.6067 21.7649 34.5994 21.7348C34.5922 21.7047 34.5788 21.6762 34.5598 21.651C34.5409 21.6258 34.5168 21.6044 34.489 21.5879C34.4612 21.5715 34.4302 21.5603 34.3978 21.5551C34.3654 21.5499 34.3322 21.5508 34.3002 21.5576Z" fill="#E4E4E4" />
                <path d="M34.0834 20.6529L26.6051 22.2321C26.5404 22.2457 26.4841 22.283 26.4487 22.3356C26.4132 22.3882 26.4015 22.4519 26.416 22.5126C26.4306 22.5733 26.4702 22.6262 26.5263 22.6594C26.5823 22.6927 26.6502 22.7037 26.7149 22.6901L34.1932 21.1109C34.2252 21.1042 34.2555 21.0916 34.2824 21.0738C34.3092 21.056 34.3321 21.0335 34.3496 21.0074C34.3672 20.9814 34.3791 20.9524 34.3847 20.922C34.3903 20.8916 34.3895 20.8605 34.3823 20.8304C34.3751 20.8003 34.3616 20.7719 34.3427 20.7467C34.3238 20.7215 34.2997 20.7001 34.272 20.6836C34.2442 20.6671 34.2133 20.6559 34.1809 20.6507C34.1486 20.6454 34.1154 20.6462 34.0834 20.6529Z" fill="#E4E4E4" />
                <path d="M29.6301 20.6431L26.3884 21.3276C26.3237 21.3412 26.2675 21.3784 26.2321 21.431C26.1966 21.4835 26.1849 21.5472 26.1995 21.6078C26.214 21.6685 26.2536 21.7213 26.3096 21.7545C26.3656 21.7878 26.4334 21.7988 26.498 21.7851L29.7397 21.1006C29.7718 21.0939 29.8022 21.0814 29.8291 21.0636C29.856 21.0459 29.8789 21.0234 29.8965 20.9973C29.9141 20.9713 29.9261 20.9422 29.9317 20.9118C29.9373 20.8815 29.9365 20.8503 29.9293 20.8202C29.9221 20.7901 29.9086 20.7617 29.8897 20.7365C29.8707 20.7113 29.8466 20.6898 29.8189 20.6734C29.7911 20.6569 29.7601 20.6458 29.7277 20.6406C29.6953 20.6354 29.6621 20.6362 29.6301 20.6431Z" fill="#E4E4E4" />
                <path d="M33.4125 17.8539L25.9342 19.433C25.9022 19.4398 25.8719 19.4524 25.845 19.4702C25.8182 19.4879 25.7953 19.5105 25.7778 19.5365C25.7423 19.5891 25.7306 19.6528 25.7451 19.7135C25.7597 19.7743 25.7993 19.8271 25.8554 19.8604C25.9114 19.8937 25.9793 19.9047 26.044 19.891L33.5223 18.3119C33.5543 18.3051 33.5846 18.2925 33.6115 18.2747C33.6383 18.257 33.6612 18.2344 33.6787 18.2084C33.6963 18.1823 33.7082 18.1533 33.7138 18.1229C33.7194 18.0925 33.7186 18.0614 33.7114 18.0313C33.7041 18.0013 33.6907 17.9728 33.6718 17.9476C33.6529 17.9224 33.6288 17.901 33.6011 17.8845C33.5733 17.868 33.5424 17.8568 33.51 17.8516C33.4777 17.8463 33.4445 17.8471 33.4125 17.8539Z" fill="#E4E4E4" />
                <path d="M33.1957 16.9496L25.7174 18.5288C25.6854 18.5355 25.655 18.548 25.6282 18.5658C25.6013 18.5835 25.5784 18.606 25.5608 18.6321C25.5432 18.6581 25.5313 18.6871 25.5257 18.7175C25.52 18.7479 25.5209 18.779 25.5281 18.8091C25.5353 18.8392 25.5487 18.8676 25.5677 18.8928C25.5866 18.918 25.6107 18.9394 25.6384 18.9559C25.6662 18.9724 25.6972 18.9835 25.7295 18.9887C25.7619 18.9939 25.7951 18.9931 25.8271 18.9863L33.3054 17.4072C33.3374 17.4004 33.3677 17.3878 33.3946 17.3701C33.4214 17.3524 33.4443 17.3298 33.4618 17.3038C33.4794 17.2778 33.4913 17.2487 33.4969 17.2184C33.5025 17.188 33.5017 17.1569 33.4945 17.1269C33.4873 17.0968 33.4739 17.0684 33.4549 17.0432C33.436 17.018 33.412 16.9966 33.3843 16.9801C33.3565 16.9637 33.3256 16.9525 33.2932 16.9473C33.2609 16.942 33.2277 16.9428 33.1957 16.9496Z" fill="#E4E4E4" />
                <path d="M28.7423 16.9393L25.5006 17.6238C25.4686 17.6306 25.4383 17.6432 25.4114 17.661C25.3846 17.6788 25.3617 17.7013 25.3442 17.7274C25.3266 17.7534 25.3147 17.7824 25.3091 17.8128C25.3035 17.8432 25.3043 17.8743 25.3115 17.9044C25.3187 17.9344 25.3322 17.9629 25.3511 17.9881C25.37 18.0133 25.394 18.0347 25.4218 18.0512C25.4495 18.0677 25.4805 18.0789 25.5129 18.0841C25.5452 18.0894 25.5784 18.0886 25.6104 18.0819L28.8521 17.3973C28.8841 17.3906 28.9145 17.3779 28.9413 17.3602C28.9681 17.3424 28.991 17.3199 29.0086 17.2938C29.0261 17.2678 29.038 17.2387 29.0436 17.2084C29.0492 17.178 29.0484 17.1469 29.0412 17.1168C29.034 17.0867 29.0205 17.0583 29.0016 17.0331C28.9827 17.0079 28.9587 16.9864 28.9309 16.97C28.9032 16.9535 28.8722 16.9423 28.8399 16.937C28.8075 16.9318 28.7744 16.9326 28.7423 16.9393Z" fill="#E4E4E4" />
                <path d="M32.5245 14.1503L25.0463 15.7294C24.9816 15.7431 24.9253 15.7803 24.8898 15.8329C24.8544 15.8855 24.8426 15.9492 24.8572 16.0099C24.8717 16.0707 24.9114 16.1235 24.9675 16.1568C25.0235 16.19 25.0913 16.2011 25.1561 16.1874L32.6343 14.6083C32.6664 14.6015 32.6967 14.5889 32.7235 14.5711C32.7504 14.5534 32.7732 14.5308 32.7908 14.5048C32.8083 14.4787 32.8203 14.4497 32.8259 14.4193C32.8315 14.3889 32.8306 14.3578 32.8234 14.3277C32.8162 14.2977 32.8028 14.2692 32.7838 14.244C32.7649 14.2188 32.7409 14.1974 32.7131 14.1809C32.6854 14.1644 32.6544 14.1532 32.6221 14.148C32.5897 14.1427 32.5566 14.1435 32.5245 14.1503Z" fill="#E6E6E6" />
                <path d="M32.308 13.246L24.8297 14.8251C24.7977 14.8319 24.7674 14.8445 24.7405 14.8622C24.7137 14.88 24.6908 14.9026 24.6733 14.9286C24.6378 14.9812 24.6261 15.0449 24.6406 15.1056C24.6552 15.1664 24.6948 15.2192 24.7509 15.2525C24.8069 15.2857 24.8748 15.2968 24.9395 15.2831L32.4178 13.704C32.4498 13.6972 32.4801 13.6846 32.507 13.6668C32.5338 13.6491 32.5567 13.6265 32.5742 13.6005C32.5918 13.5744 32.6037 13.5454 32.6093 13.515C32.6149 13.4846 32.6141 13.4535 32.6069 13.4234C32.5997 13.3934 32.5862 13.3649 32.5673 13.3397C32.5484 13.3145 32.5243 13.2931 32.4966 13.2766C32.4688 13.2601 32.4379 13.2489 32.4055 13.2437C32.3732 13.2384 32.34 13.2392 32.308 13.246Z" fill="#E6E6E6" />
                <path d="M27.8544 13.2357L24.6127 13.9202C24.548 13.9339 24.4917 13.9711 24.4562 14.0237C24.4208 14.0763 24.409 14.14 24.4236 14.2008C24.4381 14.2615 24.4778 14.3143 24.5339 14.3476C24.5899 14.3809 24.6578 14.3919 24.7225 14.3782L27.9642 13.6937C27.9962 13.6869 28.0265 13.6743 28.0534 13.6566C28.0802 13.6388 28.1031 13.6163 28.1206 13.5902C28.1382 13.5642 28.1501 13.5351 28.1557 13.5047C28.1613 13.4744 28.1605 13.4433 28.1533 13.4132C28.1461 13.3831 28.1326 13.3547 28.1137 13.3295C28.0948 13.3043 28.0707 13.2828 28.043 13.2663C28.0152 13.2499 27.9843 13.2387 27.9519 13.2334C27.9196 13.2282 27.8864 13.2289 27.8544 13.2357Z" fill="#E6E6E6" />
                <path d="M11.3312 23.1995C11.2604 23.1995 11.1924 23.226 11.1423 23.273C11.0922 23.32 11.064 23.3838 11.064 23.4503V49.3893C11.064 49.4558 11.0922 49.5196 11.1423 49.5666C11.1924 49.6137 11.2604 49.6401 11.3312 49.6402H45.9815C46.0523 49.6401 46.1203 49.6137 46.1703 49.5666C46.2204 49.5196 46.2486 49.4558 46.2487 49.3893V27.6141C46.2486 27.5476 46.2204 27.4838 46.1703 27.4368C46.1203 27.3897 46.0523 27.3633 45.9815 27.3632H26.0945C25.8926 27.3633 25.698 27.2927 25.5486 27.1652L21.0777 23.3524C20.9624 23.2539 20.8121 23.1993 20.6561 23.1995H11.3312Z" fill="#3F3D56" />
                <path d="M52.6831 16.4151L34.1298 8.92852C33.6324 8.72843 33.0707 8.7217 32.5679 8.90983C32.0652 9.09796 31.6625 9.46557 31.4481 9.93203L21.7263 31.1566L43.5254 39.9528C43.7041 40.0256 43.9014 40.0485 44.0937 40.0188L53.7523 18.9321C53.9655 18.4652 53.9727 17.938 53.7722 17.4661C53.5718 16.9942 53.1801 16.6162 52.6831 16.4151Z" fill="#E6E6E6" />
                <path d="M44.9477 14.9846L34.7801 10.8818C34.3271 10.6996 33.8156 10.6933 33.3578 10.8645C32.9 11.0357 32.5332 11.3704 32.3379 11.7951L23.1975 31.7502L42.5582 39.5625L49.2147 25.0301C50.0656 23.1666 50.0941 21.0625 49.2942 19.1793C48.4943 17.296 46.9312 15.7875 44.9477 14.9846Z" fill="white" />
                <path d="M40.0716 36.4594L27.3076 31.309C27.253 31.2868 27.1942 31.275 27.1347 31.2742C27.0752 31.2734 27.0161 31.2836 26.9608 31.3043C26.9054 31.3249 26.855 31.3556 26.8123 31.3946C26.7697 31.4335 26.7356 31.48 26.7121 31.5313C26.6886 31.5827 26.6761 31.6378 26.6753 31.6937C26.6746 31.7496 26.6855 31.805 26.7076 31.8569C26.7297 31.9088 26.7625 31.9561 26.8041 31.9961C26.8456 32.036 26.8952 32.0679 26.9499 32.0899L39.7139 37.2404C39.7685 37.2625 39.8273 37.2743 39.8868 37.2751C39.9463 37.276 40.0055 37.2657 40.0608 37.2451C40.1161 37.2244 40.1665 37.1938 40.2092 37.1548C40.2518 37.1158 40.2859 37.0694 40.3094 37.018C40.3329 36.9667 40.3454 36.9115 40.3462 36.8557C40.347 36.7998 40.336 36.7443 40.3139 36.6925C40.2918 36.6406 40.259 36.5933 40.2175 36.5533C40.1759 36.5133 40.1263 36.4814 40.0716 36.4594Z" fill="#E4E4E4" />
                <path d="M40.7792 34.9149L28.0152 29.7645C27.9048 29.72 27.78 29.7185 27.6684 29.7603C27.5568 29.8021 27.4674 29.8838 27.4199 29.9874C27.3724 30.091 27.3708 30.2081 27.4153 30.3129C27.4597 30.4177 27.5468 30.5016 27.6571 30.5462L40.4211 35.6967C40.4758 35.7187 40.5346 35.7305 40.5941 35.7312C40.6536 35.732 40.7127 35.7217 40.768 35.701C40.8233 35.6803 40.8737 35.6496 40.9163 35.6106C40.959 35.5717 40.993 35.5252 41.0166 35.4738C41.0401 35.4225 41.0526 35.3673 41.0534 35.3115C41.0542 35.2556 41.0432 35.2001 41.0212 35.1482C40.9991 35.0963 40.9664 35.049 40.9249 35.009C40.8834 34.969 40.8338 34.937 40.7792 34.9149Z" fill="#E4E4E4" />
                <path d="M34.2551 30.4536L28.7221 28.221C28.6675 28.1989 28.6087 28.1871 28.5492 28.1863C28.4898 28.1855 28.4307 28.1958 28.3754 28.2164C28.3201 28.2371 28.2697 28.2678 28.2271 28.3067C28.1844 28.3457 28.1504 28.3921 28.1269 28.4434C28.1034 28.4947 28.0909 28.5499 28.0901 28.6057C28.0893 28.6616 28.1003 28.717 28.1224 28.7689C28.1444 28.8207 28.1771 28.868 28.2187 28.908C28.2602 28.948 28.3097 28.9799 28.3644 29.0019L33.8974 31.2345C33.952 31.2566 34.0108 31.2684 34.0703 31.2692C34.1298 31.27 34.1888 31.2598 34.2441 31.2391C34.2994 31.2184 34.3498 31.1878 34.3924 31.1488C34.4351 31.1099 34.4691 31.0634 34.4926 31.0121C34.5161 30.9608 34.5286 30.9056 34.5294 30.8498C34.5302 30.794 34.5192 30.7385 34.4972 30.6867C34.4751 30.6348 34.4424 30.5875 34.4008 30.5475C34.3593 30.5075 34.3098 30.4756 34.2551 30.4536Z" fill="#E4E4E4" />
                <path d="M42.9673 30.1377L30.2034 24.9873C30.093 24.9429 29.9683 24.9414 29.8567 24.9832C29.7452 25.025 29.6558 25.1067 29.6084 25.2103C29.5609 25.3139 29.5592 25.4309 29.6037 25.5357C29.6481 25.6404 29.735 25.7244 29.8453 25.769L42.6093 30.9194C42.664 30.9416 42.7228 30.9534 42.7824 30.9542C42.8419 30.955 42.9011 30.9448 42.9565 30.9241C43.0118 30.9035 43.0623 30.8728 43.105 30.8338C43.1477 30.7948 43.1818 30.7482 43.2053 30.6969C43.2289 30.6455 43.2414 30.5903 43.2421 30.5343C43.2429 30.4784 43.2319 30.4229 43.2098 30.371C43.1877 30.3191 43.1549 30.2717 43.1133 30.2317C43.0717 30.1917 43.0221 30.1597 42.9673 30.1377Z" fill="#E4E4E4" />
                <path d="M43.6744 28.5942L30.9104 23.4438C30.8558 23.4216 30.797 23.4098 30.7375 23.409C30.678 23.4082 30.6189 23.4184 30.5635 23.439C30.5082 23.4597 30.4578 23.4904 30.4151 23.5293C30.3725 23.5683 30.3384 23.6148 30.3149 23.6661C30.2914 23.7174 30.2789 23.7726 30.2781 23.8285C30.2773 23.8843 30.2883 23.9398 30.3104 23.9917C30.3325 24.0436 30.3653 24.0908 30.4068 24.1308C30.4484 24.1708 30.498 24.2027 30.5527 24.2247L43.3167 29.3751C43.3713 29.3973 43.4301 29.4091 43.4896 29.4099C43.5491 29.4107 43.6082 29.4005 43.6636 29.3799C43.7189 29.3592 43.7693 29.3285 43.812 29.2896C43.8546 29.2506 43.8887 29.2041 43.9122 29.1528C43.9357 29.1015 43.9482 29.0463 43.949 28.9904C43.9498 28.9346 43.9388 28.8791 43.9167 28.8272C43.8946 28.7753 43.8618 28.728 43.8202 28.6881C43.7787 28.6481 43.7291 28.6162 43.6744 28.5942Z" fill="#E4E4E4" />
                <path d="M37.1507 24.1321L31.6178 21.8995C31.5073 21.855 31.3826 21.8535 31.2709 21.8953C31.1593 21.9371 31.0699 22.0188 31.0224 22.1224C30.975 22.226 30.9733 22.3431 31.0178 22.4479C31.0623 22.5527 31.1493 22.6366 31.2597 22.6812L36.7926 24.9139C36.8473 24.936 36.9061 24.9477 36.9657 24.9485C37.0252 24.9493 37.0843 24.939 37.1396 24.9183C37.1949 24.8976 37.2454 24.8669 37.288 24.8279C37.3307 24.7889 37.3648 24.7425 37.3883 24.6911C37.4118 24.6398 37.4243 24.5846 37.4251 24.5287C37.4259 24.4728 37.4149 24.4173 37.3929 24.3654C37.3708 24.3135 37.3381 24.2662 37.2965 24.2262C37.255 24.1862 37.2054 24.1542 37.1507 24.1321Z" fill="#E4E4E4" />
                <path d="M45.8627 23.8164L33.0988 18.6659C32.9884 18.6216 32.8637 18.6201 32.7522 18.662C32.6406 18.7038 32.5513 18.7854 32.5039 18.889C32.4565 18.9926 32.4548 19.1096 32.4992 19.2143C32.5436 19.3191 32.6304 19.403 32.7407 19.4477L45.5046 24.5981C45.5593 24.6202 45.6181 24.632 45.6777 24.6327C45.7372 24.6335 45.7963 24.6232 45.8516 24.6025C45.9069 24.5819 45.9574 24.5511 46 24.5122C46.0427 24.4732 46.0768 24.4267 46.1003 24.3753C46.1238 24.324 46.1363 24.2688 46.1371 24.2129C46.1379 24.1571 46.1269 24.1016 46.1049 24.0497C46.0828 23.9978 46.0501 23.9504 46.0085 23.9104C45.967 23.8704 45.9174 23.8384 45.8627 23.8164Z" fill="#6C63FF" />
                <path d="M46.5699 22.2727L33.8059 17.1223C33.6955 17.0779 33.5708 17.0764 33.4593 17.1182C33.3477 17.16 33.2584 17.2417 33.2109 17.3453C33.1635 17.4489 33.1618 17.5659 33.2062 17.6707C33.2506 17.7754 33.3376 17.8594 33.4479 17.904L46.2118 23.0545C46.2665 23.0767 46.3254 23.0886 46.385 23.0895C46.4447 23.0904 46.5039 23.0802 46.5593 23.0595C46.6147 23.0389 46.6653 23.0082 46.7081 22.9691C46.7508 22.9301 46.785 22.8836 46.8085 22.8321C46.8321 22.7807 46.8446 22.7254 46.8453 22.6695C46.8461 22.6135 46.8351 22.5579 46.8129 22.5059C46.7907 22.454 46.7578 22.4066 46.7161 22.3666C46.6744 22.3266 46.6247 22.2947 46.5699 22.2727Z" fill="#6C63FF" />
                <path d="M40.0462 17.8106L34.5133 15.578C34.4028 15.5335 34.2781 15.5319 34.1664 15.5737C34.0548 15.6155 33.9654 15.6972 33.918 15.8009C33.8705 15.9045 33.8688 16.0216 33.9133 16.1264C33.9578 16.2312 34.0448 16.3151 34.1552 16.3597L39.6882 18.5923C39.7428 18.6144 39.8016 18.6262 39.8612 18.627C39.9207 18.6277 39.9798 18.6175 40.0351 18.5968C40.0905 18.5761 40.1409 18.5454 40.1835 18.5064C40.2262 18.4674 40.2603 18.4209 40.2838 18.3696C40.3073 18.3182 40.3198 18.263 40.3206 18.2072C40.3214 18.1513 40.3104 18.0958 40.2884 18.0439C40.2663 17.992 40.2336 17.9447 40.192 17.9046C40.1505 17.8646 40.1009 17.8327 40.0462 17.8106Z" fill="#6C63FF" />
                <path d="M26.2712 24.1239L15.401 26.4193C15.1098 26.4812 14.8566 26.6489 14.6969 26.8857C14.5373 27.1226 14.4842 27.4092 14.5494 27.6827L17.5302 40.1179L30.302 37.421C30.407 37.3992 30.503 37.3494 30.5786 37.2777L27.6173 24.9232C27.5514 24.6499 27.3727 24.4122 27.1203 24.2624C26.868 24.1125 26.5626 24.0627 26.2712 24.1239Z" fill="#E6E6E6" />
                <path d="M22.3226 25.8363L16.3654 27.0943C16.1002 27.1505 15.8696 27.3032 15.7242 27.5188C15.5787 27.7345 15.5304 27.9954 15.5897 28.2445L18.3921 39.936L29.7354 37.5407L27.6945 29.0263C27.4316 27.9353 26.7183 26.9868 25.7113 26.3888C24.7042 25.7908 23.4855 25.5921 22.3226 25.8363Z" fill="white" />
                <path d="M27.5541 36.9104L20.0758 38.4895C20.0438 38.4962 20.0134 38.5088 19.9866 38.5266C19.9597 38.5443 19.9368 38.5668 19.9192 38.5928C19.9016 38.6189 19.8897 38.6479 19.8841 38.6783C19.8784 38.7087 19.8793 38.7398 19.8865 38.7699C19.8937 38.8 19.9071 38.8284 19.9261 38.8536C19.945 38.8788 19.9691 38.9002 19.9968 38.9167C20.0246 38.9331 20.0556 38.9443 20.0879 38.9495C20.1203 38.9547 20.1535 38.9539 20.1855 38.9471L27.6638 37.3679C27.6959 37.3613 27.7262 37.3487 27.7531 37.331C27.78 37.3132 27.8029 37.2907 27.8205 37.2647C27.8382 37.2386 27.8501 37.2096 27.8557 37.1792C27.8614 37.1488 27.8606 37.1177 27.8533 37.0876C27.8461 37.0575 27.8327 37.029 27.8137 37.0038C27.7948 36.9786 27.7707 36.9572 27.7429 36.9407C27.7151 36.9242 27.6841 36.9131 27.6517 36.9079C27.6193 36.9027 27.5862 36.9035 27.5541 36.9104Z" fill="#E4E4E4" />
                <path d="M27.337 36.0057L19.8588 37.5849C19.7941 37.5985 19.7378 37.6358 19.7023 37.6884C19.6669 37.741 19.6551 37.8047 19.6697 37.8654C19.6842 37.9261 19.7239 37.9789 19.78 38.0122C19.836 38.0455 19.9038 38.0565 19.9686 38.0429L27.4468 36.4637C27.5115 36.4501 27.5678 36.4128 27.6033 36.3602C27.6387 36.3076 27.6505 36.2439 27.6359 36.1832C27.6214 36.1225 27.5817 36.0697 27.5256 36.0364C27.4696 36.0031 27.4017 35.9921 27.337 36.0057Z" fill="#E4E4E4" />
                <path d="M22.8837 35.9959L19.642 36.6804C19.61 36.6871 19.5797 36.6998 19.5529 36.7175C19.5261 36.7352 19.5033 36.7578 19.4857 36.7838C19.4682 36.8098 19.4563 36.8388 19.4507 36.8692C19.4451 36.8995 19.4459 36.9306 19.4531 36.9606C19.4603 36.9907 19.4738 37.0191 19.4927 37.0442C19.5116 37.0694 19.5356 37.0908 19.5633 37.1073C19.591 37.1238 19.6219 37.1349 19.6542 37.1402C19.6866 37.1455 19.7197 37.1447 19.7517 37.1379L22.9934 36.4534C23.0255 36.4467 23.0558 36.4341 23.0827 36.4164C23.1096 36.3987 23.1326 36.3762 23.1502 36.3501C23.1678 36.3241 23.1797 36.295 23.1854 36.2646C23.191 36.2342 23.1902 36.2031 23.183 36.173C23.1758 36.1429 23.1623 36.1145 23.1433 36.0893C23.1244 36.0641 23.1003 36.0426 23.0725 36.0262C23.0447 36.0097 23.0137 35.9985 22.9813 35.9933C22.9489 35.9881 22.9158 35.989 22.8837 35.9959Z" fill="#E4E4E4" />
                <path d="M26.6661 33.2067L19.1879 34.7858C19.1558 34.7926 19.1255 34.8052 19.0987 34.8229C19.0718 34.8407 19.049 34.8632 19.0314 34.8893C18.996 34.9419 18.9842 35.0056 18.9988 35.0663C19.0133 35.127 19.053 35.1799 19.1091 35.2132C19.1651 35.2464 19.2329 35.2575 19.2977 35.2438L26.7759 33.6647C26.8406 33.651 26.8969 33.6138 26.9324 33.5612C26.9678 33.5085 26.9796 33.4449 26.965 33.3841C26.9505 33.3234 26.9108 33.2706 26.8547 33.2373C26.7987 33.204 26.7308 33.193 26.6661 33.2067Z" fill="#E4E4E4" />
                <path d="M26.4494 32.3024L18.9711 33.8815C18.939 33.8882 18.9087 33.9008 18.8818 33.9185C18.8549 33.9363 18.8321 33.9588 18.8145 33.9848C18.7969 34.0109 18.7849 34.0399 18.7793 34.0703C18.7737 34.1007 18.7745 34.1318 18.7817 34.1619C18.7889 34.1919 18.8024 34.2204 18.8213 34.2456C18.8403 34.2708 18.8643 34.2922 18.8921 34.3087C18.9199 34.3251 18.9508 34.3363 18.9832 34.3415C19.0156 34.3467 19.0487 34.3459 19.0808 34.3391L26.559 32.7599C26.5911 32.7532 26.6214 32.7406 26.6482 32.7229C26.6751 32.7051 26.6979 32.6826 26.7155 32.6566C26.7331 32.6306 26.745 32.6015 26.7506 32.5712C26.7562 32.5408 26.7554 32.5097 26.7482 32.4796C26.741 32.4496 26.7275 32.4212 26.7086 32.396C26.6897 32.3708 26.6657 32.3494 26.6379 32.3329C26.6102 32.3164 26.5792 32.3053 26.5469 32.3C26.5145 32.2948 26.4814 32.2956 26.4494 32.3024Z" fill="#E4E4E4" />
                <path d="M21.996 32.2921L18.7543 32.9766C18.6896 32.9903 18.6333 33.0275 18.5978 33.0801C18.5624 33.1327 18.5506 33.1964 18.5652 33.2572C18.5797 33.3179 18.6194 33.3707 18.6755 33.404C18.7315 33.4373 18.7994 33.4483 18.8641 33.4346L22.1058 32.7501C22.1705 32.7364 22.2268 32.6992 22.2622 32.6466C22.2977 32.594 22.3094 32.5303 22.2949 32.4696C22.2803 32.4088 22.2406 32.356 22.1846 32.3227C22.1285 32.2895 22.0607 32.2784 21.996 32.2921Z" fill="#E4E4E4" />
                <path d="M25.7784 29.503L18.3002 31.0822C18.2681 31.0889 18.2378 31.1016 18.211 31.1193C18.1841 31.1371 18.1613 31.1596 18.1437 31.1857C18.1083 31.2383 18.0965 31.302 18.1111 31.3627C18.1256 31.4234 18.1653 31.4763 18.2214 31.5095C18.2774 31.5428 18.3452 31.5538 18.41 31.5402L25.8882 29.961C25.9203 29.9543 25.9506 29.9417 25.9774 29.9239C26.0043 29.9061 26.0271 29.8836 26.0447 29.8575C26.0622 29.8315 26.0742 29.8024 26.0798 29.7721C26.0854 29.7417 26.0845 29.7106 26.0773 29.6805C26.0701 29.6504 26.0567 29.622 26.0377 29.5968C26.0188 29.5716 25.9948 29.5502 25.967 29.5337C25.9393 29.5172 25.9084 29.506 25.876 29.5008C25.8436 29.4955 25.8105 29.4963 25.7784 29.503Z" fill="#E6E6E6" />
                <path d="M25.5616 28.5987L18.0834 30.1779C18.0513 30.1846 18.021 30.1973 17.9942 30.215C17.9673 30.2328 17.9445 30.2553 17.9269 30.2814C17.8915 30.334 17.8797 30.3977 17.8943 30.4584C17.9088 30.5191 17.9485 30.572 18.0046 30.6052C18.0606 30.6385 18.1285 30.6496 18.1932 30.6359L25.6714 29.0568C25.7361 29.0431 25.7924 29.0059 25.8279 28.9532C25.8633 28.9006 25.8751 28.837 25.8605 28.7762C25.846 28.7155 25.8063 28.6627 25.7503 28.6294C25.6942 28.5961 25.6264 28.5851 25.5616 28.5987Z" fill="#E6E6E6" />
                <path d="M21.1083 28.5885L17.8666 29.273C17.8019 29.2867 17.7456 29.3239 17.7101 29.3765C17.6747 29.4291 17.6629 29.4928 17.6775 29.5535C17.692 29.6143 17.7317 29.6671 17.7878 29.7004C17.8438 29.7337 17.9117 29.7447 17.9764 29.731L21.2181 29.0465C21.2501 29.0397 21.2804 29.0271 21.3073 29.0093C21.3341 28.9916 21.357 28.969 21.3745 28.943C21.3921 28.9169 21.404 28.8879 21.4096 28.8575C21.4152 28.8272 21.4144 28.796 21.4072 28.766C21.4 28.7359 21.3865 28.7074 21.3676 28.6823C21.3487 28.6571 21.3246 28.6356 21.2969 28.6191C21.2691 28.6026 21.2382 28.5915 21.2058 28.5862C21.1735 28.5809 21.1403 28.5817 21.1083 28.5885Z" fill="#E6E6E6" />
                <path d="M11.1015 30.0125C11.0664 30.0124 11.0317 30.0189 10.9992 30.0315C10.9668 30.0441 10.9374 30.0626 10.9125 30.0859C10.8877 30.1092 10.868 30.1368 10.8546 30.1672C10.8412 30.1977 10.8342 30.2303 10.8342 30.2632C10.8342 30.2726 10.8425 49.5909 10.8425 49.5909C10.8498 49.6522 10.8809 49.7089 10.9299 49.75C10.9789 49.7911 11.0423 49.8138 11.1081 49.8137H48.3299C48.3956 49.8138 48.459 49.7911 48.508 49.75C48.557 49.7089 48.5881 49.6522 48.5954 49.5909L50.9123 30.2914C50.9165 30.2563 50.9128 30.2208 50.9014 30.1871C50.89 30.1535 50.8711 30.1225 50.8461 30.0961C50.8211 30.0697 50.7904 30.0485 50.7561 30.0341C50.7217 30.0196 50.6844 30.0123 50.6468 30.0125L11.1015 30.0125Z" fill="#3F3D56" />
                <path d="M13.7282 31.0819C13.3505 31.0829 12.9885 31.2233 12.7203 31.4729C12.4521 31.7224 12.2993 32.061 12.2949 32.4154L12.3414 47.3871C12.3397 47.5648 12.3756 47.741 12.4469 47.9056C12.5182 48.0701 12.6235 48.2198 12.7567 48.346C12.89 48.4721 13.0486 48.5723 13.2233 48.6406C13.398 48.709 13.5854 48.7441 13.7747 48.7441H37.4028C40.1128 48.7458 42.7282 47.8097 44.7489 46.1148C46.7695 44.4199 48.0535 42.0853 48.3551 39.5576L49.193 32.5778C49.2156 32.3896 49.1956 32.199 49.1344 32.0186C49.0731 31.8382 48.9719 31.6719 48.8375 31.5307C48.703 31.3895 48.5384 31.2765 48.3542 31.1992C48.17 31.1218 47.9705 31.0818 47.7688 31.0819H13.7282ZM49.937 31.0819L49.9327 30.9951H49.937V31.0819Z" fill="#6C63FF" />
                <path d="M24.0494 42.2986H14.8962C14.8016 42.2986 14.7109 42.2634 14.644 42.2006C14.5771 42.1378 14.5396 42.0527 14.5396 41.9639C14.5396 41.8751 14.5771 41.79 14.644 41.7272C14.7109 41.6645 14.8016 41.6292 14.8962 41.6292H24.0494C24.5093 41.6232 24.5139 42.3047 24.0494 42.2986Z" fill="#E4E4E4" />
                <path d="M36.3799 44.0496H14.8962C14.8016 44.0496 14.7109 44.0143 14.644 43.9516C14.5771 43.8888 14.5396 43.8037 14.5396 43.7149C14.5396 43.6261 14.5771 43.541 14.644 43.4782C14.7109 43.4154 14.8016 43.3802 14.8962 43.3802H36.3799C36.8398 43.3741 36.8444 44.0557 36.3799 44.0496Z" fill="#E4E4E4" />
            </g>
            <defs>
                <clipPath id="clip0_701_1660">
                    <rect width="80" height="50" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
export default Packaging;