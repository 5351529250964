import React from 'react';

const Delivered = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="50" viewBox="0 0 80 50" fill="none">
            <g clipPath="url(#clip0_701_1611)">
                <path d="M19.2064 27.1093C18.568 24.3979 19.5732 21.3133 22.3671 19.6721C22.2088 22.669 23.3915 25.6138 25.7061 27.9854C26.6029 28.8975 27.7201 29.8378 27.6854 30.9943C27.6638 31.7139 27.1733 32.3842 26.527 32.8893C25.8806 33.3943 25.0844 33.7584 24.2977 34.1169L24.1752 34.2681C21.8691 32.2041 19.8449 29.8207 19.2064 27.1093Z" fill="#F0F0F0" />
                <path d="M22.418 19.7134C21.2442 21.7979 20.9279 24.1228 21.5142 26.358C21.6297 26.841 21.8403 27.3065 22.1385 27.7374C22.446 28.1497 22.8871 28.4891 23.4158 28.7205C23.9034 28.944 24.4348 29.1278 24.8454 29.4415C25.0525 29.6046 25.2139 29.8005 25.319 30.0165C25.424 30.2326 25.4703 30.4637 25.4548 30.6949C25.4352 31.2718 25.1554 31.8087 24.8589 32.3262C24.5296 32.9007 24.1762 33.4934 24.1975 34.1393C24.2001 34.2176 24.0469 34.2162 24.0443 34.1381C24.0072 33.0142 25.0312 32.1116 25.2594 31.0332C25.3658 30.53 25.2818 29.9983 24.836 29.6083C24.4461 29.2672 23.8983 29.0772 23.4022 28.8543C22.8822 28.6346 22.4379 28.3173 22.1081 27.93C21.7833 27.5125 21.5503 27.0542 21.4193 26.5747C21.1026 25.499 21.001 24.3904 21.1182 23.2897C21.2433 22.0328 21.6384 20.8021 22.2865 19.651C22.3266 19.5797 22.4579 19.6426 22.418 19.7134Z" fill="white" />
                <path d="M21.2721 25.6473C20.7861 25.6745 20.3038 25.5636 19.9112 25.3347C19.5187 25.1057 19.2416 24.7735 19.1293 24.3973C19.1069 24.3211 19.255 24.2899 19.2774 24.3662C19.3812 24.7166 19.6394 25.0261 20.0054 25.2387C20.3715 25.4514 20.8213 25.5532 21.2737 25.5258C21.372 25.5199 21.3698 25.6415 21.2721 25.6473Z" fill="white" />
                <path d="M23.1498 28.6331C23.8095 28.0411 24.1638 27.2736 24.142 26.4836C24.1398 26.4053 24.293 26.4067 24.2952 26.4848C24.316 27.307 23.9452 28.1051 23.257 28.7199C23.1913 28.7785 23.0845 28.6913 23.1498 28.6331Z" fill="white" />
                <path d="M21.4229 22.1084C21.6137 22.129 21.8079 22.1117 21.9882 22.058C22.1685 22.0043 22.3291 21.9159 22.4557 21.8007C22.5203 21.7416 22.6271 21.8288 22.5629 21.8876C22.4223 22.0143 22.2447 22.1117 22.0457 22.1714C21.8467 22.2311 21.6323 22.2512 21.4213 22.23C21.4012 22.2293 21.3821 22.2226 21.3681 22.2112C21.354 22.1998 21.3459 22.1845 21.3455 22.1686C21.3458 22.1525 21.3541 22.1371 21.3686 22.1259C21.3831 22.1146 21.4026 22.1083 21.4229 22.1084Z" fill="white" />
                <path d="M33.7927 21.9149C33.7537 21.9528 33.7146 21.9906 33.6759 22.0295C33.154 22.5409 32.6854 23.085 32.2746 23.6566C32.2419 23.7004 32.2097 23.7451 32.1787 23.7896C31.2074 25.175 30.5688 26.6914 30.2953 28.2618C30.1872 28.8868 30.1391 29.5174 30.1516 30.1481C30.1689 31.0192 30.2749 31.9626 29.9384 32.7638C29.9045 32.8477 29.864 32.9298 29.8173 33.0098L24.4172 34.3749C24.4029 34.3732 24.389 34.3724 24.3747 34.3706L24.1623 34.4329C24.1587 34.4004 24.156 34.3667 24.1525 34.3342C24.1504 34.3153 24.1494 34.2962 24.1473 34.2773C24.1462 34.2647 24.1453 34.252 24.1434 34.2406C24.143 34.2364 24.1427 34.2322 24.1428 34.2289C24.1409 34.2176 24.1407 34.2068 24.1393 34.1965C24.1232 34.0078 24.1083 33.8188 24.096 33.6291C24.0956 33.6281 24.0956 33.6281 24.0964 33.6269C24.0037 32.1826 24.0622 30.7195 24.3987 29.3099C24.4088 29.2675 24.4185 29.2241 24.4311 29.1811C24.5872 28.5464 24.8085 27.9229 25.0926 27.3168C25.2495 26.9853 25.4275 26.6604 25.6258 26.3432C26.1403 25.5243 26.8139 24.7748 27.6219 24.1221C29.2329 22.821 31.3504 21.9433 33.6193 21.9157C33.6774 21.915 33.7343 21.9146 33.7927 21.9149Z" fill="#F0F0F0" />
                <path d="M33.8023 21.9723C31.2834 23.0759 29.2668 24.7812 28.0389 26.8459C27.7646 27.2867 27.5796 27.759 27.4907 28.2455C27.4234 28.7216 27.518 29.2033 27.7646 29.6406C27.9843 30.052 28.2691 30.4527 28.3589 30.8993C28.4006 31.1284 28.3808 31.362 28.3007 31.5846C28.2207 31.8073 28.0822 32.014 27.8944 32.1912C27.441 32.6424 26.8103 32.9375 26.1808 33.209C25.482 33.5104 24.7501 33.8148 24.277 34.3407C24.2196 34.4045 24.0984 34.3302 24.1556 34.2665C24.9787 33.3514 26.4812 33.12 27.4817 32.3679C27.9485 32.017 28.2849 31.5524 28.2249 31.0279C28.1724 30.5693 27.8792 30.156 27.6522 29.741C27.4037 29.3172 27.2897 28.8515 27.3203 28.3847C27.3777 27.8962 27.5395 27.419 27.7987 26.9736C28.362 25.9634 29.1221 25.0297 30.051 24.2069C31.1045 23.263 32.3538 22.4692 33.7448 21.8596C33.8309 21.8219 33.8879 21.9349 33.8023 21.9723Z" fill="white" />
                <path d="M28.385 26.1626C27.9763 25.9521 27.6753 25.6332 27.5357 25.2629C27.396 24.8925 27.4268 24.4949 27.6226 24.1409C27.6625 24.0694 27.8044 24.1152 27.7644 24.1868C27.5814 24.5162 27.5527 24.8866 27.6837 25.2313C27.8146 25.5759 28.0965 25.8721 28.4785 26.0663C28.5614 26.1086 28.4674 26.2046 28.385 26.1626Z" fill="white" />
                <path d="M27.6187 29.4435C28.5947 29.286 29.46 28.8425 30.042 28.2013C30.0996 28.1377 30.2209 28.212 30.1634 28.2755C29.5561 28.9418 28.6545 29.402 27.6384 29.5641C27.5416 29.5795 27.5224 29.4588 27.6187 29.4435Z" fill="white" />
                <path d="M31.1907 23.409C31.3275 23.5166 31.4957 23.5956 31.6804 23.6388C31.8651 23.682 32.0604 23.6882 32.2488 23.6567C32.3454 23.6404 32.3644 23.761 32.2685 23.7773C32.0601 23.8113 31.8444 23.8043 31.6402 23.7568C31.4361 23.7094 31.2496 23.6231 31.0972 23.5053C31.0817 23.4951 31.0716 23.4807 31.069 23.4649C31.0664 23.449 31.0715 23.433 31.0833 23.42C31.0958 23.4073 31.114 23.3991 31.1342 23.397C31.1543 23.3949 31.1746 23.3992 31.1907 23.409Z" fill="white" />
                <path d="M42.2751 7.08815C42.2932 7.15125 42.305 7.21539 42.3104 7.27996L48.6419 10.1782L50.1809 9.4752L51.8215 11.1794L49.2495 12.6339C49.0417 12.7514 48.7915 12.8123 48.5364 12.8074C48.2813 12.8024 48.0351 12.732 47.8348 12.6066L41.3059 8.51975C41.0113 8.60629 40.6904 8.6196 40.386 8.55792C40.0816 8.49623 39.8081 8.36247 39.6018 8.17443C39.3955 7.98639 39.2663 7.75297 39.2313 7.50523C39.1963 7.25749 39.2572 7.00716 39.4059 6.78755C39.5546 6.56794 39.784 6.38945 40.0636 6.27581C40.3433 6.16218 40.6599 6.1188 40.9714 6.15143C41.2829 6.18407 41.5744 6.29117 41.8073 6.4585C42.0402 6.62583 42.2034 6.84545 42.2751 7.08815Z" fill="#FFB6B6" />
                <path d="M48.7299 9.85811C48.7499 9.78418 48.7899 9.71456 48.847 9.654C48.9041 9.59344 48.9769 9.54336 49.0606 9.50719L51.9838 8.24346C52.373 8.01035 52.8629 7.90923 53.346 7.9623C53.8291 8.01537 54.266 8.21828 54.5608 8.52654C54.8556 8.8348 54.9843 9.22325 54.9187 9.6067C54.853 9.99014 54.5985 10.3373 54.2107 10.572L51.8679 12.4605C51.801 12.5144 51.7203 12.5562 51.6312 12.5831C51.5422 12.61 51.4468 12.6214 51.3517 12.6164C51.2566 12.6114 51.164 12.5903 51.0801 12.5543C50.9963 12.5184 50.9231 12.4685 50.8657 12.4082L48.8486 10.2861C48.7912 10.2258 48.7508 10.1563 48.7303 10.0824C48.7098 10.0085 48.7097 9.93203 48.7299 9.85811Z" fill="#3F3D56" />
                <path d="M53.952 2.51123C54.3726 1.4477 55.329 0.472265 56.6517 0.112733C57.9745 -0.246797 59.9113 0.300885 60.9058 1.08725C62.7248 2.52568 63.1687 7.4672 61.9189 9.02877C61.6704 9.01833 60.8126 9.01061 60.561 9.00313L60.2051 8.06193V8.99386C58.2414 8.9457 56.197 8.97585 54.1515 9.00703C53.9682 7.90142 53.5314 3.57476 53.952 2.51123Z" fill="#2F2E41" />
                <path d="M53.3894 47.1526H51.5984L50.7463 41.6709L53.3898 41.671L53.3894 47.1526Z" fill="#FFB6B6" />
                <path d="M69.0684 46.269L67.3352 46.6274L64.7681 41.4935L67.326 40.9646L69.0684 46.269Z" fill="#FFB6B6" />
                <path d="M59.198 13.4852L59.6039 14.3224L59.5227 15.1596L51.5801 17.3425L51.9742 13.6784L52.867 12.8411L59.198 13.4852Z" fill="#FFB6B6" />
                <path d="M56.6869 5.95077C58.2536 5.95077 59.5237 4.94301 59.5237 3.69987C59.5237 2.45674 58.2536 1.44897 56.6869 1.44897C55.1202 1.44897 53.8501 2.45674 53.8501 3.69987C53.8501 4.94301 55.1202 5.95077 56.6869 5.95077Z" fill="#FFB7B7" />
                <path d="M54.7375 0.768928C55.2826 0.505263 55.9069 0.362636 56.5453 0.355959C57.1836 0.349281 57.8124 0.478799 58.3661 0.730971C58.9197 0.983143 59.3778 1.34871 59.6925 1.78944C60.0071 2.23017 60.1668 2.72988 60.1547 3.23632C58.6084 3.54992 56.9605 3.68109 55.2142 3.31185L54.815 2.53667L54.5797 3.31242C54.0454 3.61061 53.5298 3.61007 53.0328 3.31081C53.0117 2.8058 53.158 2.30524 53.4572 1.85918C53.7563 1.41312 54.1978 1.03717 54.7375 0.768928Z" fill="#2F2E41" />
                <path d="M54.1906 49.851H52.9196L52.6928 48.8989L52.1118 49.851H48.7409C48.5794 49.851 48.422 49.81 48.2919 49.7341C48.1617 49.6581 48.0656 49.5511 48.0175 49.4287C47.9694 49.3063 47.9719 49.1749 48.0246 49.0537C48.0773 48.9325 48.1774 48.8279 48.3104 48.755L51.0022 47.2799V46.3174L53.8335 46.4515L54.1906 49.851Z" fill="#2F2E41" />
                <path d="M70.8588 48.6874L69.629 48.9418L69.1068 48.0659L68.8472 49.1034L65.5854 49.7781C65.429 49.8104 65.2637 49.8023 65.1136 49.7548C64.9636 49.7073 64.8365 49.623 64.7511 49.5142C64.6656 49.4054 64.6263 49.2778 64.6387 49.1499C64.6512 49.0221 64.7148 48.9008 64.8203 48.8037L66.9562 46.8375L66.6502 45.9061L69.4326 45.4692L70.8588 48.6874Z" fill="#2F2E41" />
                <path d="M68.8486 42.8217L63.5985 34.2599L61.9504 23.8392C63.0868 22.1647 60.9764 18.7575 60.9764 18.7575V17.3269L52.8015 18.5506C51.1294 22.1691 50.3186 26.0026 50.4102 29.856L50.7396 43.7081H50.6363C50.4648 43.7081 50.3004 43.7622 50.1791 43.8584C50.0579 43.9546 49.9897 44.0851 49.9897 44.2211C49.9897 44.3572 50.0579 44.4876 50.1791 44.5839C50.3004 44.6801 50.4648 44.7341 50.6363 44.7341H53.869C54.0405 44.7341 54.2049 44.6801 54.3262 44.5839C54.4474 44.4876 54.5155 44.3572 54.5155 44.2211C54.5155 44.0851 54.4474 43.9546 54.3262 43.8584C54.2049 43.7622 54.0405 43.7081 53.869 43.7081H53.7857L56.1064 26.0857L58.8048 34.7819L65.3257 42.7888C65.1594 42.7991 65.0046 42.8602 64.8937 42.9591C64.7829 43.0581 64.7248 43.1873 64.7315 43.3195C64.7382 43.4518 64.8092 43.5769 64.9296 43.6685C65.0501 43.7601 65.2106 43.811 65.3775 43.8107H68.6102C68.7605 43.8108 68.9062 43.7693 69.0222 43.6934C69.1381 43.6174 69.217 43.5117 69.2452 43.3945C69.2735 43.2773 69.2493 43.156 69.1769 43.0515C69.1045 42.9469 68.9884 42.8657 68.8486 42.8217Z" fill="#2F2E41" />
                <path d="M61.7156 19.6343C61.6887 19.5506 61.6292 19.4758 61.5454 19.4206C61.4617 19.3654 61.3581 19.3327 61.2494 19.3271C61.1417 18.3496 60.4297 12.0805 60.4026 11.8966L60.4015 11.8888L60.1954 8.39444C60.1879 8.26753 60.1218 8.14726 60.0096 8.05659C59.8974 7.96593 59.7471 7.91123 59.5873 7.90294L58.4984 7.84647C58.4835 7.84607 58.4693 7.84108 58.459 7.83256C58.4486 7.82403 58.4428 7.81264 58.4428 7.80078C58.4428 7.70537 58.4191 7.61089 58.373 7.52274C58.327 7.43459 58.2596 7.35449 58.1746 7.28703C58.0895 7.21956 57.9886 7.16604 57.8775 7.12953C57.7664 7.09301 57.6473 7.07422 57.5271 7.07422H54.3033C54.0945 7.07429 53.8928 7.13482 53.7363 7.24446C53.5797 7.3541 53.479 7.50531 53.453 7.6697C53.4515 7.68102 53.4448 7.69149 53.434 7.69902C53.4233 7.70656 53.4094 7.7106 53.395 7.71034L53.3947 7.71033C53.0283 7.70435 52.6715 7.80275 52.3888 7.98769C52.1061 8.17263 51.9163 8.43185 51.8539 8.71832C51.4132 10.753 50.5299 15.371 50.9666 17.9383C51.1186 18.832 51.0495 20.1762 50.992 20.9239C50.9863 20.9962 50.9986 21.0686 51.0283 21.1371C51.0579 21.2056 51.1043 21.2688 51.1647 21.323C51.0362 21.3524 50.927 21.4203 50.8603 21.5123C50.7936 21.6043 50.7745 21.7133 50.807 21.8163C50.8406 21.9218 50.9256 22.0125 51.0433 22.0683C51.1611 22.1241 51.302 22.1405 51.435 22.1139L61.3405 20.1326C61.4735 20.106 61.5877 20.0386 61.6581 19.9451C61.7284 19.8517 61.7491 19.7399 61.7156 19.6343Z" fill="#3F3D56" />
                <path d="M45.3444 34.3251C45.3426 34.0956 45.2384 33.8741 45.0503 33.6998C44.8622 33.5255 44.6024 33.4096 44.317 33.3728L26.755 31.0846C26.5866 31.0625 26.4142 31.0685 26.249 31.1024L22.1332 31.9468L19.7717 32.4319L15.4137 33.3258C15.1506 33.38 14.9176 33.5019 14.7513 33.6725C14.585 33.8431 14.4947 34.0527 14.4946 34.2683V48.8699C14.4959 49.0925 14.5929 49.3081 14.7694 49.4811C14.946 49.6541 15.1917 49.7741 15.4658 49.8214L33.3539 49.6607C33.4379 49.675 33.5236 49.682 33.6095 49.6817C33.7346 49.6819 33.8591 49.6669 33.9785 49.6371L44.6063 49.847C44.8571 49.7849 45.076 49.6603 45.2305 49.4916C45.385 49.323 45.4669 49.1194 45.464 48.9109L45.3444 34.3251Z" fill="white" />
                <path d="M45.3444 34.3251C45.3426 34.0956 45.2384 33.8741 45.0503 33.6998C44.8622 33.5255 44.6024 33.4096 44.317 33.3728L26.755 31.0846C26.5866 31.0625 26.4142 31.0685 26.249 31.1024L22.1332 31.9468L19.7717 32.4319L15.4137 33.3258C15.1506 33.38 14.9176 33.5019 14.7513 33.6725C14.585 33.8431 14.4947 34.0527 14.4946 34.2683V48.8699C14.4959 49.0925 14.5929 49.3081 14.7694 49.4811C14.946 49.6541 15.1917 49.7741 15.4658 49.8214L33.3539 49.6607C33.4379 49.675 33.5236 49.682 33.6095 49.6817C33.7346 49.6819 33.8591 49.6669 33.9785 49.6371L44.6063 49.847C44.8571 49.7849 45.076 49.6603 45.2305 49.4916C45.385 49.323 45.4669 49.1194 45.464 48.9109L45.3444 34.3251ZM44.5143 49.615L33.8865 49.8156C33.7349 49.853 33.5737 49.8592 33.4183 49.8334L15.5292 49.5837C15.3236 49.5482 15.1395 49.4581 15.0072 49.3283C14.8749 49.1985 14.8022 49.0368 14.8013 48.8699V34.2683C14.8015 34.1188 14.8593 33.973 14.9672 33.8504C15.075 33.7279 15.2277 33.6345 15.4045 33.5829C15.4325 33.5745 15.4612 33.5675 15.4903 33.5618L20.1009 32.616L22.8273 32.0563L26.3256 31.3385C26.4007 31.3226 26.478 31.3147 26.5556 31.3149C26.6056 31.3149 26.6556 31.3182 26.7049 31.3247L44.2669 33.6129C44.4082 33.6313 44.5422 33.6756 44.6582 33.7422C44.7743 33.8087 44.8692 33.8959 44.9355 33.9966C45.0018 34.0987 45.0368 34.2118 45.0377 34.3267L45.1573 48.9125C45.1595 49.0689 45.0982 49.2217 44.9823 49.3482C44.8665 49.4747 44.7024 49.5683 44.5143 49.615Z" fill="#CCCCCC" />
                <path d="M44.9355 33.9967C44.9235 34.0029 44.9104 34.0076 44.8966 34.0105L39.731 35.1185L38.1229 35.4632L33.5492 36.4439C33.5361 36.4465 33.5227 36.4478 33.5093 36.448C33.5001 36.4478 33.4909 36.4473 33.4817 36.4463L15.0395 33.7785L14.9782 33.7696C14.9382 33.7636 14.9029 33.7453 14.8799 33.7188C14.8568 33.6922 14.848 33.6594 14.8553 33.6277C14.8626 33.596 14.8854 33.5678 14.9188 33.5494C14.9522 33.531 14.9934 33.5238 15.0334 33.5295L15.4045 33.583L33.5032 36.2014L37.4451 35.3562L39.6502 34.8833L44.7361 33.7923L44.8169 33.7752C44.8518 33.7679 44.8888 33.7706 44.9215 33.7827C44.9542 33.7949 44.9805 33.8158 44.9958 33.8418C44.9997 33.848 45.0028 33.8545 45.005 33.8612C45.0134 33.8867 45.0112 33.9137 44.9987 33.9381C44.9861 33.9626 44.964 33.9831 44.9355 33.9967Z" fill="#CCCCCC" />
                <path d="M33.6627 49.7102C33.5786 49.7102 33.5101 49.6565 33.5093 50L33.356 36.7378C33.3552 36.2602 33.4232 36.2052 33.5079 36.2046H33.5093C33.5934 36.2046 33.6619 36.2583 33.6627 36.7356L33.816 49.9978C33.8168 49.6546 33.7487 49.7096 33.6641 49.7102H33.6627Z" fill="#CCCCCC" />
                <path d="M39.7745 49.864L38.1229 49.8779L37.4451 35.356L20.151 32.6257C20.1341 32.6233 20.1173 32.6201 20.1009 32.616C19.9681 32.586 19.852 32.521 19.7717 32.4318L22.1332 31.9468L22.8274 32.0563L39.2372 34.6463C39.3235 34.6598 39.4051 34.688 39.4764 34.7288C39.5477 34.7697 39.607 34.8224 39.6502 34.8831C39.7013 34.9537 39.7734 49.7796 39.7745 49.864Z" fill="#CCCCCC" />
                <path d="M53.0032 7.74527C53.0018 7.57234 52.9233 7.40538 52.7815 7.27401C52.6398 7.14263 52.444 7.05533 52.2289 7.02758L38.9932 5.30304C38.8663 5.28637 38.7364 5.29095 38.6119 5.31649L35.51 5.95288L33.7303 6.31844L30.4458 6.99212C30.2475 7.03298 30.072 7.1249 29.9466 7.25345C29.8213 7.38201 29.7532 7.53994 29.7532 7.70248V16.3099C29.7542 16.4777 29.8272 16.6402 29.9603 16.7705C30.0934 16.9009 30.2785 16.9914 30.4851 17.027L43.9665 19.303C44.0298 19.3138 44.0944 19.3191 44.1591 19.3189C44.2535 19.319 44.3473 19.3077 44.4373 19.2852L52.4469 17.2783C52.636 17.2315 52.8009 17.1375 52.9174 17.0105C53.0338 16.8834 53.0955 16.7299 53.0933 16.5728L53.0032 7.74527Z" fill="white" />
                <path d="M38.8432 5.93506C38.8336 5.93505 38.824 5.93604 38.8146 5.93799L30.6485 7.61363C30.6237 7.61872 30.6018 7.63019 30.5862 7.64623C30.5705 7.66228 30.562 7.682 30.562 7.70229V16.3096C30.5621 16.3306 30.5712 16.3509 30.5878 16.3672C30.6045 16.3835 30.6276 16.3948 30.6534 16.3992L44.1348 18.6751C44.1543 18.6784 44.1746 18.6776 44.1936 18.6729L52.2034 16.6659C52.227 16.6601 52.2476 16.6484 52.2621 16.6325C52.2767 16.6166 52.2843 16.5974 52.284 16.5778L52.1943 7.75035C52.1941 7.72875 52.1842 7.7079 52.1665 7.6915C52.1488 7.6751 52.1244 7.6642 52.0975 7.66074L38.8619 5.93625C38.8557 5.93547 38.8495 5.93507 38.8432 5.93506Z" fill="#6C63FF" />
                <path d="M53.0032 7.74527C53.0018 7.57234 52.9233 7.40538 52.7815 7.27401C52.6398 7.14263 52.444 7.05533 52.2289 7.02758L38.9932 5.30304C38.8663 5.28637 38.7364 5.29095 38.6119 5.31649L35.51 5.95288L33.7303 6.31844L30.4458 6.99212C30.2475 7.03298 30.072 7.1249 29.9466 7.25345C29.8213 7.38201 29.7532 7.53994 29.7532 7.70248V16.3099C29.7542 16.4777 29.8272 16.6402 29.9603 16.7705C30.0934 16.9009 30.2785 16.9914 30.4851 17.027L43.9665 19.303C44.0298 19.3138 44.0944 19.3191 44.1591 19.3189C44.2535 19.319 44.3473 19.3077 44.4373 19.2852L52.4469 17.2783C52.636 17.2315 52.8009 17.1375 52.9174 17.0105C53.0338 16.8834 53.0955 16.7299 53.0933 16.5728L53.0032 7.74527ZM52.3776 17.1034L44.3679 19.1104C44.2537 19.1386 44.1322 19.1433 44.0151 19.1238L30.5329 16.8479C30.378 16.8211 30.2392 16.7532 30.1395 16.6554C30.0397 16.5576 29.985 16.4357 29.9843 16.3099V7.70249C29.9844 7.58983 30.028 7.47991 30.1093 7.38754C30.1906 7.29518 30.3056 7.2248 30.4389 7.18591C30.46 7.17961 30.4816 7.17431 30.5036 7.17002L33.9784 6.45722L36.0332 6.03541L38.6696 5.49439C38.7262 5.48241 38.7845 5.47645 38.843 5.47665C38.8807 5.47664 38.9183 5.4791 38.9555 5.48399L52.1911 7.20854C52.2976 7.22241 52.3986 7.25575 52.4861 7.30594C52.5735 7.35613 52.6451 7.42178 52.695 7.4977C52.745 7.57467 52.7714 7.65991 52.7721 7.7465L52.8622 16.574C52.8639 16.6919 52.8176 16.807 52.7303 16.9024C52.643 16.9977 52.5193 17.0682 52.3776 17.1034Z" fill="#3F3D56" />
                <path d="M52.695 7.49784C52.686 7.50252 52.6761 7.50603 52.6657 7.50823L48.7726 8.34329L47.5607 8.60311L44.1136 9.34219C44.1038 9.34411 44.0937 9.34514 44.0836 9.34525C44.0766 9.34515 44.0697 9.34474 44.0628 9.34403L30.1638 7.33338L30.1176 7.32666C30.0874 7.32219 30.0608 7.30843 30.0434 7.2884C30.026 7.26836 30.0193 7.24368 30.0248 7.21975C30.0303 7.19582 30.0476 7.1746 30.0727 7.16072C30.0979 7.14685 30.129 7.14145 30.1592 7.14571L30.4388 7.18605L44.079 9.15941L47.0498 8.52241L48.7117 8.166L52.5447 7.34378L52.6056 7.33094C52.6319 7.32544 52.6598 7.32744 52.6845 7.3366C52.7091 7.34576 52.7289 7.3615 52.7404 7.38106C52.7434 7.38574 52.7457 7.39066 52.7474 7.39574C52.7537 7.41496 52.752 7.4353 52.7426 7.45371C52.7332 7.47211 52.7164 7.4876 52.695 7.49784Z" fill="#3F3D56" />
                <path d="M44.1992 19.3402C44.1687 19.3402 44.1395 19.3306 44.1178 19.3136C44.0962 19.2966 44.0839 19.2735 44.0836 19.2493L43.968 9.25416C43.9678 9.22984 43.9797 9.20643 44.0011 9.18908C44.0226 9.17173 44.0519 9.16185 44.0825 9.16162H44.0836C44.1141 9.16162 44.1433 9.17117 44.1649 9.18818C44.1866 9.2052 44.1989 9.22831 44.1992 9.25249L44.3147 19.2476C44.315 19.2719 44.3031 19.2954 44.2816 19.3127C44.2601 19.3301 44.2309 19.3399 44.2002 19.3402H44.1992Z" fill="#3F3D56" />
                <path d="M48.7726 18.1243L47.5607 18.3842L47.0499 8.52227L34.0161 6.46455C34.0033 6.46276 33.9907 6.46031 33.9783 6.45722C33.8782 6.4346 33.7907 6.38567 33.7302 6.31845L35.51 5.95288L36.0331 6.03541L48.4005 7.98737C48.4655 7.99756 48.527 8.01879 48.5807 8.0496C48.6344 8.08041 48.6791 8.12007 48.7117 8.16587C48.7503 8.21905 48.7718 18.0608 48.7726 18.1243Z" fill="#3F3D56" />
                <path d="M48.2295 12.5015C48.2915 12.5435 48.3492 12.5894 48.4021 12.6387L55.6623 11.9659L56.3684 10.6731L58.9666 11.2636L57.9969 13.6487C57.9186 13.8413 57.7636 14.0087 57.5552 14.1255C57.3468 14.2423 57.0963 14.3024 56.8412 14.2966L48.5286 14.1091C48.3573 14.3181 48.1099 14.4807 47.8192 14.5754C47.5286 14.67 47.2085 14.6922 46.9015 14.639C46.5946 14.5858 46.3153 14.4597 46.1009 14.2775C45.8865 14.0953 45.747 13.8656 45.7012 13.619C45.6553 13.3724 45.7051 13.1205 45.844 12.8969C45.9829 12.6733 46.2043 12.4886 46.4788 12.3673C46.7533 12.246 47.0678 12.1938 47.3805 12.2178C47.6933 12.2418 47.9894 12.3407 48.2295 12.5015Z" fill="#FFB6B6" />
                <path d="M55.4922 11.6676C55.4527 11.5987 55.4324 11.524 55.4326 11.4483C55.4329 11.3727 55.4537 11.298 55.4936 11.2294L56.8886 8.83113C57.0256 8.45978 57.3427 8.1467 57.7702 7.96054C58.1977 7.77438 58.7008 7.73033 59.1692 7.83806C59.6376 7.9458 60.033 8.19651 60.2688 8.53523C60.5045 8.87395 60.5614 9.27303 60.4269 9.64497L59.9675 12.2697C59.9544 12.3446 59.9211 12.4163 59.8698 12.4801C59.8186 12.5439 59.7506 12.5981 59.6706 12.6392C59.5905 12.6803 59.5003 12.7071 59.406 12.718C59.3117 12.7289 59.2155 12.7235 59.124 12.7023L55.9094 11.9548C55.8179 11.9335 55.7333 11.8969 55.6613 11.8473C55.5893 11.7978 55.5316 11.7364 55.4922 11.6676Z" fill="#3F3D56" />
                <path d="M79.8461 49.9709H0.154007C0.11325 49.9708 0.0742013 49.9579 0.0454235 49.935C0.0166456 49.9121 0.000488281 49.8811 0.000488281 49.8487C0.000488281 49.8164 0.0166456 49.7854 0.0454235 49.7625C0.0742013 49.7396 0.11325 49.7267 0.154007 49.7266H79.8461C79.8869 49.7266 79.9261 49.7394 79.9549 49.7623C79.9838 49.7853 80 49.8163 80 49.8487C80 49.8811 79.9838 49.9122 79.9549 49.9351C79.9261 49.958 79.8869 49.9709 79.8461 49.9709Z" fill="#CACACA" />
                <path d="M15.5315 36.9901C18.089 36.9901 20.1622 35.3451 20.1622 33.3158C20.1622 31.2866 18.089 29.6416 15.5315 29.6416C12.9741 29.6416 10.9009 31.2866 10.9009 33.3158C10.9009 35.3451 12.9741 36.9901 15.5315 36.9901Z" fill="#6C63FF" />
                <path d="M14.8999 35.228C14.82 35.228 14.7411 35.2132 14.6696 35.1849C14.5981 35.1565 14.5359 35.1153 14.4879 35.0645L13.2246 33.728C13.1426 33.6412 13.1074 33.5322 13.1268 33.4249C13.1461 33.3176 13.2183 33.2208 13.3276 33.1558C13.4369 33.0908 13.5743 33.0628 13.7095 33.0782C13.8448 33.0935 13.9668 33.1508 14.0487 33.2375L14.8753 34.1119L16.998 31.5854C17.0354 31.5406 17.0837 31.502 17.1399 31.472C17.1961 31.442 17.2593 31.421 17.3257 31.4104C17.3921 31.3998 17.4606 31.3997 17.5271 31.4101C17.5936 31.4204 17.6568 31.4412 17.7132 31.471C17.7696 31.5008 17.8181 31.5392 17.8557 31.5839C17.8934 31.6286 17.9196 31.6788 17.9328 31.7315C17.946 31.7843 17.9459 31.8386 17.9326 31.8913C17.9193 31.944 17.893 31.9942 17.8552 32.0388L15.3286 35.046C15.2829 35.1004 15.2214 35.1453 15.1493 35.1769C15.0772 35.2086 14.9966 35.2261 14.9143 35.2279C14.9095 35.228 14.9047 35.228 14.8999 35.228Z" fill="white" />
                <path d="M35.2488 13.8C35.2344 13.7999 35.22 13.7987 35.206 13.7961L31.1974 13.0779C31.1473 13.0689 31.1037 13.0445 31.0763 13.01C31.0489 12.9755 31.0398 12.9338 31.0511 12.894C31.0625 12.8542 31.0932 12.8196 31.1366 12.7978C31.1801 12.776 31.2326 12.7687 31.2828 12.7777L35.2914 13.4959C35.3379 13.5042 35.3789 13.5259 35.4066 13.5567C35.4343 13.5875 35.4467 13.6254 35.4414 13.663C35.4362 13.7006 35.4136 13.7354 35.3781 13.7607C35.3425 13.7859 35.2965 13.7999 35.2488 13.8Z" fill="white" />
                <path d="M19.8214 42.7062C19.8073 42.7085 19.7929 42.7096 19.7785 42.7094L15.6696 42.6545C15.6182 42.6537 15.5693 42.6368 15.5337 42.6075C15.498 42.5782 15.4784 42.5388 15.4793 42.498C15.4801 42.4573 15.5013 42.4184 15.5382 42.3901C15.5751 42.3617 15.6247 42.3461 15.6761 42.3467L19.785 42.4016C19.8327 42.4022 19.8784 42.4168 19.9134 42.4425C19.9484 42.4682 19.9703 42.5033 19.9748 42.541C19.9793 42.5787 19.9662 42.6163 19.9379 42.6468C19.9096 42.6773 19.8681 42.6984 19.8214 42.7062Z" fill="#E6E6E6" />
                <path d="M21.2438 44.4506C21.2297 44.4529 21.2153 44.454 21.2009 44.4538L17.092 44.3989C17.0406 44.3981 16.9917 44.3812 16.956 44.3519C16.9203 44.3225 16.9008 44.2832 16.9016 44.2424C16.9025 44.2017 16.9237 44.1628 16.9606 44.1345C16.9975 44.1061 17.047 44.0905 17.0984 44.0911L21.2073 44.146C21.255 44.1466 21.3008 44.1612 21.3358 44.1869C21.3708 44.2126 21.3927 44.2477 21.3972 44.2853C21.4017 44.323 21.3885 44.3607 21.3602 44.3912C21.3319 44.4217 21.2905 44.4428 21.2438 44.4506Z" fill="#E6E6E6" />
            </g>
            <defs>
                <clipPath id="clip0_701_1611">
                    <rect width="80" height="50" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
export default Delivered;