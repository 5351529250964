import React from 'react';


const Printed = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="50" viewBox="0 0 80 50" fill="none">
            <path d="M6.86098 20.4461L3.00358 18.5843C2.95155 16.5923 3.46772 14.6195 4.50729 12.8373C5.39663 16.2964 10.0769 17.9421 12.2364 20.9623C12.9015 21.9087 13.3095 22.9712 13.4302 24.0712C13.551 25.1711 13.3814 26.2802 12.934 27.3163L13.1876 29.9982C11.194 29.4865 9.35352 28.6105 7.79299 27.4305C6.23246 26.2506 4.98899 24.7947 4.14825 23.1633C3.68718 22.2441 3.36302 21.2787 3.18344 20.2901C4.98192 20.4541 6.86098 20.4461 6.86098 20.4461Z" fill="#F2F2F2" />
            <path d="M71.1293 44.9114L71.2403 42.8037C72.3976 42.2814 73.6885 42.0055 75.0007 42C73.1947 43.2467 73.4204 45.65 72.1959 47.3266C71.8092 47.847 71.2888 48.2873 70.6732 48.6148C70.0577 48.9424 69.3627 49.1488 68.6398 49.2187L67.1284 50C66.921 49.0238 66.9667 48.0213 67.2624 47.0615C67.5581 46.1017 68.0967 45.2074 68.8412 44.4403C69.2638 44.0134 69.7485 43.6332 70.2837 43.3085C70.6457 44.1144 71.1293 44.9114 71.1293 44.9114Z" fill="#F2F2F2" />
            <path d="M67.0005 48L65.6454 47.9999L65.0007 44L67.0007 44.0001L67.0005 48Z" fill="#FFB6B6" />
            <path d="M67.0007 50L63.0007 49.9999V49.9531C63.0008 49.5711 63.1648 49.2048 63.4568 48.9347C63.7487 48.6646 64.1447 48.5128 64.5576 48.5128L65.2884 48L66.6516 48.5129L67.0007 48.5129L67.0007 50Z" fill="#2F2E41" />
            <path d="M61.0004 48L59.6454 47.9999L59.0007 44L61.0007 44.0001L61.0004 48Z" fill="#FFB6B6" />
            <path d="M61.0007 50L57.0007 49.9999V49.9531C57.0008 49.5711 57.1648 49.2048 57.4568 48.9347C57.7487 48.6646 58.1447 48.5128 58.5576 48.5128L59.2884 48L60.6516 48.5129L61.0007 48.5129L61.0007 50Z" fill="#2F2E41" />
            <path d="M60.1144 24L68.5876 24.1883C70.1968 24.9654 70.2212 26.6471 69.694 28.6439C69.694 28.6439 69.872 29.8307 69.516 30.1472C69.16 30.4637 68.982 30.4637 69.16 31.0175C69.338 31.5714 68.3975 32.476 68.5117 32.5775C68.626 32.6791 68.893 33.2329 68.893 33.2329L68.003 37.7428C68.003 37.7428 67.38 45.5758 67.202 45.7341C67.024 45.8923 66.846 45.7341 67.024 46.1297C67.202 46.5253 67.38 46.367 67.202 46.5253C67.0443 46.6764 66.8957 46.8349 66.757 47H64.6052C64.6052 47 64.443 46.2088 64.443 46.1297C64.443 46.0505 64.265 45.5758 64.265 45.4967C64.265 45.4176 64.4223 45.2777 64.4223 45.2777C64.4766 45.1169 64.5134 44.9517 64.532 44.7846C64.532 44.6264 65.066 38.534 65.066 38.534L63.998 30.6219L61.4169 38.3758C61.4169 38.3758 61.4169 45.1802 61.2389 45.3385C61.0609 45.4967 61.0609 45.4176 61.1499 45.7341C61.2389 46.0505 61.595 45.9714 61.3279 46.2088C61.0609 46.4461 60.9719 46.0505 61.0609 46.4462L61.1499 46.8418L58.4799 46.8757C58.4799 46.8757 58.1239 46.2088 58.3019 45.9714C58.4799 45.7341 58.4693 45.7989 58.2521 45.4104C58.0349 45.022 57.9459 44.9429 58.0349 44.8637C58.1239 44.7846 58.1239 44.3638 58.1239 44.3638L58.5689 36.7934C58.5689 36.7934 58.6579 28.8022 58.6579 28.5648C58.6646 28.4426 58.6393 28.3208 58.584 28.2088V27.8867L58.9249 26.745L60.1144 24Z" fill="#2F2E41" />
            <path d="M65.5007 9C66.8814 9 68.0007 7.88071 68.0007 6.5C68.0007 5.11929 66.8814 4 65.5007 4C64.12 4 63.0007 5.11929 63.0007 6.5C63.0007 7.88071 64.12 9 65.5007 9Z" fill="#FFB6B6" />
            <path d="M64.4174 10.1544C63.8609 10.4586 63.5279 11.0175 63.3368 11.5863C62.983 12.6392 62.77 13.7279 62.7026 14.8283L62.5007 18.1225L60.0007 25.1368C62.1674 26.8224 70.3116 25.5199 70.3116 25.5199C70.3116 25.5199 70.5616 25.4433 70.3116 25.2134C70.0616 24.9836 69.8182 25.1872 70.0682 24.9573C70.3182 24.7275 70.1459 24.9836 70.0625 24.7537C69.9792 24.5239 70.0625 24.6771 70.1459 24.6005C70.2292 24.5239 69.5007 23.8343 69.5007 23.8343L70.1674 19.808L71.0007 11.6867C70.0007 10.5374 67.9896 9.85998 67.9896 9.85998L67.4699 9L64.8717 9.19111L64.4174 10.1544Z" fill="#E6E6E6" />
            <path d="M64.0124 4.88512C64.1209 4.8858 64.2289 4.89724 64.3339 4.91916C64.541 4.95932 64.7512 4.98777 64.9632 5.00431C65.6122 5.05358 66.2221 5.27693 66.6954 5.63868C67.1687 6.00043 67.478 6.47972 67.5741 7C67.7371 6.88539 68.1078 6.67457 68.4116 6.8705C68.4162 6.87361 68.4189 6.87521 68.4296 6.87112C68.6314 6.79389 68.9896 5.84315 69.0005 5.00604C69.0062 4.56173 68.9195 3.99437 68.4742 3.8147L68.4507 3.80524L68.4445 3.78466C68.4148 3.68472 68.0824 3.51411 67.5046 3.38133C66.4547 3.14002 64.8896 2.66995 64.0083 3.36174C63.9416 3.59496 63.7246 4.09405 63.5054 4.15062C63.2627 4.21324 63.0531 4.26727 63.02 4.50752C62.9924 4.8494 62.9944 5.19241 63.026 5.53407C63.1083 5.32501 63.2697 5.14202 63.4876 5.01088C63.6425 4.92672 63.8257 4.88284 64.0124 4.88512Z" fill="#2F2E41" />
            <path d="M58.7434 18L63.0007 15.5556L60.8564 13L58.7391 15.3351L52.1419 15.6978C51.9684 15.5441 51.7487 15.4389 51.5097 15.3951C51.2706 15.3513 51.0225 15.3708 50.7956 15.4513C50.5687 15.5317 50.3729 15.6696 50.2319 15.8481C50.0909 16.0267 50.0109 16.2381 50.0016 16.4567C49.9924 16.6753 50.0543 16.8916 50.1797 17.0792C50.3052 17.2668 50.4889 17.4176 50.7083 17.5133C50.9277 17.6089 51.1734 17.6453 51.4155 17.6179C51.6576 17.5905 51.8855 17.5005 52.0715 17.359L58.7434 18Z" fill="#FFB6B6" />
            <path d="M66.9907 11.8681C67.2531 13.3051 62.2978 16.0492 62.2978 16.0492C62.2977 15.7119 59.3401 18.2582 59.2066 17.9785C58.8272 17.1835 58.5605 15.3004 58.0303 14.999C57.7271 14.8266 59.8563 13.2074 59.8563 13.2074C59.8563 13.2074 60.9709 12.1913 62.4335 10.9097C62.8396 10.5405 63.3423 10.2702 63.8949 10.124C64.4475 9.97786 65.0319 9.96059 65.5938 10.0738C65.5938 10.0738 66.7283 10.4311 66.9907 11.8681Z" fill="#E6E6E6" />
            <path d="M76.0007 18.5595L73.4955 15L71.0007 16.8686L73.3571 18.6165L73.8413 24.201C73.692 24.3511 73.5918 24.5394 73.5528 24.7429C73.5139 24.9464 73.5379 25.1563 73.622 25.347C73.7061 25.5377 73.8466 25.701 74.0263 25.8169C74.2061 25.9328 74.4174 25.9964 74.6344 25.9998C74.8514 26.0033 75.0648 25.9465 75.2486 25.8364C75.4323 25.7263 75.5785 25.5677 75.6692 25.3798C75.76 25.1919 75.7914 24.9829 75.7597 24.7782C75.7279 24.5736 75.6344 24.3822 75.4905 24.2274L76.0007 18.5595Z" fill="#FFB6B6" />
            <path d="M69.0296 11.0116C70.6191 10.7464 73.7705 15.1277 73.7705 15.1277C73.3961 15.1348 76.2854 17.7307 75.9777 17.8562C75.1033 18.2127 73.0188 18.4912 72.6955 18.9724C72.5106 19.2476 70.668 17.3742 70.668 17.3742C70.668 17.3742 69.5165 16.3971 68.0628 15.1138C67.6442 14.7578 67.3335 14.3132 67.1595 13.8213C66.9855 13.3293 66.9539 12.8062 67.0677 12.3004C67.0677 12.3004 67.4401 11.2767 69.0296 11.0116Z" fill="#E6E6E6" />
            <path d="M52.2184 23.2434H11.8911C11.261 23.244 10.657 23.4588 10.2115 23.8406C9.76596 24.2223 9.51536 24.74 9.51465 25.2799V46.7958C9.51536 47.3358 9.76597 47.8534 10.2115 48.2352C10.657 48.617 11.261 48.8317 11.8911 48.8323H52.2184C52.8484 48.8317 53.4525 48.617 53.898 48.2352C54.3435 47.8534 54.5941 47.3358 54.5948 46.7958V25.2799C54.5941 24.74 54.3435 24.2223 53.898 23.8405C53.4525 23.4588 52.8484 23.244 52.2184 23.2434Z" fill="#6C63FF" />
            <path d="M52.2184 23.2434H11.8911C11.261 23.244 10.657 23.4588 10.2115 23.8406C9.76596 24.2223 9.51536 24.74 9.51465 25.2799V46.7958C9.51536 47.3358 9.76597 47.8534 10.2115 48.2352C10.657 48.617 11.261 48.8317 11.8911 48.8323H52.2184C52.8484 48.8317 53.4525 48.617 53.898 48.2352C54.3435 47.8534 54.5941 47.3358 54.5948 46.7958V25.2799C54.5941 24.74 54.3435 24.2223 53.898 23.8405C53.4525 23.4588 52.8484 23.244 52.2184 23.2434ZM52.9983 38.6986C52.9886 40.889 51.9776 42.989 50.182 44.548C48.3863 46.107 45.9493 47.0007 43.3935 47.0376C39.3016 47.0833 45.6575 47.1011 40.7224 47.1011C29.4372 47.1011 17.67 47.0087 13.2794 46.9487C12.7018 46.9385 12.1517 46.7352 11.7466 46.3822C11.3415 46.0291 11.1134 45.5544 11.1112 45.0593V25.2815C11.1114 25.1042 11.1936 24.9342 11.3397 24.8087C11.4858 24.6831 11.684 24.6123 11.8909 24.6117H52.2168C52.4236 24.6119 52.622 24.6823 52.7684 24.8075C52.9149 24.9326 52.9975 25.1024 52.9983 25.2797V38.6986Z" fill="#3F3D56" />
            <path d="M47.2541 10.8539H16.5289C16.2571 10.8542 15.992 10.9264 15.7698 11.0607C15.5476 11.195 15.3792 11.3847 15.2876 11.604L10.5423 23.0128C10.4715 23.183 10.4494 23.3654 10.4779 23.5445C10.5064 23.7237 10.5847 23.8943 10.7061 24.0421C10.8276 24.1899 10.9887 24.3106 11.1759 24.394C11.363 24.4774 11.5708 24.521 11.7818 24.5213L52.9893 24.5839H52.9911C53.2092 24.5839 53.4239 24.5375 53.6159 24.4489C53.8079 24.3603 53.9712 24.2323 54.0913 24.0763C54.2113 23.9203 54.2843 23.7412 54.3037 23.555C54.3231 23.3689 54.2883 23.1815 54.2024 23.0098L48.4654 11.5383C48.364 11.3351 48.1951 11.1619 47.9797 11.0402C47.7644 10.9185 47.5121 10.8537 47.2541 10.8539Z" fill="#3F3D56" />
            <path d="M54.3091 23.2575C54.309 23.4105 54.2727 23.5619 54.2024 23.7025L48.4658 32.5328C48.3636 32.7356 48.1945 32.9084 47.9792 33.0299C47.764 33.1514 47.5119 33.2163 47.2542 33.2166H16.5294C16.2575 33.2163 15.9923 33.1442 15.7699 33.01C15.5476 32.8758 15.3789 32.6861 15.287 32.4668L10.5427 23.6994C10.4845 23.5598 10.459 23.4116 10.4677 23.2635C10.4764 23.1154 10.5191 22.9702 10.5933 22.8362C10.6675 22.7022 10.7718 22.5821 10.9002 22.4827C11.0286 22.3833 11.1786 22.3065 11.3417 22.2568C11.4827 22.2132 11.6315 22.1908 11.7815 22.1908L16.8152 22.1827L18.8696 22.1796L45.9039 22.139L47.9595 22.1359L52.9896 22.1278H52.9908C53.3404 22.1279 53.6757 22.2469 53.9229 22.4587C54.1701 22.6706 54.309 22.9579 54.3091 23.2575Z" fill="#3F3D56" />
            <path d="M14.9142 39.3828C15.5235 39.3828 23.8901 39.821 23.8901 40.3431C23.8901 40.8652 15.5235 41.2736 14.9142 41.2736C14.6216 41.2736 14.341 41.174 14.1341 40.9967C13.9273 40.8194 13.811 40.5789 13.811 40.3282C13.811 40.0775 13.9273 39.837 14.1341 39.6597C14.341 39.4824 14.6216 39.3828 14.9142 39.3828Z" fill="#3F3D56" />
            <path d="M45.3408 3.91333H19.434C18.7397 3.91402 18.074 4.15068 17.5831 4.57139C17.0922 4.9921 16.816 5.56252 16.8152 6.15749V33.2165H47.2542C47.5041 33.2172 47.749 33.1561 47.9596 33.0407V6.15749C47.9588 5.56252 47.6826 4.9921 47.1917 4.57139C46.7007 4.15068 46.0351 3.91402 45.3408 3.91333Z" fill="#E6E6E6" />
            <path d="M35.4525 5.73291H21.2549C20.6227 5.73344 20.0165 5.94882 19.5693 6.33182C19.1222 6.71481 18.8706 7.23415 18.8696 7.77593V33.2166H45.9039V14.6892C45.9007 12.3147 44.7985 10.0382 42.8392 8.35919C40.8799 6.68014 38.2234 5.73565 35.4525 5.73291Z" fill="white" />
            <path d="M41.2981 30.9572H23.4752C23.3211 30.9572 23.1734 31.0096 23.0644 31.103C22.9555 31.1963 22.8943 31.3229 22.8943 31.455C22.8943 31.587 22.9555 31.7136 23.0644 31.8069C23.1734 31.9003 23.3211 31.9528 23.4752 31.9528H41.2981C41.4521 31.9528 41.5999 31.9003 41.7088 31.8069C41.8177 31.7136 41.879 31.587 41.879 31.455C41.879 31.3229 41.8177 31.1963 41.7088 31.103C41.5999 31.0096 41.4521 30.9572 41.2981 30.9572Z" fill="#E4E4E4" />
            <path d="M41.2982 28.9883H23.4753C23.3211 28.9883 23.1732 29.0408 23.0641 29.1342C22.9551 29.2277 22.8938 29.3544 22.8938 29.4866C22.8938 29.6187 22.9551 29.7455 23.0641 29.8389C23.1732 29.9324 23.3211 29.9849 23.4753 29.9849H41.2982C41.4524 29.9849 41.6003 29.9324 41.7093 29.8389C41.8184 29.7455 41.8797 29.6187 41.8797 29.4866C41.8797 29.3544 41.8184 29.2277 41.7093 29.1342C41.6003 29.0408 41.4524 28.9883 41.2982 28.9883Z" fill="#E4E4E4" />
            <path d="M31.2011 27.0204H23.4752C23.3211 27.0204 23.1734 27.0728 23.0644 27.1662C22.9555 27.2595 22.8943 27.3862 22.8943 27.5182C22.8943 27.6502 22.9555 27.7768 23.0644 27.8702C23.1734 27.9635 23.3211 28.016 23.4752 28.016H31.2011C31.3552 28.016 31.5029 27.9635 31.6119 27.8702C31.7208 27.7768 31.782 27.6502 31.782 27.5182C31.782 27.3862 31.7208 27.2595 31.6119 27.1662C31.5029 27.0728 31.3552 27.0204 31.2011 27.0204Z" fill="#E4E4E4" />
            <path d="M41.2982 22.8978H23.4753C23.3211 22.8978 23.1732 22.9503 23.0641 23.0438C22.9551 23.1372 22.8938 23.264 22.8938 23.3961C22.8938 23.5283 22.9551 23.655 23.0641 23.7485C23.1732 23.8419 23.3211 23.8944 23.4753 23.8944H41.2982C41.4524 23.8944 41.6003 23.8419 41.7093 23.7485C41.8184 23.655 41.8797 23.5283 41.8797 23.3961C41.8797 23.264 41.8184 23.1372 41.7093 23.0438C41.6003 22.9503 41.4524 22.8978 41.2982 22.8978Z" fill="#E4E4E4" />
            <path d="M41.2981 20.9301H23.4752C23.3211 20.9301 23.1734 20.9825 23.0644 21.0759C22.9555 21.1692 22.8943 21.2958 22.8943 21.4279C22.8943 21.5599 22.9555 21.6865 23.0644 21.7798C23.1734 21.8732 23.3211 21.9257 23.4752 21.9257H41.2981C41.4521 21.9257 41.5999 21.8732 41.7088 21.7798C41.8177 21.6865 41.879 21.5599 41.879 21.4279C41.879 21.2958 41.8177 21.1692 41.7088 21.0759C41.5999 20.9825 41.4521 20.9301 41.2981 20.9301Z" fill="#E4E4E4" />
            <path d="M31.2012 18.9612H23.4753C23.3211 18.9612 23.1732 19.0137 23.0641 19.1071C22.9551 19.2006 22.8938 19.3273 22.8938 19.4595C22.8938 19.5916 22.9551 19.7184 23.0641 19.8118C23.1732 19.9053 23.3211 19.9578 23.4753 19.9578H31.2012C31.3554 19.9578 31.5033 19.9053 31.6124 19.8118C31.7214 19.7184 31.7827 19.5916 31.7827 19.4595C31.7827 19.3273 31.7214 19.2006 31.6124 19.1071C31.5033 19.0137 31.3554 18.9612 31.2012 18.9612Z" fill="#E4E4E4" />
            <path d="M41.2982 14.8385H23.4753C23.3211 14.8385 23.1732 14.891 23.0641 14.9845C22.9551 15.0779 22.8938 15.2046 22.8938 15.3368C22.8938 15.469 22.9551 15.5957 23.0641 15.6892C23.1732 15.7826 23.3211 15.8351 23.4753 15.8351H41.2982C41.4524 15.8351 41.6003 15.7826 41.7093 15.6892C41.8184 15.5957 41.8797 15.469 41.8797 15.3368C41.8797 15.2046 41.8184 15.0779 41.7093 14.9845C41.6003 14.891 41.4524 14.8385 41.2982 14.8385Z" fill="#6C63FF" />
            <path d="M41.2982 12.8707H23.4753C23.3211 12.8707 23.1732 12.9232 23.0641 13.0167C22.9551 13.1101 22.8938 13.2369 22.8938 13.369C22.8938 13.5012 22.9551 13.6279 23.0641 13.7214C23.1732 13.8148 23.3211 13.8673 23.4753 13.8673H41.2982C41.4524 13.8673 41.6003 13.8148 41.7093 13.7214C41.8184 13.6279 41.8797 13.5012 41.8797 13.369C41.8797 13.2369 41.8184 13.1101 41.7093 13.0167C41.6003 12.9232 41.4524 12.8707 41.2982 12.8707Z" fill="#6C63FF" />
            <path d="M31.2012 10.9019H23.4753C23.3211 10.9019 23.1732 10.9544 23.0641 11.0478C22.9551 11.1413 22.8938 11.268 22.8938 11.4002C22.8938 11.5323 22.9551 11.6591 23.0641 11.7525C23.1732 11.846 23.3211 11.8985 23.4753 11.8985H31.2012C31.3554 11.8985 31.5033 11.846 31.6124 11.7525C31.7214 11.6591 31.7827 11.5323 31.7827 11.4002C31.7827 11.268 31.7214 11.1413 31.6124 11.0478C31.5033 10.9544 31.3554 10.9019 31.2012 10.9019Z" fill="#6C63FF" />
            <path d="M46.9883 7.75406C49.487 7.75406 51.5125 6.01826 51.5125 3.87703C51.5125 1.73581 49.487 0 46.9883 0C44.4897 0 42.4641 1.73581 42.4641 3.87703C42.4641 6.01826 44.4897 7.75406 46.9883 7.75406Z" fill="#6C63FF" />
            <path d="M46.3711 5.89483C46.293 5.89483 46.2159 5.87924 46.1461 5.84929C46.0762 5.81934 46.0154 5.77586 45.9686 5.72229L44.7343 4.31198C44.6944 4.26669 44.6654 4.2151 44.6488 4.16017C44.6322 4.10524 44.6285 4.04805 44.6378 3.99187C44.6471 3.9357 44.6692 3.88165 44.7029 3.83281C44.7366 3.78397 44.7812 3.74132 44.8341 3.70728C44.8871 3.67324 44.9474 3.64849 45.0115 3.63445C45.0757 3.62041 45.1424 3.61735 45.2079 3.62545C45.2735 3.63356 45.3365 3.65266 45.3934 3.68166C45.4503 3.71067 45.4999 3.74901 45.5395 3.7945L46.347 4.71708L48.421 2.05118C48.495 1.95601 48.6101 1.88994 48.741 1.86751C48.8719 1.84508 49.0078 1.86812 49.1189 1.93157C49.2299 1.99501 49.307 2.09367 49.3332 2.20583C49.3594 2.31799 49.3325 2.43446 49.2585 2.52963L46.7899 5.70278C46.7452 5.76016 46.6852 5.80754 46.6148 5.84093C46.5443 5.87433 46.4656 5.89276 46.3851 5.89469C46.3805 5.89476 46.3758 5.89483 46.3711 5.89483Z" fill="white" />
        </svg>
    );
}
export default Printed;