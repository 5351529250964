import React from 'react';

const Reorder = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="50" viewBox="0 0 80 50" fill="none">
            <rect x="38" y="11" width="15" height="2" rx="1" fill="#2970CC" />
            <path fillRule="evenodd" clipRule="evenodd" d="M31.3267 19.3877C34.1444 19.3877 36.4287 17.1034 36.4287 14.2856C36.4287 11.4679 34.1444 9.18359 31.3267 9.18359C28.5089 9.18359 26.2246 11.4679 26.2246 14.2856C26.2246 17.1034 28.5089 19.3877 31.3267 19.3877ZM34.2813 12.651C34.5056 12.1463 34.2783 11.5553 33.7736 11.331C33.2689 11.1067 32.678 11.334 32.4537 11.8387L30.9226 15.2836L29.8858 14.506C29.444 14.1747 28.8172 14.2642 28.4858 14.706C28.1545 15.1479 28.244 15.7747 28.6858 16.106L30.4254 17.4107C31.0936 17.9119 32.0542 17.6619 32.3934 16.8987L34.2813 12.651Z" fill="#3E9944" />
            <path fillRule="evenodd" clipRule="evenodd" d="M31.3267 30.6123C34.1444 30.6123 36.4287 28.328 36.4287 25.5102C36.4287 22.6925 34.1444 20.4082 31.3267 20.4082C28.5089 20.4082 26.2246 22.6925 26.2246 25.5102C26.2246 28.328 28.5089 30.6123 31.3267 30.6123ZM34.2813 23.8756C34.5056 23.3709 34.2783 22.7799 33.7736 22.5556C33.2689 22.3313 32.678 22.5586 32.4537 23.0633L30.9226 26.5082L29.8858 25.7307C29.444 25.3993 28.8172 25.4888 28.4858 25.9307C28.1545 26.3725 28.244 26.9993 28.6858 27.3307L30.4254 28.6353C31.0936 29.1365 32.0542 28.8865 32.3934 28.1233L34.2813 23.8756Z" fill="#F2A130" />
            <path fillRule="evenodd" clipRule="evenodd" d="M31.3267 41.8367C34.1444 41.8367 36.4287 39.5524 36.4287 36.7346C36.4287 33.9168 34.1444 31.6326 31.3267 31.6326C28.5089 31.6326 26.2246 33.9168 26.2246 36.7346C26.2246 39.5524 28.5089 41.8367 31.3267 41.8367ZM34.2813 35.0999C34.5056 34.5952 34.2783 34.0043 33.7736 33.78C33.2689 33.5557 32.678 33.783 32.4537 34.2877L30.9226 37.7326L29.8858 36.955C29.444 36.6236 28.8172 36.7132 28.4858 37.155C28.1545 37.5968 28.244 38.2236 28.6858 38.555L30.4254 39.8597C31.0936 40.3608 32.0542 40.1109 32.3934 39.3477L34.2813 35.0999Z" fill="#E65C62" />
            <rect x="38" y="22" width="15" height="2" rx="1" fill="#2970CC" />
            <rect x="38" y="26" width="9" height="2" rx="1" fill="#2970CC" />
            <rect x="38" y="14" width="9" height="2" rx="1" fill="#2970CC" />
            <rect x="38" y="37" width="9" height="2" rx="1" fill="#2970CC" />
            <rect x="38" y="34" width="15" height="2" rx="1" fill="#2970CC" />
            <path fillRule="evenodd" clipRule="evenodd" d="M40 48C46.2428 48 51.9047 45.5128 56.0488 41.4753L57.7461 42.609C53.2167 47.1735 46.9384 50 40 50C26.1929 50 15 38.8071 15 25C15 11.1929 26.1929 0 40 0C53.8071 0 65 11.1929 65 25C65 28.9739 64.0728 32.7312 62.423 36.0674L64.1568 37.0684L58.7185 40.2083V33.9286L60.6866 35.0649C62.1685 32.0249 63 28.6095 63 25C63 12.2975 52.7025 2 40 2C27.2975 2 17 12.2975 17 25C17 37.7025 27.2975 48 40 48Z" fill="#8E9FB3" />
        </svg>
    );
}
export default Reorder;