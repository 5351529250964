import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ProfileUpdateOtp from '../pages/member/profile-otp/index';

const Router = ({ match, permission }) => (
	<Switch>
		<Route exact path={'/otp/:ID'} render={(props) => <ProfileUpdateOtp  {...props} />} />
	</Switch>
);

export default Router;