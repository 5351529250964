import React from 'react';

const Return = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="50" viewBox="0 0 80 50" fill="none">
            <path d="M24.0978 41.1684C23.8194 44.2287 22.7954 47.0287 21.703 49.7258C21.6669 49.8176 21.6297 49.9081 21.5916 49.9999H10.2379C10.0083 49.9163 9.78164 49.8245 9.55593 49.7258C8.27356 49.1782 7.06291 48.3428 5.97102 47.2521C1.794 43.0309 0.186686 35.0546 0.912675 27.9074C1.3641 23.467 2.75348 18.9334 5.45901 16.5419C6.88752 15.2769 8.72245 14.8657 10.3669 15.3358C10.4099 15.3468 10.4529 15.3605 10.4968 15.3728C11.9117 15.8196 13.1721 16.9297 13.8893 18.7182C15.179 21.932 14.411 25.9133 13.7769 29.4904C13.1447 33.0674 12.8018 37.3488 14.6368 39.987C14.0309 37.4859 14.6289 34.5023 16.076 32.8111C17.1693 31.5351 18.6916 31.0568 20.0801 31.4529C20.2129 31.4927 20.3439 31.5379 20.4729 31.5927C20.7846 31.7207 21.0851 31.8973 21.3688 32.119C23.533 33.8143 24.4095 37.7189 24.0978 41.1684Z" fill="#F2F2F2" />
            <path d="M17.3657 49.7258C17.6803 49.8286 17.9959 49.919 18.3135 49.9999H16.5968C16.372 49.9149 16.1483 49.8245 15.9265 49.7258C15.4389 49.512 14.9588 49.2667 14.4863 48.9898C12.5907 47.8742 10.8447 46.1995 9.52367 43.971C8.87761 42.8914 8.35004 41.6825 7.95839 40.3844C7.5337 38.9173 7.2638 37.371 7.15721 35.7946C6.91781 32.5602 7.16208 29.2368 7.61837 26.0599C8.08925 22.7673 8.84936 19.5693 9.8813 16.5391C10.0054 16.1759 10.1337 15.815 10.2663 15.4564C10.2754 15.4268 10.2892 15.4006 10.3066 15.3797C10.3241 15.3588 10.3447 15.3438 10.3669 15.3358C10.412 15.3235 10.4587 15.3368 10.4968 15.3728C10.5426 15.4096 10.5765 15.4696 10.5916 15.5405C10.6067 15.6114 10.6018 15.6878 10.5779 15.7538C9.51397 18.6641 8.70744 21.7459 8.17628 24.9306C7.65649 28.0376 7.36141 31.2884 7.46203 34.483C7.55778 37.468 8.11378 40.4036 9.37516 42.8445C10.5271 45.0729 12.1178 46.8299 13.8776 48.0264C14.9914 48.7738 16.1614 49.3439 17.3657 49.7258V49.7258Z" fill="white" />
            <path d="M21.1333 49.9999H20.6271C20.5704 49.9095 20.5157 49.8176 20.462 49.7258C19.3091 47.7611 18.5281 45.4202 18.1912 42.9199C17.7821 39.7996 18.1004 36.5774 19.0989 33.7307C19.3775 32.9324 19.7058 32.1702 20.0799 31.4529C20.2128 31.4926 20.3438 31.5379 20.4727 31.5927C19.4887 33.4215 18.8268 35.5506 18.541 37.8065C18.2861 39.9204 18.3709 42.0874 18.7891 44.1495C19.2073 46.2115 19.9484 48.1166 20.9584 49.7258C21.015 49.8176 21.0736 49.9095 21.1333 49.9999Z" fill="white" />
            <path d="M66.7278 47.6692C66.8013 48.4769 67.0715 49.2158 67.3598 49.9277C67.3694 49.9519 67.3792 49.9758 67.3892 50H70.3857C70.4463 49.9779 70.5061 49.9537 70.5656 49.9277C70.9041 49.7831 71.2236 49.5626 71.5118 49.2748C72.6141 48.1607 73.0383 46.0556 72.8467 44.1694C72.7276 42.9975 72.3609 41.801 71.6469 41.1698C71.2592 40.8385 70.7965 40.7249 70.3516 40.8515C70.3403 40.8544 70.3289 40.858 70.3173 40.8613C70.1321 40.9115 69.9566 41.0161 69.8023 41.1683C69.648 41.3205 69.5184 41.5167 69.422 41.7442C69.0816 42.5924 69.2843 43.6431 69.4516 44.5871C69.6185 45.5312 69.709 46.6611 69.2247 47.3574C69.2957 47.0662 69.3101 46.7538 69.2665 46.4522C69.2229 46.1506 69.1228 45.8708 68.9765 45.6414C68.8302 45.4121 68.643 45.2415 68.4342 45.1472C68.2253 45.053 68.0023 45.0384 67.7882 45.1051C67.7531 45.1156 67.7185 45.1275 67.6845 45.142C67.6022 45.1758 67.5229 45.2224 67.448 45.2809C66.8768 45.7283 66.6455 46.7588 66.7278 47.6692Z" fill="#F2F2F2" />
            <path d="M68.5045 49.9277C68.4215 49.9548 68.3382 49.9787 68.2544 50H68.7075C68.7668 49.9776 68.8258 49.9537 68.8844 49.9277C69.013 49.8713 69.1397 49.8065 69.2644 49.7334C69.7703 49.4454 70.2195 48.9911 70.5741 48.4089C70.7447 48.124 70.8839 47.8049 70.9873 47.4623C71.0993 47.0751 71.1706 46.667 71.1987 46.251C71.2501 45.3909 71.2091 44.5248 71.077 43.6818C70.9527 42.8128 70.7521 41.9688 70.4798 41.1691C70.447 41.0732 70.4131 40.978 70.3782 40.8833C70.3758 40.8756 70.3721 40.8686 70.3675 40.8631C70.3629 40.8576 70.3575 40.8536 70.3516 40.8515C70.3397 40.8483 70.3274 40.8518 70.3173 40.8613C70.3052 40.871 70.2963 40.8869 70.2923 40.9056C70.2883 40.9243 70.2896 40.9444 70.2959 40.9618C70.5767 41.7299 70.7896 42.5433 70.9297 43.3838C71.0741 44.2082 71.1375 45.0564 71.1182 45.9048C71.1079 46.694 70.9323 47.4614 70.6133 48.1116C70.3031 48.6971 69.8951 49.1667 69.4251 49.4792C69.1311 49.6764 68.8224 49.8269 68.5045 49.9277Z" fill="white" />
            <path d="M67.51 49.9999H67.6436C67.6585 49.976 67.673 49.9518 67.6872 49.9275C67.9914 49.409 68.1976 48.7912 68.2865 48.1313C68.3945 47.3078 68.3104 46.4574 68.0469 45.7061C67.9734 45.4954 67.8867 45.2943 67.788 45.105C67.7529 45.1155 67.7184 45.1274 67.6843 45.1419C67.944 45.6245 68.1187 46.1865 68.1941 46.7818C68.2614 47.3397 68.239 47.9116 68.1287 48.4558C68.0183 49.0001 67.8227 49.5029 67.5562 49.9275C67.5412 49.9518 67.5257 49.976 67.51 49.9999Z" fill="white" />
            <path d="M43 29H31.1265L31.0006 25.1848C30.9952 25.0194 31.0241 24.8547 31.0854 24.7021C31.1467 24.5496 31.2389 24.4127 31.3556 24.3009C31.4724 24.1891 31.6109 24.105 31.7617 24.0546C31.9125 24.0041 32.072 23.9884 32.2292 24.0085L39.1387 24.8927H39.1396C40.1639 24.8956 41.1454 25.3227 41.869 26.0804C42.5926 26.8382 42.9993 27.8648 43 28.9354V29Z" fill="#6C63FF" />
            <path d="M38.1579 15H29.8421C29.7315 15 29.622 15.0239 29.5198 15.0704C29.4176 15.117 29.3248 15.1852 29.2466 15.2712C29.1684 15.3573 29.1064 15.4594 29.064 15.5718C29.0217 15.6842 29 15.8047 29 15.9263V25.0737C29 25.1953 29.0217 25.3158 29.064 25.4282C29.1064 25.5406 29.1684 25.6427 29.2466 25.7288C29.3248 25.8148 29.4176 25.883 29.5198 25.9295C29.622 25.9761 29.7315 26 29.8421 26H38.1579C38.2685 26 38.378 25.9761 38.4802 25.9295C38.5824 25.883 38.6752 25.8148 38.7534 25.7288C38.8316 25.6427 38.8936 25.5406 38.936 25.4282C38.9783 25.3158 39 25.1953 39 25.0737V15.9263C39 15.8047 38.9783 15.6842 38.936 15.5718C38.8936 15.4594 38.8316 15.3573 38.7534 15.2712C38.6752 15.1852 38.5824 15.117 38.4802 15.0704C38.378 15.0239 38.2685 15 38.1579 15V15Z" fill="#CCCCCC" />
            <path d="M36.2785 21.2388L32.3635 21.9841C32.1313 22.028 31.8907 21.9801 31.6945 21.8509C31.4984 21.7216 31.3627 21.5216 31.3172 21.2947L31.0163 19.784C30.9713 19.557 31.0203 19.3218 31.1525 19.1301C31.2847 18.9383 31.4893 18.8057 31.7215 18.7612L35.6365 18.0159C35.8687 17.972 36.1093 18.0199 36.3055 18.1491C36.5016 18.2783 36.6373 18.4784 36.6828 18.7053L36.9837 20.216C37.0287 20.443 36.9797 20.6782 36.8475 20.8699C36.7153 21.0617 36.5107 21.1943 36.2785 21.2388Z" fill="#3F3D56" />
            <path d="M34.8505 19.4153L32.2201 19.9961C32.172 20.0068 32.1222 19.9953 32.0815 19.9642C32.0409 19.9331 32.0128 19.885 32.0034 19.8305C31.994 19.7759 32.0042 19.7194 32.0316 19.6733C32.059 19.6272 32.1014 19.5953 32.1495 19.5847L34.7799 19.0039C34.828 18.9932 34.8778 19.0047 34.9185 19.0358C34.9591 19.0669 34.9872 19.115 34.9966 19.1695C35.006 19.2241 34.9958 19.2806 34.9684 19.3267C34.941 19.3728 34.8986 19.4047 34.8505 19.4153V19.4153Z" fill="white" />
            <path d="M34.8272 20.5771L33.2545 20.9946C33.1989 21.0094 33.1412 20.9934 33.0942 20.9503C33.0472 20.9071 33.0148 20.8402 33.0039 20.7644C32.9931 20.6886 33.0048 20.61 33.0365 20.546C33.0682 20.4819 33.1172 20.4377 33.1728 20.4229L34.7455 20.0054C34.7731 19.9981 34.8014 19.9982 34.8289 20.0058C34.8564 20.0134 34.8825 20.0284 34.9058 20.0497C34.929 20.0711 34.949 20.0985 34.9645 20.1304C34.98 20.1623 34.9907 20.198 34.9961 20.2356C35.0014 20.2731 35.0013 20.3117 34.9957 20.3492C34.9901 20.3867 34.9792 20.4223 34.9635 20.454C34.9478 20.4857 34.9277 20.5129 34.9043 20.534C34.8809 20.5552 34.8547 20.5698 34.8272 20.5771Z" fill="white" />
            <path d="M39 15.8649V17H29V15.8649C29 15.7513 29.0217 15.6388 29.064 15.5339C29.1064 15.4289 29.1684 15.3336 29.2466 15.2532C29.3248 15.1729 29.4176 15.1092 29.5198 15.0658C29.622 15.0223 29.7315 15 29.8421 15H38.1579C38.2685 15 38.378 15.0223 38.4802 15.0658C38.5824 15.1092 38.6752 15.1729 38.7534 15.2532C38.8316 15.3336 38.8937 15.4289 38.936 15.5339C38.9783 15.6388 39 15.7513 39 15.8649Z" fill="#B3B3B3" />
            <path d="M56.9413 17.1589C56.8873 16.9625 56.7957 16.7818 56.673 16.6296C56.5503 16.4774 56.3995 16.3574 56.2311 16.2779C56.0628 16.1985 55.881 16.1616 55.6986 16.1698C55.5162 16.1781 55.3377 16.2313 55.1755 16.3257L44.4715 12L44 15.0119L54.67 18.3168C54.8288 18.6131 55.0786 18.8336 55.3721 18.9363C55.6656 19.039 55.9823 19.0169 56.2623 18.8742C56.5423 18.7315 56.766 18.478 56.8912 18.1619C57.0163 17.8457 57.0342 17.4889 56.9413 17.1589V17.1589Z" fill="#FFB8B8" />
            <path opacity="0.2" d="M56.9413 17.1589C56.8873 16.9625 56.7957 16.7818 56.673 16.6296C56.5503 16.4774 56.3995 16.3574 56.2311 16.2779C56.0628 16.1985 55.881 16.1616 55.6986 16.1698C55.5162 16.1781 55.3377 16.2313 55.1755 16.3257L44.4715 12L44 15.0119L54.67 18.3168C54.8288 18.6131 55.0786 18.8336 55.3721 18.9363C55.6656 19.039 55.9823 19.0169 56.2623 18.8742C56.5423 18.7315 56.766 18.478 56.8912 18.1619C57.0163 17.8457 57.0342 17.4889 56.9413 17.1589V17.1589Z" fill="black" />
            <path d="M46.9581 12.635L45.8121 15.5966C45.7795 15.6808 45.7289 15.7572 45.6638 15.8204C45.5987 15.8835 45.5206 15.9321 45.4348 15.9627C45.349 15.9933 45.2576 16.0053 45.1667 15.9978C45.0758 15.9903 44.9876 15.9636 44.9081 15.9193L42.1231 14.3686C41.6882 14.2011 41.3383 13.8699 41.15 13.4476C40.9616 13.0252 40.9502 12.5462 41.1183 12.1156C41.2864 11.685 41.6202 11.3379 42.0466 11.1504C42.473 10.9628 42.9572 10.9502 43.393 11.1153L46.5029 11.7976C46.592 11.8172 46.6757 11.8555 46.7484 11.91C46.8211 11.9644 46.8811 12.0337 46.9243 12.1132C46.9675 12.1927 46.9929 12.2804 46.9987 12.3705C47.0045 12.4605 46.9907 12.5507 46.9581 12.635V12.635Z" fill="#6C63FF" />
            <path d="M58.2 20L54 22.0301L54.5514 24.2376C55.6163 28.5004 55.8989 32.9415 55.3838 37.318V37.318L58.4545 38L61 31.0977L60.1091 23.2481L58.2 20Z" fill="#6C63FF" />
            <path d="M65.5 31C63.0734 31 61.096 32.776 61 35H70C69.904 32.776 67.9266 31 65.5 31Z" fill="#3F3D56" />
            <path d="M63.9805 31.1448C63.8472 18.9008 57.8553 20.0272 57.8553 20.0272C57.8553 20.0272 57.9674 23.8383 58.0212 23.9569C61.8395 32.3642 55.5379 42.2206 47.5165 40.4074C47.3652 40.3732 47.2199 40.3385 47.0806 40.3033C46.0642 40.0564 45.1668 39.3681 44.571 38.3786C43.9751 37.3891 43.7256 36.1726 43.873 34.9765C44.6632 27.5921 40.7331 27.7228 40.7331 27.7228H34.9864L32.002 24.597C31.8967 24.4867 31.7697 24.4081 31.6322 24.368C31.4947 24.328 31.3509 24.3278 31.2133 24.3674C31.0758 24.407 30.9486 24.4852 30.843 24.5952C30.7374 24.7052 30.6566 24.8436 30.6076 24.9984L30.2237 26.2112C30.2237 26.2112 24.5556 26.8983 25.028 33.357H26.7803C26.7761 33.5405 26.7824 33.7242 26.7992 33.9067L39.8771 33.783C40.6437 33.7757 41.3204 34.4986 41.3235 35.3908C41.3243 35.608 41.2874 35.8231 41.2151 36.0233C41.1428 36.2236 41.0365 36.4048 40.9027 36.5561C40.7688 36.7074 40.6101 36.8258 40.436 36.9041C40.2619 36.9824 40.076 37.019 39.8895 37.0118L37.781 36.93C37.1316 40.2968 38.7257 42.5642 40.4822 43.99C42.136 45.3129 44.0983 46.0143 46.1051 45.9998H51.833C59.2723 45.9998 60.5712 38.7164 60.5712 38.7164C64.468 36.7925 63.9805 31.1448 63.9805 31.1448Z" fill="#3F3D56" />
            <path d="M65.5 50C69.0899 50 72 46.4183 72 42C72 37.5817 69.0899 34 65.5 34C61.9101 34 59 37.5817 59 42C59 46.4183 61.9101 50 65.5 50Z" fill="#3F3D56" />
            <path d="M65.5 44C66.3284 44 67 43.1046 67 42C67 40.8954 66.3284 40 65.5 40C64.6716 40 64 40.8954 64 42C64 43.1046 64.6716 44 65.5 44Z" fill="white" />
            <path d="M32.5 50C36.6421 50 40 45.9705 40 41C40 36.0294 36.6421 32 32.5 32C28.3579 32 25 36.0294 25 41C25 45.9705 28.3579 50 32.5 50Z" fill="#3F3D56" />
            <path d="M32 43C33.1046 43 34 42.1046 34 41C34 39.8954 33.1046 39 32 39C30.8954 39 30 39.8954 30 41C30 42.1046 30.8954 43 32 43Z" fill="white" />
            <path d="M54.5203 23L53.1004 18.4485C53.0264 18.2113 52.9927 17.9592 53.0013 17.7067C53.0099 17.4541 53.0605 17.206 53.1503 16.9765C53.2401 16.747 53.3673 16.5406 53.5247 16.3692C53.6821 16.1977 53.8665 16.0645 54.0675 15.9772L58.1382 14.2086C58.4919 14.0549 58.8677 13.985 59.2443 14.0027C59.6208 14.0204 59.9908 14.1255 60.3329 14.312C60.6751 14.4984 60.9828 14.7626 61.2384 15.0894C61.4941 15.4161 61.6927 15.7992 61.8229 16.2165C61.9403 16.5912 62.0003 16.9875 62 17.3869C61.9985 17.894 61.9012 18.3944 61.7153 18.8517C61.5294 19.309 61.2595 19.7116 60.9252 20.0303C60.6837 20.2589 60.4123 20.4395 60.1215 20.565L54.5203 23Z" fill="#3F3D56" />
            <path d="M62 17.3698C61.9996 17.8746 61.8979 18.373 61.7023 18.8282C61.5067 19.2834 61.2221 19.6838 60.8696 20C60.3287 19.8407 59.8254 19.5518 59.3947 19.1535C58.9639 18.7551 58.6161 18.2567 58.3753 17.6931C58.1344 17.1294 58.0065 16.5139 58.0002 15.8895C57.994 15.265 58.1096 14.6465 58.3391 14.077C58.7797 13.97 59.2362 13.9746 59.675 14.0903C60.1138 14.2061 60.5238 14.4301 60.8749 14.7459C61.226 15.0617 61.5093 15.4613 61.704 15.9154C61.8987 16.3694 61.9998 16.8664 62 17.3698V17.3698Z" fill="#6C63FF" />
            <path d="M26.5 34C27.3284 34 28 32.8807 28 31.5C28 30.1193 27.3284 29 26.5 29C25.6716 29 25 30.1193 25 31.5C25 32.8807 25.6716 34 26.5 34Z" fill="#6C63FF" />
            <path d="M48.0002 40H49.3553L50 36L48 36.0001L48.0002 40Z" fill="#FFB8B8" />
            <path d="M47 39.0002L50.0538 39H50.0539C50.3095 39 50.5625 39.0751 50.7986 39.2211C51.0347 39.3671 51.2493 39.5811 51.43 39.8509C51.6107 40.1207 51.7541 40.441 51.8519 40.7935C51.9497 41.146 52 41.5238 52 41.9053V41.9997L47.0001 42L47 39.0002Z" fill="#2F2E41" />
            <path d="M49.5217 38C49.5052 38 49.4888 37.999 49.4722 37.9972L47.5219 37.8264C47.4431 37.8176 47.3669 37.7872 47.2994 37.7374C47.2319 37.6876 47.1747 37.6199 47.1324 37.5394C47.0901 37.4588 47.0638 37.3677 47.0554 37.273C47.047 37.1783 47.0568 37.0826 47.0841 36.9932L49.6942 29.9428C49.7128 29.88 49.7204 29.8132 49.7165 29.7466C49.7126 29.68 49.6973 29.6151 49.6716 29.556C49.6459 29.4962 49.61 29.4436 49.5662 29.4017C49.5223 29.3598 49.4717 29.3295 49.4174 29.3128C48.1901 28.9271 45.0411 27.9 42.3152 26.6983C41.1473 26.1834 40.4118 25.4394 40.129 24.4868C39.7562 23.231 40.3077 22.0857 40.3313 22.0377L40.3499 22L42.9149 22.2803L45.6956 22.5647L51.7894 26.4935C52.3 26.8254 52.6879 27.3765 52.8758 28.0369C53.0636 28.6972 53.0377 29.4185 52.8031 30.0569L49.9937 37.6314C49.953 37.7411 49.8868 37.8343 49.8031 37.8997C49.7193 37.9651 49.6215 37.9999 49.5217 38Z" fill="#2F2E41" />
            <path d="M43 8.99999C44.6569 8.99999 46 7.43299 46 5.5C46 3.567 44.6569 2 43 2C41.3431 2 40 3.567 40 5.5C40 7.43299 41.3431 8.99999 43 8.99999Z" fill="#FFB8B8" />
            <path d="M47.0002 40H48.3553L49 36L47 36.0001L47.0002 40Z" fill="#FFB8B8" />
            <path d="M47 40.0001L49.443 40H49.4431C49.6476 40 49.85 40.0501 50.0389 40.1474C50.2278 40.2448 50.3994 40.3874 50.544 40.5673C50.6886 40.7471 50.8033 40.9607 50.8815 41.1957C50.9597 41.4307 51 41.6825 51 41.9369V41.9998L47.0001 42L47 40.0001Z" fill="#2F2E41" />
            <path d="M48.5217 39C48.5052 39 48.4888 38.999 48.4722 38.9972L46.5219 38.8264C46.4431 38.8176 46.3669 38.7872 46.2994 38.7374C46.2319 38.6876 46.1747 38.6199 46.1324 38.5394C46.0901 38.4588 46.0638 38.3677 46.0554 38.273C46.047 38.1783 46.0568 38.0826 46.0841 37.9932L48.6942 30.9428C48.7128 30.88 48.7204 30.8132 48.7165 30.7466C48.7126 30.68 48.6973 30.6151 48.6716 30.556C48.6459 30.4962 48.61 30.4436 48.5662 30.4017C48.5223 30.3598 48.4717 30.3295 48.4174 30.3128C47.1901 29.9271 44.0411 28.9 41.3152 27.6983C40.1473 27.1834 39.4118 26.4394 39.129 25.4868C38.7562 24.231 39.3077 23.0857 39.3313 23.0377L39.3499 23L41.9149 23.2803L44.6956 23.5647L50.7894 27.4935C51.3 27.8254 51.6879 28.3765 51.8758 29.0369C52.0636 29.6972 52.0377 30.4185 51.8031 31.0569L48.9937 38.6314C48.953 38.7411 48.8868 38.8343 48.8031 38.8997C48.7193 38.9651 48.6215 38.9999 48.5217 39Z" fill="#2F2E41" />
            <path d="M44.0625 10.2402L40.8125 9C40.8125 9 38.7722 10.728 39.7524 15.0148C40.2641 17.227 40.115 19.5621 39.3274 21.6734C39.2325 21.9347 39.1232 22.1892 39 22.4356C39 22.4356 42.625 27.2587 46 23.6759L44.6875 16.7169C44.6875 16.7169 46.75 12.1005 44.0625 10.2402Z" fill="#6C63FF" />
            <path d="M43.7909 8.96579C43.3646 8.24536 43.0281 7.2464 43.4975 6.56093C43.9609 5.88431 44.8749 5.984 45.5846 5.6846C46.573 5.26758 47.1463 3.9567 46.9675 2.78273C46.7887 1.60875 45.9539 0.635556 44.9593 0.237358C43.9648 -0.160841 42.8433 -0.0313373 41.8693 0.427213C40.6704 0.991694 39.642 2.08364 39.2107 3.46686C38.7794 4.85007 39.01 6.51088 39.9046 7.57405C40.8638 8.71388 42.3692 9.01764 43.7618 8.99921" fill="#2F2E41" />
            <path d="M40.6346 2.81194C40.1447 3.29739 39.3972 3.08177 38.8713 2.65573C38.3455 2.22968 37.9187 1.62302 37.3498 1.28899C36.3466 0.700097 35.1338 1.09157 34.1576 1.74387C33.1813 2.39618 32.3159 3.29734 31.2846 3.80923C30.2534 4.32112 28.9375 4.34643 28.1566 3.37986C27.9644 4.05755 27.9487 4.79089 28.1116 5.47985C28.2745 6.1688 28.6081 6.7798 29.0668 7.22948C29.5255 7.67917 30.087 7.94564 30.6747 7.99253C31.2624 8.03943 31.8477 7.86446 32.3507 7.49151C33.4806 6.65374 34.1047 4.89716 35.3721 4.43475C36.0734 4.17888 36.8264 4.38496 37.5352 4.60818C38.244 4.8314 38.983 5.07259 39.6983 4.88256C40.4136 4.69253 41.0827 3.91012 40.9917 3.02587L40.6346 2.81194Z" fill="#2F2E41" />
            <path d="M56.8871 17.1411C56.8131 16.9673 56.704 16.8128 56.5676 16.6885C56.4311 16.5642 56.2707 16.4732 56.0975 16.4218C55.9242 16.3703 55.7425 16.3598 55.565 16.3909C55.3876 16.422 55.2187 16.494 55.0703 16.6018L44.1359 14L44 16.8331L54.7909 18.4997C54.9782 18.752 55.2463 18.9226 55.5444 18.9794C55.8424 19.0361 56.1498 18.9749 56.4082 18.8075C56.6666 18.64 56.858 18.3779 56.9463 18.0707C57.0346 17.7636 57.0135 17.4328 56.8871 17.1411V17.1411Z" fill="#FFB8B8" />
            <path d="M45.9844 14.2008L45.3373 17.4899C45.3189 17.5835 45.2846 17.671 45.2368 17.7465C45.1889 17.8219 45.1287 17.8836 45.0602 17.9273C44.9916 17.9709 44.9164 17.9955 44.8396 17.9994C44.7628 18.0034 44.6862 17.9865 44.6151 17.9499L42.1226 16.6691C41.741 16.5486 41.4132 16.2431 41.2111 15.8198C41.0089 15.3965 40.9489 14.89 41.0443 14.4113C41.1397 13.9327 41.3826 13.521 41.7197 13.2667C42.0569 13.0123 42.4608 12.936 42.8427 13.0546L45.5157 13.3724C45.5922 13.3816 45.6663 13.4113 45.7329 13.4595C45.7994 13.5078 45.8569 13.5734 45.9014 13.6521C45.9458 13.7307 45.9763 13.8204 45.9906 13.9151C46.005 14.0098 46.0028 14.1072 45.9844 14.2008V14.2008Z" fill="#6C63FF" />
            <path d="M56 20C56.5523 20 57 19.5523 57 19C57 18.4477 56.5523 18 56 18C55.4477 18 55 18.4477 55 19C55 19.5523 55.4477 20 56 20Z" fill="#6C63FF" />
            <path d="M80 49.8629C79.9999 49.8992 79.9897 49.934 79.9717 49.9597C79.9536 49.9853 79.9291 49.9998 79.9035 49.9999H0.0965018C0.0709079 49.9999 0.0463623 49.9855 0.0282647 49.9598C0.0101671 49.9341 0 49.8992 0 49.8629C0 49.8265 0.0101671 49.7917 0.0282647 49.766C0.0463623 49.7403 0.0709079 49.7258 0.0965018 49.7258H79.9035C79.9291 49.7259 79.9536 49.7404 79.9717 49.7661C79.9897 49.7918 79.9999 49.8266 80 49.8629V49.8629Z" fill="#3F3D56" />
        </svg>
    );
}
export default Return;