/* ACTION TYPE FOR ENROLLMENT */
export const ENROLLMENT_SELECT_MEMBERSHIP = "ENROLLMENT_SELECT_MEMBERSHIP";
export const ENROLLMENT_SELECT_TIER = "ENROLLMENT_SELECT_TIER";
export const ENROLLMENT_SELECT_ENROLL_DATE = "ENROLLMENT_SELECT_ENROLL_DATE";
export const ENROLLMENT_SELECT_ENROLL_CHANNEL = "ENROLLMENT_SELECT_ENROLL_CHANNEL";
export const ENROLLMENT_SELECT_IS_ENROLL_COBRAND = "ENROLLMENT_SAVE_IS_ENROLL_COBRAND";
export const ENROLLMENT_SELECT_PARTNER = "ENROLLMENT_SELECT_PARTNER";
export const ENROLLMENT_SELECT_COBRAND = "ENROLLMENT_SELECT_COBRAND";
export const ENROLLMENT_SELECT_SALUTATION = "ENROLLMENT_SELECT_SALUTATION";
export const ENROLLMENT_SELECT_TITLE = "ENROLLMENT_SELECT_TITLE";
export const ENROLLMENT_SELECT_GENDER = "ENROLLMENT_SELECT_GENDER";
export const ENROLLMENT_SELECT_DATEOFBIRTH = "ENROLLMENT_SELECT_DATEOFBIRTH";
export const ENROLLMENT_SELECT_MINDATEOFBIRTH = "ENROLLMENT_SELECT_MINDATEOFBIRTH";
export const ENROLLMENT_SELECT_MAXDATEOFBIRTH = "ENROLLMENT_SELECT_MAXDATEOFBIRTH";
export const ENROLLMENT_SELECT_NATIONALITY = "ENROLLMENT_SELECT_NATIONALITY";
export const ENROLLMENT_SELECT_RELIGION = "ENROLLMENT_SELECT_RELIGION";
export const ENROLLMENT_SELECT_LANGUAGE = "ENROLLMENT_SELECT_LANGUAGE";
export const ENROLLMENT_SELECT_MOBILE_PHONE_COUNTRY_CODE = "ENROLLMENT_SELECT_MOBILE_PHONE_COUNTRY_CODE";
export const ENROLLMENT_SELECT_PREFERRED_ADDRESS = "ENROLLMENT_SELECT_PREFERRED_ADDRESS";
export const ENROLLMENT_SELECT_COUNTRY_PRIVATE = "ENROLLMENT_SELECT_COUNTRY_PRIVATE";
export const ENROLLMENT_SELECT_PROVINCE_PRIVATE = "ENROLLMENT_SELECT_PROVINCE_PRIVATE";
export const ENROLLMENT_SELECT_CITY_PRIVATE = "ENROLLMENT_SELECT_CITY_PRIVATE";
export const ENROLLMENT_SELECT_COUNTRY_BUSINESS = "ENROLLMENT_SELECT_COUNTRY_BUSINESS";
export const ENROLLMENT_SELECT_PROVINCE_BUSINESS = "ENROLLMENT_SELECT_PROVINCE_BUSINESS";
export const ENROLLMENT_SELECT_CITY_BUSINESS = "ENROLLMENT_SELECT_CITY_BUSINESS";
export const ENROLLMENT_SELECT_PHONE_COUNTRY_CODE_PRIVATE = "ENROLLMENT_SELECT_PHONE_COUNTRY_CODE_PRIVATE";
export const ENROLLMENT_SELECT_PHONE_COUNTRY_CODE_BUSINES = "ENROLLMENT_SELECT_PHONE_COUNTRY_CODE_BUSINES";
export const ENROLLMENT_SELECT_FAX_COUNTRY_CODE_BUSINESS = "ENROLLMENT_SELECT_FAX_COUNTRY_CODE_BUSINESS";
export const ENROLLMENT_SELECT_FAX_COUNTRY_CODE_PRIVATE = "ENROLLMENT_SELECT_FAX_COUNTRY_CODE_PRIVATE";
export const ENROLLMENT_SELECT_HOBBIES = "ENROLLMENT_SELECT_HOBBIES";
export const ENROLLMENT_SELECT_NAMEONCARD = "ENROLLMENT_SELECT_NAMEONCARD";
export const ENROLLMENT_SAVE_FIRSTNAME = "ENROLLMENT_SAVE_FIRSTNAME";
export const ENROLLMENT_SAVE_LASTNAME = "ENROLLMENT_SAVE_LASTNAME";
export const ENROLLMENT_SAVE_PASSPORT_NUMBER = "ENROLLMENT_SAVE_PASSPORT_NUMBER";
export const ENROLLMENT_SAVE_IDCARD_NUMBER = "ENROLLMENT_SAVE_IDCARD_NUMBER";
export const ENROLLMENT_SAVE_EMAIL = "ENROLLMENT_SAVE_EMAIL";
export const ENROLLMENT_SAVE_MOBILE_PHONE_NUMBER = "ENROLLMENT_SAVE_MOBILE_PHONE_NUMBER";
export const ENROLLMENT_SAVE_PRIVATE_ADDRESS = "ENROLLMENT_SAVE_PRIVATE_ADDRESS";
export const ENROLLMENT_SAVE_BUSINESS_ADDRESS = "ENROLLMENT_SAVE_BUSINESS_ADDRESS";
export const ENROLLMENT_SAVE_POSTAL_CODE_ADDRESS_PRIVATE = "ENROLLMENT_SAVE_POSTAL_CODE_ADDRESS_PRIVATE";
export const ENROLLMENT_SAVE_POSTAL_CODE_ADDRESS_BUSINESS = "ENROLLMENT_SAVE_POSTAL_CODE_ADDRESS_BUSINESS";
export const ENROLLMENT_SAVE_REGION_PHONE_PRIVATE = "ENROLLMENT_SAVE_REGION_PHONE_PRIVATE";
export const ENROLLMENT_SAVE_REGION_PHONE_BUSINESS = "ENROLLMENT_SAVE_REGION_PHONE_BUSINESS";
export const ENROLLMENT_SAVE_REGION_FAX_PRIVATE = "ENROLLMENT_SAVE_REGION_FAX_PRIVATE";
export const ENROLLMENT_SAVE_REGION_FAX_BUSINESS = "ENROLLMENT_SAVE_REGION_FAX_BUSINESS";
export const ENROLLMENT_SAVE_NUMBER_PHONE_PRIVATE = "ENROLLMENT_SAVE_NUMBER_PHONE_PRIVATE";
export const ENROLLMENT_SAVE_NUMBER_PHONE_BUSINESS = "ENROLLMENT_SAVE_NUMBER_PHONE_BUSINESS";
export const ENROLLMENT_SAVE_NUMBER_FAX_PRIVATE = "ENROLLMENT_SAVE_NUMBER_FAX_PRIVATE";
export const ENROLLMENT_SAVE_NUMBER_FAX_BUSINESS = "ENROLLMENT_SAVE_NUMBER_FAX_BUSINESS";
export const ENROLLMENT_SAVE_COMPANY_NAME = "ENROLLMENT_SAVE_COMPANY_NAME";
export const ENROLLMENT_SAVE_DEPARTMENT = "ENROLLMENT_SAVE_DEPARTMENT";
export const ENROLLMENT_SAVE_NAMEONCARD = "ENROLLMENT_SAVE_NAMEONCARD";
export const ENROLLMENT_SAVE_CUSTOMNAMEONCARD = "ENROLLMENT_SAVE_CUSTOMNAMEONCARD";
export const ENROLLMENT_SAVE_USERNAME = "ENROLLMENT_SAVE_USERNAME";
export const ENROLLMENT_SAVE_CARDNUMBER = "ENROLLMENT_SAVE_CARDNUMBER";
export const SET_ENROLLMENT_RESPONSE = "SET_ENROLLMENT_RESPONSE";
export const ENROLLMENT_RESET_STORE = "ENROLLMENT_RESET_STORE";

export const ACCESS_MIDDLEWARE = "ACCESS_MIDDLEWARE";
export const API_START = "API_START";
export const API_END = "API_END";
export const ACCESS_DENIED = "ACCESS_DENIED";
export const API_ERROR = "API_ERROR";

export const GET_MEMBER_SUMMARY = "GET_MEMBER_SUMMARY";
export const SET_MEMBER_SUMMARY = "SET_MEMBER_SUMMARY";

export const REDEMPTION_RESET_STORE = "REDEMPTION_RESET_STORE";

/* ACTION TYPE FOR REDEMPTION NON AIR */
export const REDEMPTIONNONAIR_SET_ELIGIBLE_REDEEM_STATUS = "REDEMPTIONNONAIR_SET_ELIGIBLE_REDEEM_STATUS";
export const REDEMPTIONNONAIR_SET_MEMBER_PROFILE = "REDEMPTIONNONAIR_SET_MEMBER_PROFILE";
export const REDEMPTIONNONAIR_SET_USER = "REDEMPTIONNONAIR_SET_USER";
export const REDEMPTIONNONAIR_SET_AWARD = "REDEMPTIONNONAIR_SET_AWARD";

/* ACTION TYPE FOR REDEMPTION AIR */
export const REDEMPTION_SET_ORIGIN = "REDEMPTION_SET_ORIGIN";
export const REDEMPTION_SET_DESTINATION = "REDEMPTION_SET_DESTINATION";
export const REDEMPTION_SET_DEPARTUREDATE = "REDEMPTION_SET_DEPARTUREDATE";
export const REDEMPTION_SET_RETURNDATE = "REDEMPTION_SET_RETURNDATE";
export const REDEMPTION_SET_ISRETURN = "REDEMPTION_SET_ISRETURN";
export const REDEMPTION_SET_COMPARTMENT = "REDEMPTION_SET_COMPARTMENT";
export const REDEMPTION_SET_PASSENGER = "REDEMPTION_SET_PASSENGER";
export const REDEMPTION_SET_PRICELIST = "REDEMPTION_SET_PRICELIST";
export const REDEMPTION_SET_FLIGHTSCHEDULE = "REDEMPTION_SET_FLIGHTSCHEDULE";
export const REDEMPTION_SET_SUMMARY = "REDEMPTION_SET_SUMMARY";
export const REDEMPTION_SET_REDEEMUSER = "REDEMPTION_SET_REDEEMUSER";
export const REDEMPTION_SET_RESPONSE_BUY_AWARD = "REDEMPTION_SET_RESPONSE_BUY_AWARD";

export const REDEMPTION_SET_GENERAL_REQUEST = "REDEMPTION_SET_GENERAL_REQUEST";

export const REDEMPTION_SET_UPGRADE = "REDEMPTION_SET_UPGRADE";

/* ACTION UPDATE CERTIFICATE */
export const UPDATE_CERTIFICATE_RESET_STORE = "UPDATE_CERTIFICATE_RESET_STORE";
export const UPDATE_CERTIFICATE_JUMP_STEP_TO = "UPDATE_CERTIFICATE_JUMP_STEP_TO";
export const UPDATE_CERTIFICATE_SET_DETAIL_CERTIFICATE = "UPDATE_CERTIFICATE_SET_DETAIL_CERTIFICATE";
export const UPDATE_CERTIFICATE_SELECTED_AIR_ACTIVITY = "UPDATE_CERTIFICATE_SELECTED_AIR_ACTIVITY";
export const UPDATE_CERTIFICATE_SAVE_ACTIVITY_AIR_TYPE = "UPDATE_CERTIFICATE_SAVE_ACTIVITY_AIR_TYPE";
export const UPDATE_CERTIFICATE_SET_PRICE_LIST = "UPDATE_CERTIFICATE_SET_PRICE_LIST";
export const UPDATE_CERTIFICATE_SET_UPDATE_SUMMARY = "UPDATE_CERTIFICATE_SET_UPDATE_SUMMARY";
export const UPDATE_CERTIFICATE_SET_AIR_ACTIVITY = "UPDATE_CERTIFICATE_SET_AIR_ACTIVITY";
export const UPDATE_CERTIFICATE_SET_RESPONSE_UPDATE_AWARD = "UPDATE_CERTIFICATE_SET_RESPONSE_UPDATE_AWARD";

/* ROLE PERMISSION */
export const ROLE_PERMISSION_SET_PERMISSION = "ROLE_PERMISSION_SET_PERMISSION";
export const ROLE_PERMISSION_GET_PERMISSION = "ROLE_PERMISSION_GET_PERMISSION";

/* PERMISSION MIDLEWARE */
export const MIDDLEWARE_PERMISSION_ACCESS = "MIDDLEWARE_PERMISSION_ACCESS";
export const MIDDLEWARE_PERMISSION_START_API = "MIDDLEWARE_PERMISSION_START_API";
export const MIDDLEWARE_PERMISSION_END_API = "MIDDLEWARE_PERMISSION_END_API";
export const MIDDLEWARE_PERMISSION_ACCESS_DENIED = "MIDDLEWARE_PERMISSION_ACCESS_DENIED";
export const MIDDLEWARE_PERMISSION_ERROR_API = "MIDDLEWARE_PERMISSION_ERROR_API";
export const ROLE_PERMISSION_CAN = "ROLE_PERMISSION_CAN";

/* ENROLLMENT CORPORATE */
export const ENROLLMENT_CORP_RESET_STORE = "ENROLLMENT_CORP_RESET_STORE";
export const ENROLLMENT_CORP_SAVE_MEMBER = "ENROLLMENT_CORP_SAVE_MEMBER";
export const ENROLLMENT_CORP_SAVE_MEMBERCORPORATEDETAIL = "ENROLLMENT_CORP_SAVE_MEMBERCORPORATEDETAIL";
export const ENROLLMENT_CORP_SAVE_CORPORATETOURCODE = "ENROLLMENT_CORP_SAVE_CORPORATETOURCODE";
export const ENROLLMENT_CORP_ADD_STAFF_TRAVELCORDINATOR = "ENROLLMENT_CORP_ADD_STAFF_TRAVELCORDINATOR";
export const ENROLLMENT_CORP_SAVE_TRAVELCORDINATOR = "ENROLLMENT_CORP_SAVE_TRAVELCORDINATOR";
export const ENROLLMENT_CORP_SET_RESPONSE = "ENROLLMENT_CORP_SET_RESPONSE";