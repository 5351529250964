export default [
    { "code": 501, "message": "Not Implemented" },
    { "code": 502, "message": "Bad Gateway" },
    { "code": 503, "message": "Service Unavailable" },
    { "code": 504, "message": "Gateway Timeout" },
    { "code": 505, "message": "HTTP Version Not Supported" },
    { "code": 506, "message": "Variant Also Negotiates" },
    { "code": 507, "message": "Variant Also Negotiates" },
    { "code": 511, "message": "Network Authentication Required" },
    { "code": 400, "message": "Bad Request" },
    { "code": 401, "message": "Unauthorized" },
    { "code": 402, "message": "Payment Required" },
    { "code": 403, "message": "Forbidden" },
    { "code": 404, "message": "Not Found" },
    { "code": 405, "message": "Method Not Allowed" },
    { "code": 406, "message": "Not Acceptable" },
    { "code": 407, "message": "Proxy Authentication Required" },
    { "code": 408, "message": "Request Timeout" }
]