import React from 'react';

const widthResolution = window.innerWidth;
const width = (widthResolution > 1200) ? 710 : (widthResolution > 1000) ? 510 : (widthResolution > 500) ? 310 : (widthResolution > 300) ? 150 : 90;
const height = (widthResolution > 1200) ? 410 : (widthResolution > 1000) ? 310 : (widthResolution > 500) ? 180 : (widthResolution > 300) ? 100 : 70;

const EmailChecklist = () => {
    return (
        <svg width={width} height={height} viewBox="0 0 1004 647" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.3" d="M845.145 431.769C845.145 431.769 821.146 393.514 869.144 314.497C917.142 235.479 918.039 -19.226 692.602 52.63C467.165 124.486 357.472 166.408 212.177 79.013C-71.3093 -91.504 128.181 431.769 128.181 431.769H845.145Z" fill="#CCE9FF" />
            <path d="M743.426 165.421C742.901 167.382 740.96 168.662 738.915 168.32C736.743 167.959 735.274 165.904 735.636 163.73C739.621 139.833 734.243 115.425 720.495 95.0019C719.261 93.1701 719.753 90.6921 721.576 89.4662C723.404 88.2332 725.884 88.7166 727.114 90.5464C742.009 112.667 747.832 139.124 743.508 165.04C743.488 165.172 743.459 165.297 743.426 165.421Z" fill="#CCE9FF" />
            <path d="M713.199 285.216L574.189 367.192L435.179 285.216C435.179 285.148 435.179 285.058 435.202 284.99C435.315 284.469 442.988 278.064 454.689 268.559C454.802 268.469 454.892 268.378 455.006 268.287C493.391 237.122 574.189 173.298 574.189 173.298C574.189 173.298 654.988 237.122 693.373 268.287C705.255 277.929 713.063 284.447 713.177 284.99C713.199 285.057 713.199 285.148 713.199 285.216Z" fill="#00AEF0" />
            <path opacity="0.4" d="M713.199 285.216L574.189 367.192L435.179 285.216C435.179 285.148 435.179 285.058 435.202 284.99C435.315 284.469 442.988 278.064 454.689 268.559C454.802 268.469 454.892 268.378 455.006 268.287C493.391 237.122 574.189 173.298 574.189 173.298C574.189 173.298 654.988 237.122 693.373 268.287C705.255 277.929 713.063 284.447 713.177 284.99C713.199 285.057 713.199 285.148 713.199 285.216Z" fill="#0B4870" />
            <path d="M631.357 105.408H481.374C468.51 105.408 458.081 115.837 458.081 128.701V355.473C458.081 368.338 468.51 378.766 481.374 378.766H669.308C682.173 378.766 692.601 368.337 692.601 355.473V166.653L631.357 105.408Z" fill="#CCE9FF" />
            <path d="M631.357 143.359C631.357 156.224 641.786 166.652 654.65 166.652H692.601L631.357 105.408V143.359Z" fill="#CCE9FF" />
            <path d="M553.6 143.74H501.856C497.273 143.74 493.558 147.455 493.558 152.038V203.782C493.558 208.365 497.273 212.08 501.856 212.08H553.6C558.183 212.08 561.898 208.365 561.898 203.782V152.038C561.898 147.455 558.183 143.74 553.6 143.74Z" fill="#00AEF0" />
            <path d="M624.484 231.071H500.325C496.633 231.071 493.64 234.064 493.64 237.756C493.64 241.448 496.633 244.442 500.325 244.442H624.484C628.176 244.442 631.169 241.449 631.169 237.756C631.17 234.064 628.177 231.071 624.484 231.071Z" fill="#00AEF0" />
            <path d="M624.484 264.375H500.325C496.633 264.375 493.64 267.368 493.64 271.06C493.64 274.753 496.633 277.746 500.325 277.746H624.484C628.176 277.746 631.169 274.753 631.169 271.06C631.17 267.368 628.177 264.375 624.484 264.375Z" fill="#00AEF0" />
            <path d="M568.097 296.683H500.325C496.633 296.683 493.64 299.676 493.64 303.368C493.64 307.06 496.633 310.054 500.325 310.054H568.097C571.789 310.054 574.783 307.061 574.783 303.368C574.783 299.676 571.789 296.683 568.097 296.683Z" fill="#00AEF0" />
            <path d="M713.212 285.21V418.573C713.212 427.894 705.593 435.512 696.273 435.512H452.088C442.767 435.512 435.194 427.894 435.194 418.573V285.21L574.203 367.173L713.212 285.21Z" fill="#00AEF0" />
            <path opacity="0.2" d="M708.514 430.204L619.043 340.733L574.203 367.171L529.344 340.721L439.867 430.198C442.947 433.451 447.27 435.51 452.088 435.51H696.273C701.089 435.512 705.423 433.455 708.514 430.204Z" fill="#0B4870" />
            <path d="M410.072 122.947L368.233 141.18C366.1 142.107 363.62 141.126 362.698 138.991L362.417 138.341C361.491 136.196 362.491 133.708 364.644 132.8L406.764 115.218C408.972 114.288 411.51 115.388 412.34 117.635C413.113 119.727 412.118 122.058 410.072 122.947Z" fill="#CCE9FF" />
            <path d="M943.934 458.187C943.934 472.778 932.106 484.605 917.516 484.605H109.266C94.6757 484.605 82.8477 472.777 82.8477 458.187C82.8477 443.597 94.6757 431.769 109.266 431.769H917.515C932.106 431.769 943.934 443.597 943.934 458.187Z" fill="#CCE9FF" />
            <path d="M397.041 396.974C396.068 396.119 395.007 395.235 393.828 394.291C381.947 384.769 310.517 345.059 290.382 335.802C289.005 335.17 287.217 335.281 285.337 335.966C283.763 336.257 282.07 337.026 280.24 338.452C264.417 350.779 176.518 409.23 178.286 412.451C182.88 420.822 232.072 401.146 262.231 384.445C271.464 379.332 278.79 375.449 286.816 371.168C291.752 374.754 302.098 383.465 313.907 392.963C331.566 407.172 352.29 423.062 362.402 425.863C375.521 429.489 385.072 429.312 392.177 424.389C406.386 414.515 411.457 409.533 397.041 396.974Z" fill="#0B4870" />
            <path d="M392.176 424.391C385.071 429.314 375.52 429.491 362.401 425.865C352.289 423.064 331.565 407.174 313.906 392.965C345.096 393.26 381.121 396.797 394.122 396.945C395.095 396.974 396.068 396.974 397.041 396.974C411.456 409.533 406.385 414.515 392.176 424.391Z" fill="#0B4870" />
            <path d="M361.209 339.625C357.427 336.47 348.266 327.347 343.531 322.587C340.787 319.828 340.193 315.385 342.569 312.303C342.791 312.015 343.037 311.735 343.31 311.467C345.307 309.502 346.575 311.535 348.652 312.919C358.286 319.338 377.868 328.148 387.474 330.881C397.548 333.747 400.899 340.91 393.718 351.24C389.659 357.078 372.185 348.783 361.209 339.625Z" fill="#CCE9FF" />
            <path d="M449.426 336.088C449.426 336.235 449.397 336.383 449.397 336.53C448.925 343.163 448.306 349.678 447.658 355.869C445.27 378.805 442.292 396.935 442.292 396.935H383.096C371.746 396.935 363.079 386.853 364.818 375.65C366.41 365.302 368.149 351.859 369.299 336.883C369.417 335.261 369.535 333.64 369.653 331.989C370.39 321.17 370.744 309.702 370.478 298.087V297.969C370.33 291.189 369.977 284.349 369.358 277.569C369.682 273.383 362.784 254.663 386.103 253.72L419.887 252.305C431.974 251.774 441.555 260.294 446.095 271.113C446.537 272.145 446.921 273.265 447.304 274.474C451.49 288.271 451.195 312.68 449.426 336.088Z" fill="#CCE9FF" />
            <path opacity="0.1" d="M449.427 336.088C449.427 336.235 449.397 336.383 449.397 336.53L439.227 331.931L433.979 270.347L447.305 274.474C451.491 288.271 451.196 312.68 449.427 336.088Z" fill="#0B4870" />
            <path opacity="0.1" d="M449.436 336.081C448.954 342.857 448.322 349.543 447.659 355.868C445.069 354.663 442.569 353.338 440.311 351.923C437.721 350.327 432.842 346.803 428.234 343.4L418.235 335.961C418.566 336.985 418.386 337.798 417.783 338.22V338.25C417.421 338.521 416.909 338.612 416.337 338.581L371.794 333.852C370.74 333.671 370.047 332.979 369.656 331.985C370.379 321.173 370.74 309.698 370.469 298.073V297.983L410.826 316.746C411.248 316.836 411.669 316.927 412.091 317.047C415.615 318.011 418.235 322.137 420.373 325.54C421.487 325.57 422.632 326.564 424.108 327.257C430.373 330.268 440.341 333.611 449.436 336.081Z" fill="#0B4870" />
            <path d="M336.686 293.296C336.718 293.587 336.789 293.886 336.898 294.194L350.806 327.813C351.465 329.378 353.285 330.665 354.862 330.692L413.615 332.426C414.193 332.411 414.696 332.273 415.048 331.996L415.049 331.961C415.598 331.478 415.731 330.656 415.328 329.68L401.421 296.025C400.799 294.504 398.979 293.217 397.403 293.19L338.65 291.456C337.379 291.425 336.632 292.183 336.686 293.296Z" fill="#0B4870" />
            <path d="M184.615 408.467C185.629 408.918 186.929 411.477 186.846 412.584C186.57 416.266 183.661 418.991 181.958 420.652C180.146 422.42 161.998 416.33 152.849 411.094C147.907 408.266 165.211 410.46 169.318 410.359C172.455 410.281 178.386 409.425 180.203 408.667C182.117 407.869 183.008 407.752 183.866 408.133L184.615 408.467Z" fill="#0B4870" />
            <path d="M435.016 431.117C435.016 440.069 369.519 447.326 288.725 447.326C207.93 447.326 142.434 440.069 142.434 431.117C142.434 422.165 207.931 414.909 288.725 414.909C369.519 414.908 435.016 422.165 435.016 431.117Z" fill="#CCE9FF" />
            <path d="M190.496 432.08C191.222 432.92 191.309 435.789 190.763 436.755C188.946 439.97 185.154 441.197 182.906 441.976C180.514 442.804 166.683 429.571 160.632 420.938C157.364 416.276 172.088 425.625 175.848 427.282C178.72 428.547 184.452 430.296 186.418 430.383C188.489 430.475 189.346 430.748 189.96 431.459L190.496 432.08Z" fill="#0B4870" />
            <path d="M440.044 408.198C440.063 408.129 440.084 408.059 440.102 407.99C441.299 403.799 442.182 398.782 442.753 392.728C414.112 393.453 370.767 389.783 364.743 376.097C362.294 380.833 360.185 387.936 359.67 395.176C335.2 396.237 304.241 398.371 284.008 401.62C264.229 404.955 182.981 429.001 184.239 432.454C187.423 441.191 206.452 437.996 240.952 435.895C254.959 435.043 286.455 430.538 301.559 426.334C321.494 426.432 403.216 433.994 411.68 432.319C432.309 428.237 434.465 425.029 439.466 410.092C439.523 409.923 439.562 409.771 439.615 409.605C439.762 409.148 439.905 408.678 440.044 408.198Z" fill="#0B4870" />
            <path d="M407.662 308.232C412.927 309.29 416.788 317.446 419.605 319.838C423.014 322.733 419.315 325.393 414.525 326.238C410.235 326.995 404.412 326.412 401.801 324.346C399.593 322.599 396.829 320.547 394.404 313.699C392.423 308.11 400.789 306.851 407.662 308.232Z" fill="#FFE3CA" />
            <path d="M345.284 304.645C350.549 305.703 354.411 313.859 357.227 316.251C360.636 319.146 356.937 321.806 352.148 322.651C347.858 323.408 348.812 325.866 346.201 323.8C343.993 322.053 341.229 320.001 338.803 313.154C336.823 307.564 338.411 303.264 345.284 304.645Z" fill="#FFE3CA" />
            <path d="M474.338 336.853C474.326 336.579 474.319 336.302 474.29 336.039C473.795 329.261 470.745 320.619 468.371 313.652C464.69 302.848 459.148 285.293 448.075 264.758C442.797 254.971 435.193 251.786 422.351 252.327C417.302 252.54 429.74 275.559 432.475 281.892C435.401 288.668 445.771 300.294 453.405 319.22C454.078 320.889 458.656 330.495 459.405 332.426C449.336 330.597 427.638 320.439 420.348 317.566C418.026 316.651 416.624 315.13 414.82 317.273C411.062 321.737 413.573 326.38 413.573 326.38C413.573 326.38 432.245 337.876 438.252 341.026C450.912 347.664 469.742 352.09 472.478 345.527C472.786 344.788 473.054 344.072 473.285 343.376C474.161 341.636 474.446 339.392 474.338 336.853Z" fill="#CCE9FF" />
            <path d="M391.636 253.475C392.838 258.489 397.176 261.772 403.224 261.518C409.272 261.263 417.862 257.435 421.775 252.206L391.636 253.475Z" fill="#FFE3CA" />
            <path d="M378.019 191.429C366.706 201.094 375.735 233.675 380.616 233.177L390.995 225.894C398.964 224.636 396.601 175.555 378.019 191.429Z" fill="black" />
            <path d="M395.822 255.262C398.176 243.674 392.596 229.83 390.418 219.364C389.066 212.872 392.847 206.308 399.593 204.863C406.948 203.288 414.367 207.942 415.88 214.901C416.632 218.362 419.805 231.192 414.572 255.262" fill="#FFE3CA" />
            <path d="M375.732 217.105C376.306 235.968 392.822 251.305 401.426 251.305C409.239 251.305 428.278 235.902 427.704 217.039C427.13 198.176 414.434 182.935 399.347 182.996C384.26 183.058 375.157 198.242 375.732 217.105Z" fill="#FFE3CA" />
            <path d="M426.874 215.648C433.524 219.528 435.905 176.824 419.523 184.132C406.071 190.134 418.905 216.907 426.874 215.648Z" fill="black" />
            <path d="M408.92 202.692C386.224 218.353 374.025 204.613 366.591 196.1C366.591 196.1 370.102 196.026 373.679 193.457C373.679 193.457 370.126 191.411 365.989 189.086C365.989 189.086 370.17 188.126 374.461 185.777C374.461 185.777 376.488 185.877 371.233 183.081C371.233 183.081 382.058 177.567 396.363 174.14C406.08 171.812 430.533 170.779 426.437 185.674C426.11 186.864 425.667 187.992 425.148 189.052C422.028 195.424 417.016 197.106 408.92 202.692Z" fill="black" />
            <path d="M422.499 214.129C421.063 219.824 422.394 225.097 425.473 225.907C428.551 226.717 432.211 222.757 433.647 217.063C435.083 211.368 433.752 206.096 430.674 205.285C427.595 204.475 423.935 208.434 422.499 214.129Z" fill="#FFE3CA" />
            <path d="M416.779 242.233C416.779 242.233 412.802 249.785 407.624 251.305C402.446 252.825 400.128 253.72 396.381 249.991C396.381 249.991 409.993 251.355 416.779 242.233Z" fill="#FCD2B1" />
            <path d="M658.698 184.604C682.824 184.604 702.382 165.046 702.382 140.92C702.382 116.794 682.824 97.236 658.698 97.236C634.572 97.236 615.014 116.794 615.014 140.92C615.014 165.046 634.572 184.604 658.698 184.604Z" fill="#42E100" />
            <path d="M656.828 165.134C655.102 167.375 651.863 167.74 649.682 165.938L633.541 152.604C631.625 151.021 631.355 148.185 632.938 146.269V146.269C634.521 144.353 637.357 144.083 639.273 145.666L652.222 156.363L678.333 122.453C679.85 120.484 682.676 120.117 684.645 121.633V121.633C686.614 123.15 686.981 125.975 685.465 127.944L656.828 165.134Z" fill="#CCE9FF" />
            <path d="M288.724 527.59C288.724 529.868 285.031 531.715 280.474 531.715H177.657C173.101 531.715 169.407 529.868 169.407 527.59C169.407 525.312 173.1 523.465 177.657 523.465H280.474C285.03 523.465 288.724 525.311 288.724 527.59Z" fill="#CCE9FF" />
            <path d="M424.254 591.089C424.254 593.367 420.561 595.214 416.004 595.214H313.188C308.631 595.214 304.938 593.367 304.938 591.089C304.938 588.811 308.63 586.964 313.188 586.964H416.005C420.561 586.964 424.254 588.811 424.254 591.089Z" fill="#CCE9FF" />
            <path d="M754.839 602.887C754.839 605.969 751.146 608.467 746.589 608.467H643.772C639.216 608.467 635.522 605.969 635.522 602.887C635.522 599.805 639.215 597.307 643.772 597.307H746.589C751.145 597.307 754.839 599.805 754.839 602.887Z" fill="#CCE9FF" />
            <path d="M894.335 531.714C894.335 534.796 890.642 537.294 886.085 537.294H783.269C778.713 537.294 775.019 534.796 775.019 531.714C775.019 528.632 778.712 526.134 783.269 526.134H886.086C890.642 526.134 894.335 528.632 894.335 531.714Z" fill="#CCE9FF" />
            <path d="M643.684 530.38C643.684 534.199 636.386 537.295 627.384 537.295H424.237C415.235 537.295 407.938 534.199 407.938 530.38C407.938 526.561 415.235 523.465 424.237 523.465H627.384C636.386 523.465 643.684 526.561 643.684 530.38Z" fill="#CCE9FF" />
        </svg>

    );
}
export default EmailChecklist;