export const general_config = {
    default_tier: 'default.tier',
    default_nationality: 'default.nationality',
    default_language: 'default.language',
    default_country: 'default.country',
    default_phonecode: 'default.phonecode',
    reporting_allaccess: 'reporting.allaccess',
    reporting_spvgarudamilescc: 'reporting.spvgarudamilescc',
    reporting_ho: 'reporting.ho',
    reporting_bo: 'reporting.bo',
    reporting_spvbo: 'reporting.spvbo',
    default_vat: 'buymileage.vat.amount',
    default_announcement: 'announcement.content',
    default_announcement_show: 'announcement.show',
    maximum_retro: 'maximum.retro',
    member_status: 'member.updatestatus'
}