import React from 'react';

const ReadyToPrint = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="50" viewBox="0 0 80 50" fill="none">
            <g clipPath="url(#clip0_701_80)">
                <path d="M5.39211 11.2195C5.25504 11.2503 5.13886 11.3232 5.06905 11.4223C4.99925 11.5214 4.98151 11.6385 5.01975 11.748L13.9982 37.348C14.0368 37.4574 14.1281 37.5502 14.2522 37.6059C14.3763 37.6616 14.5231 37.6758 14.6602 37.6453L47.7595 30.2459C47.8966 30.2151 48.0127 30.1422 48.0825 30.0431C48.1523 29.944 48.1701 29.8269 48.1318 29.7173L39.1534 4.11738C39.1148 4.00794 39.0235 3.91519 38.8994 3.85945C38.7753 3.80372 38.6285 3.78956 38.4914 3.82009L5.39211 11.2195Z" fill="#E6E6E6" />
                <path d="M15.076 36.4364L46.6175 29.3853L38.0752 5.02893L6.53369 12.0801L15.076 36.4364Z" fill="white" />
                <path d="M10.7041 15.3323C10.4936 15.3794 10.4069 15.6585 10.5107 15.9544C10.6145 16.2504 10.8701 16.4529 11.0806 16.4059L25.4662 13.1899C25.6766 13.1429 25.7634 12.8638 25.6596 12.5679C25.5558 12.2719 25.3001 12.0694 25.0897 12.1164L10.7041 15.3323Z" fill="#E6E6E6" />
                <path d="M11.8625 18.6356C11.6521 18.6826 11.5653 18.9617 11.6691 19.2577C11.7729 19.5536 12.0286 19.7561 12.239 19.7091L26.6246 16.4932C26.8351 16.4461 26.9218 16.167 26.818 15.8711C26.7142 15.5751 26.4586 15.3726 26.2481 15.4196L11.8625 18.6356Z" fill="#E6E6E6" />
                <path d="M13.0075 21.9007C12.7971 21.9477 12.7103 22.2268 12.8141 22.5228C12.9179 22.8188 13.1736 23.0213 13.384 22.9742L27.7697 19.7583C27.9801 19.7113 28.0669 19.4322 27.9631 19.1362C27.8593 18.8403 27.6036 18.6377 27.3931 18.6848L13.0075 21.9007Z" fill="#E6E6E6" />
                <path d="M14.1662 25.2039C13.9558 25.251 13.869 25.53 13.9728 25.826C14.0766 26.122 14.3323 26.3245 14.5427 26.2775L28.9284 23.0615C29.1388 23.0145 29.2256 22.7354 29.1218 22.4394C29.018 22.1435 28.7623 21.9409 28.5518 21.988L14.1662 25.2039Z" fill="#E6E6E6" />
                <path d="M32.3901 10.5225C32.3017 10.5422 32.219 10.5755 32.1468 10.6207C32.0746 10.6659 32.0143 10.722 31.9693 10.7859C31.9242 10.8497 31.8954 10.92 31.8844 10.9927C31.8734 11.0654 31.8805 11.1392 31.9053 11.2097C31.93 11.2803 31.9719 11.3462 32.0286 11.4038C32.0853 11.4614 32.1556 11.5095 32.2356 11.5454C32.3156 11.5813 32.4037 11.6042 32.4948 11.6129C32.5859 11.6216 32.6783 11.6159 32.7666 11.596L35.4559 10.9948C35.6342 10.955 35.7854 10.8602 35.8762 10.7313C35.967 10.6025 35.9899 10.4501 35.94 10.3078C35.8901 10.1654 35.7714 10.0447 35.61 9.97222C35.4486 9.89974 35.2577 9.88142 35.0794 9.92128L32.3901 10.5225Z" fill="#E6E6E6" />
                <path d="M33.5261 13.7616C33.4377 13.7813 33.355 13.8147 33.2828 13.8599C33.2106 13.9051 33.1503 13.9612 33.1052 14.025C33.0602 14.0888 33.0314 14.1591 33.0204 14.2319C33.0094 14.3046 33.0165 14.3783 33.0412 14.4489C33.066 14.5194 33.1079 14.5854 33.1646 14.643C33.2213 14.7006 33.2916 14.7487 33.3716 14.7846C33.4516 14.8204 33.5397 14.8434 33.6308 14.8521C33.7219 14.8607 33.8143 14.855 33.9026 14.8352L36.5919 14.234C36.7702 14.1941 36.9214 14.0993 37.0122 13.9705C37.103 13.8416 37.1259 13.6892 37.076 13.5469C37.0261 13.4045 36.9073 13.2838 36.7459 13.2114C36.5846 13.1389 36.3937 13.1206 36.2154 13.1604L33.5261 13.7616Z" fill="#E6E6E6" />
                <path d="M34.6623 17.0009C34.574 17.0206 34.4914 17.054 34.4193 17.0993C34.3472 17.1445 34.287 17.2006 34.242 17.2644C34.197 17.3282 34.1683 17.3984 34.1573 17.4711C34.1464 17.5438 34.1535 17.6175 34.1782 17.6879C34.2029 17.7584 34.2448 17.8244 34.3014 17.8819C34.3581 17.9395 34.4283 17.9876 34.5082 18.0235C34.5881 18.0594 34.6761 18.0823 34.7672 18.0911C34.8582 18.0998 34.9505 18.0942 35.0388 18.0744L37.7281 17.4732C37.9064 17.4334 38.0576 17.3386 38.1484 17.2097C38.2392 17.0809 38.2621 16.9285 38.2122 16.7861C38.1622 16.6438 38.0435 16.5231 37.8821 16.4506C37.7207 16.3781 37.5299 16.3598 37.3516 16.3997L34.6623 17.0009Z" fill="#E6E6E6" />
                <path d="M35.7982 20.24C35.6201 20.28 35.4692 20.3748 35.3785 20.5036C35.2879 20.6324 35.265 20.7846 35.3149 20.9269C35.3648 21.0691 35.4834 21.1897 35.6446 21.2622C35.8058 21.3347 35.9965 21.3532 36.1747 21.3135L38.8641 20.7123C39.0422 20.6723 39.1931 20.5775 39.2838 20.4487C39.3744 20.3199 39.3972 20.1677 39.3474 20.0254C39.2975 19.8831 39.1789 19.7625 39.0177 19.69C38.8564 19.6175 38.6658 19.5991 38.4875 19.6388L35.7982 20.24Z" fill="#E6E6E6" />
                <path d="M36.8836 25.8546C36.771 25.8797 36.6657 25.9222 36.5737 25.9798C36.4817 26.0374 36.4048 26.1089 36.3474 26.1902C36.29 26.2716 36.2533 26.3611 36.2393 26.4538C36.2253 26.5465 36.2343 26.6404 36.2659 26.7303C36.2974 26.8202 36.3508 26.9042 36.423 26.9776C36.4953 27.051 36.5849 27.1123 36.6868 27.1581C36.7888 27.2038 36.901 27.233 37.0171 27.2441C37.1332 27.2551 37.2509 27.2478 37.3634 27.2225L40.7902 26.4565C41.0174 26.4057 41.21 26.2849 41.3257 26.1207C41.4414 25.9566 41.4706 25.7624 41.407 25.581C41.3434 25.3996 41.1921 25.2458 40.9864 25.1535C40.7808 25.0611 40.5376 25.0378 40.3104 25.0886L36.8836 25.8546Z" fill="#6C63FF" />
                <path d="M40.4103 23.092L15.0527 28.7607L15.1158 28.9407L40.4734 23.272L40.4103 23.092Z" fill="#E6E6E6" />
                <path d="M65.8816 47.2864L63.8478 47.6789L60.9836 41.603L63.9852 41.0237L65.8816 47.2864Z" fill="#A0616A" />
                <path d="M60.3391 50L60.3254 49.9546C60.1642 49.4197 60.2754 48.8556 60.6345 48.3861C60.9936 47.9165 61.5714 47.5799 62.241 47.45L66.22 46.682L66.8445 48.7444L60.3391 50Z" fill="#2F2E41" />
                <path d="M56.8786 48.3924L54.7864 48.3923L53.791 41.9492L56.879 41.9493L56.8786 48.3924Z" fill="#A0616A" />
                <path d="M57.3854 49.9904L50.6926 49.9902V49.9434C50.6926 49.3931 50.9665 48.8653 51.4539 48.4762C51.9414 48.087 52.6025 47.8684 53.2918 47.8684H53.2919L57.3855 47.8685L57.3854 49.9904Z" fill="#2F2E41" />
                <path d="M53.7893 45.8772L51.7578 26.5476L60.1675 25.31L60.2007 25.3052L62.6621 26.5685L61.8054 33.6822L65.75 44.7732L62.344 45.4888L58.3931 35.2026L57.489 32.0746L57.0256 36.1392L57.3696 46.163L53.7893 45.8772Z" fill="#2F2E41" />
                <path d="M51.4973 26.9859L51.4751 26.9634L54.3811 9.4945L54.3849 9.47162L54.4054 9.45525C54.4482 9.42105 55.4664 8.61777 56.5137 8.61777C56.6651 8.61777 56.809 8.61471 56.9471 8.61192C57.7485 8.59528 58.3816 8.58198 59.1339 9.18274C59.9005 9.79469 62.4015 12.0293 62.4015 15.0898C62.4015 18.0521 62.7394 27.258 62.7428 27.3506L62.7476 27.4806L62.611 27.4097C62.5773 27.3924 59.2134 25.6823 56.3922 27.272C55.5106 27.7688 54.7185 27.943 54.0413 27.943C52.4651 27.943 51.5108 26.9996 51.4973 26.9859Z" fill="#6C63FF" />
                <path d="M58.0795 7.17646C60.1108 6.28994 60.8573 4.25659 59.7469 2.63485C58.6365 1.01311 56.0897 0.417092 54.0584 1.30362C52.0271 2.19014 51.2805 4.22349 52.3909 5.84524C53.5014 7.46698 56.0482 8.06299 58.0795 7.17646Z" fill="#A0616A" />
                <path d="M49.652 30.7402C49.8503 30.6175 50.0126 30.4616 50.1272 30.2834C50.2419 30.1052 50.3062 29.9092 50.3157 29.709C50.3252 29.5089 50.2796 29.3095 50.1822 29.1249C50.0847 28.9403 49.9377 28.7751 49.7516 28.6407L52.132 24.158L49.0152 24.6159L47.2326 28.8026C46.9707 29.0672 46.8394 29.3993 46.8637 29.736C46.888 30.0727 47.0661 30.3906 47.3644 30.6294C47.6626 30.8682 48.0602 31.0114 48.4818 31.0318C48.9035 31.0522 49.3198 30.9485 49.652 30.7402Z" fill="#A0616A" />
                <path d="M47.3523 27.2531L48.1188 25.9611C48.0715 25.9384 48.0324 25.9061 48.0056 25.8675C47.2895 24.8766 51.6153 16.6477 51.9036 16.1019C51.8597 15.8051 51.4056 12.6593 51.7376 11.5989C52.1285 10.3507 52.931 9.76892 54.4212 9.65369C56.0645 9.53048 56.5079 11.3122 56.5121 11.3302L56.5136 15.9106L54.6276 21.1831L50.3264 28.1884L47.3523 27.2531Z" fill="#6C63FF" />
                <path d="M56.6049 5.50078C56.1024 5.55298 55.7234 5.14253 55.5476 4.76311C55.3718 4.38369 55.2379 3.94197 54.801 3.73701C54.2041 3.457 53.4405 3.79378 52.758 3.7014C51.9872 3.59707 51.486 2.94488 51.4467 2.3215C51.4075 1.69813 51.7182 1.09857 52.0231 0.523829L52.1295 1.23811C52.1153 0.99602 52.1791 0.755066 52.3148 0.538294C52.4506 0.321521 52.6537 0.13618 52.9048 0L52.7676 1.04809C52.8536 0.460926 53.6459 0.00594929 54.3706 0.127629L54.349 0.75212C55.2392 0.667604 56.1373 0.582969 57.0302 0.647358C57.9231 0.711747 58.8222 0.937296 59.499 1.40678C60.5113 2.10904 60.881 3.26541 60.7568 4.3315C60.6327 5.39759 60.0815 6.39911 59.507 7.36658C59.3625 7.61 59.1625 7.88468 58.8274 7.91568C58.5262 7.94354 58.2506 7.74256 58.157 7.51234C58.0634 7.28212 58.1091 7.03201 58.1651 6.79414C58.2498 6.4348 58.3566 6.06751 58.277 5.70743C58.1974 5.34736 57.8729 4.99153 57.4182 4.9474C56.9636 4.90328 56.4984 5.31817 56.717 5.63949L56.6049 5.50078Z" fill="#2F2E41" />
                <path d="M74.9549 49.9979H44.7905V49.8011H74.9996L74.9549 49.9979Z" fill="#3F3D56" />
            </g>
            <defs>
                <clipPath id="clip0_701_80">
                    <rect width="80" height="50" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
export default ReadyToPrint;